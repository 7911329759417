import { Injectable } from '@angular/core';
import { HpHcpHttp } from './hp-hcp-http.service';

@Injectable()
export class HpHcpActionListService {

    constructor(private _hpHcpHttp: HpHcpHttp) {}

    public updateActionItemComplete(userHsId, status, actionItemHsId, lastModifiedBy, potentialActionItemHsId, success, failure) {
        const optionalParams = {
            requestBody: {
                "userHsId":userHsId, 
                "status":status, 
                "actionItemHsId":actionItemHsId,
                "updatedBy": lastModifiedBy,
                "portalActionItemHsId": potentialActionItemHsId
             }
        };
        return this._hpHcpHttp.post(this._hpHcpHttp.urls.actionList.changeActionItemStatus, optionalParams).subscribe(success, failure);
    }

    public getActionListData(userId, success, failure) {
        this._hpHcpHttp.get(this._hpHcpHttp.urls.actionList.actionListData, { urlParam: userId }).subscribe(success, failure);
    }
}