import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HpHcpHttp } from '../../shared/service/hp-hcp-http.service';

@Injectable()
export class HpHcpLegalDocumentService {
  public constructor(private _httpService: HpHcpHttp) {}

  public postPrivacyAndTerms(requestBody: Object): Observable<any> {
    return this._httpService.post(this._httpService.urls.user.acceptLegal, { requestBody });
  }

  public getPrivacyPolicy(): Observable<any> {
    return this._httpService
      .get(this._httpService.urls.document.privacyPolicy, {}, true)
      .pipe(map((html: string) => this._cleanUpHtml(html)));
  }

  public getTermsOfUse(): Observable<any> {
    return this._httpService
      .get(this._httpService.urls.document.termsOfUse, {}, true)
      .pipe(map((html: string) => this._cleanUpHtml(html)));
  }

  private _cleanUpHtml(html: string) {
    return /<body>(.*?)<\/body>/g
      .exec(html.replace(/\n|\r/g, ''))[1]
      .replace(/<script(.*?)<\/script>|<noscript>(.*?)<\/noscript>/g, '');
  }
}
