<div class="hp-hcp-progressbar-vertical"
     [class.profile-page]="isProfilePage">

    <div class="fraction" *ngIf="!shouldDisplayEmptyState()">
        <span class="title" *ngIf="title">{{title}}</span>
        <span class="value">{{numerator}}/{{denominator}}</span>
        <span class="subtitle">{{label}}</span>
    </div>

    <hp-hcp-patient-empty-state-column class="fraction" *ngIf="shouldDisplayEmptyState()">
    </hp-hcp-patient-empty-state-column>

    <div class="progressbar-container">
        <div class="progressbar" [style.height.%]="percentage" [class.critical]="isCritical"></div>
    </div>

</div>
