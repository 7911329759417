import { Component, Input, OnChanges } from '@angular/core';

@Component({
    selector: 'hp-hcp-progressbar-vertical',
    templateUrl: './progressbar-vertical.html',
    styleUrls: ['./progressbar-vertical.scss']
})
export class ProgressbarVerticalComponent implements OnChanges {

    @Input() public isCritical: boolean;
    @Input() public denominator: number;
    @Input() public isProfilePage?: boolean = false;
    @Input() public label: string;
    @Input() public numerator: number;
    @Input() public title: string;

    public percentage: number;

    public ngOnChanges() {
        this.percentage = this._calculatePercentage();
    }

    public shouldDisplayEmptyState() {
        return !this.denominator;
    }

    private _calculatePercentage() {
        return this.denominator
            ? (this.numerator / this.denominator) * 100
            : 0;
    }
}
