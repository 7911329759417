<div class="hp-hcp-privacy-and-terms" >
    <mat-toolbar color="primary">
        <mat-toolbar-row>

                <img src="../../../assets/img/velys-logo-v2.png" class="header-logo-img" alt="VELYS Insights" (click)="goToLogin()">

        </mat-toolbar-row>
    </mat-toolbar>

    <div class="privacy-and-terms-form">
        <h3 class="privacy-and-terms-title">Terms of Use & Privacy Policy</h3>
        <form [formGroup]="privacyAndTermsForm">

          <div class="privacy-and-terms-detail">
                <h4 class="title-section"> Terms of Use</h4>

                <p>
                    Please read these Terms of Service carefully, as they include important information about your legal
                    rights. By clicking or tapping “OK” or “Agree” (or a similar term) in connection with these Terms of
                    Service, or by using or accessing the Services (as defined below), you agree to these Terms of
                    Service. We recommend that you print a copy of these Terms of Service for future reference.
                    We retain the right to make changes to these Terms of Service from time to time, as outlined below.     </p>

                <p>
                    These Terms of Service are between you and Medical Device Business Services, Inc. (“<u>Company</u>” or “<u>we</u>” or “<u>us</u>” or “<u>our</u>”)
                    and govern your use of and access to the VELYS&trade; Insights site provided by us located at
                    <a href="https://www.velysinsights.com" target="_blank">www.velysinsights.com</a> (the “<u>Site</u>”) and related content, services and tools (collectively with the Site,
                    the “<u>Services</u>”).
                </p>

                <p>
                    If you are not willing to accept the terms and conditions in these Terms of Service, we ask that you
                    not access or use the Services or post or submit any materials to the Services. Please review our
                    Privacy Policy located <a href="/general/privacy-policy">here</a> for details about what information
                    we collect from you when you use our Services and how we use it.
                </p>

                <p>
                    THIS SITE IS INTENDED FOR AND DIRECTED ONLY TO RESIDENTS OF THE UNITED STATES AND ANY OF ITS
                    TERRITORIES. OUR SERVICES ARE NOT INTENDED FOR USE BY PERSONS UNDER THE AGE OF 18. IF YOU ARE
                    UNDER 18 YEARS OLD, YOU MAY NOT USE THE SERVICES OR PROVIDE US WITH ANY PERSONALLY IDENTIFIABLE
                    INFORMATION.
                </p>

                <p>
                    REFERENCES TO “YOU” AND “YOUR” IN THESE TERMS OF SERVICE WILL REFER TO YOU AS THE USER OF THE
                    SERVICES. IF YOU ARE USING THE SERVICES ON BEHALF OF, OR FOR THE BENEFIT OF, ANY ORGANIZATION
                    WITH WHICH YOU ARE ASSOCIATED, THEN YOU AGREE TO THESE TERMS OF SERVICE ON BEHALF OF YOURSELF AND
                    SUCH ORGANIZATION, AND YOU REPRESENT AND WARRANT THAT YOU ARE AN AUTHORIZED REPRESENTATIVE OF THE
                    ORGANIZATION WITH THE LEGAL AUTHORITY TO BIND SUCH ORGANIZATION TO THESE TERMS OF SERVICE.
                </p>

                <p>
                    <b>Please note that Section 18 contains an arbitration clause and class action waiver.
                    By agreeing to these Terms of Service, you agree (a) to resolve all disputes with us
                    through binding individual arbitration, which means that you waive any right to have those
                    disputes decided by a judge or jury, and (b) that you waive your right to participate in
                    class actions, class arbitrations, or representative actions. You have the right to opt-out
                    of arbitration as explained in Section 18.

                    </b>
                </p>

                <p class="title"><b>TABLE OF CONTENTS</b>  </p>

              <ol>
                <li>Our Right to Make Changes  </li>
                <li>Information Disclaimer </li>
                <li>Information Submitted Through the Services  </li>
                <li>Jurisdictional Issues  </li>
                <li>Acceptable Use and Rules of Conduct </li>
                <li>Electronic Communications </li>
                <li>Registration </li>
                <li>Our Right to Use Submissions  </li>
                <li>Monitoring Use of the Services  </li>
                <li>Your Right to Use the Services </li>
                <li>Company’s Proprietary Rights </li>
                <li>Third Party Materials; Links </li>
                <li>Additional Terms </li>
                <li>Disclaimer of Warranties  </li>
                <li>Limitation of Liability </li>
                <li>Third Party Claims </li>
                <li>Termination </li>
                <li>Arbitration and class action waiver </li>
                <li>Injunctive Relief </li>
                <li>Governing Law; Jurisdiction </li>
                <li>Filtering </li>
                <li>Information or Complaints </li>
                <li>Copyright Infringement Claims </li>
                <li>Export Controls </li>
                <li>U.S. Government Restricted Rights </li>
                <li>Forward-Looking Statements </li>
                <li>Other Important Terms </li>
                <li>How to Contact Us </li>
                <li>[Open Source Font License Language] </li>
              </ol>

              <p class="title-section-spacing">
                <b>1. Our Right to Make Changes</b><br/><br/>

                We may change these Terms of Service from time to time, for any reason, including without limitation changes
                in the Services offered via the Site or to reflect a change in the law, by making available revised Terms of
                Service through the Services and updating the “<i>Last Updated</i> ” legend above. If we make changes that are material,
                we will use reasonable efforts to attempt to notify you, including without limitation by e-mail and/or by placing
                a prominent notice on the first page of the Site. However, it is your sole responsibility to review these Terms
                of Service from time to time to view any such changes. The updated Terms of Service will be effective as of the
                time of posting, or such later date as may be specified in the updated Terms of Service. Any such changes will
                not apply to any dispute between you and us arising prior to the date on which we posted the revised Terms of
                Service incorporating such changes or otherwise notified you of such changes. Your clicking or tapping “OK” or
                “Agree” (or a similar term) in connection with these Terms of Service or your continued access or use of the
                Services following any changes will constitute your acceptance of such changes.
              </p>

              <p>
                To the extent permitted by applicable law, we may, at any time and without liability, modify or discontinue
                all or part of the Services (e.g., without limitation, to reflect changes in the relevant laws, to protect
                the security of the Services or to implement reasonable technical adjustments and improvements, to modify the
                services and functions provided by the Site); charge, modify or waive any fees required to use the Services where
                reasonably necessary; or offer opportunities to some or all users, at our sole discretion. We will seek to notify
                you by reasonable means of (i) any modifications that will have a material adverse effect on your use of the
                Services, taken as a whole; and (ii) any material increase in the fees charged by us to use the Services.
              </p>

                <p>
                    <b>2. Information Disclaimer </b><br/>
                </p>
                    <p class="title-content-spacing">
                        <b>We Do Not Provide Professional Advice Through the Services</b>.  The Services may provide information
                        and data to you as the provider of medical services to your patients in order to assist such providers in
                        their assessment and management of the care of their applicable patients. However, you acknowledge and agree
                        that any such health care or related medical advice that you choose to provide is solely your decision,
                        and nothing in the Site or the Services may be considered medical advice. Company is not engaged in
                        rendering of medical or similar professional services or advice via the Services, and the information
                        provided by the Services is not intended to replace your medical or other professional advice or any
                        advice offered by any other physician or other professional healthcare provider. Company is not responsible
                        for any health problems that may result from information or advice you provide through using the Services
                        or from participating in the Services. If you rely on any information you receive or learn about through
                        the Services, you agree that you do so at your own risk and are voluntarily participating in these
                        activities.
                     </p>

                    <p>
                        WE ASSUME NO RESPONSIBILITY FOR ANY CONSEQUENCE RELATING DIRECTLY OR INDIRECTLY TO ANY ACTION OR
                        INACTION YOU TAKE BASED ON THE INFORMATION, OR OTHER MATERIAL PROVIDED AS PART OF THE SERVICES.
                    </p>

                <p>
                    <b>3. Information Submitted Through the Services </b>
                </p>
                    <p class="title-content-spacing">
                    Your submission of information through the Services is governed by our Privacy Policy,
                    located <a href="/general/privacy-policy">here</a>.
                     </p>

                <p>
                    <b>4. Jurisdictional Issues</b>
                </p>
                    <p class="title-content-spacing">
                        The Services may not be appropriate or available for use in some jurisdictions. Any use of the
                        Services is at your own risk, and you must comply with all applicable laws, rules and regulations
                        in doing so. We may limit the availability of the Services at any time, in whole or in part, to any
                        person or geographic area that we choose, in our sole discretion, for valid reasons (e.g., to comply
                        with relevant laws and regulatory requirements, to protect the security of the Services or to implement
                        reasonable technical adjustments).
                    </p>

                <p>
                    <b>5. Acceptable Use and Rules of Conduct</b>
                    <br/><br/>You must not:
                </p>
                    <ul>
                        <li>
                            Post, transmit or otherwise make available through or in connection with the Services any materials that are or may be: (a) threatening, harassing, degrading, hateful, intimidating, or otherwise fail to respect the rights and dignity of others; (b) defamatory, libelous or fraudulent; (c) obscene, indecent, pornographic or otherwise objectionable; or (d) protected by copyright, trademark, trade secret, right of publicity or privacy or any other proprietary right, without the express prior written consent of the applicable owner.             </li>
                        <li>
                            Post, transmit or otherwise make available through or in connection with the Services any virus, worm, Trojan horse, Easter egg, time bomb, spyware or other computer code, file or program that is or is potentially harmful or invasive or intended to damage or hijack the operation of, or to monitor the use of, any hardware, software or equipment (each, a “<u>Virus</u>”).             </li>
                        <li>
                            Use the Services for any commercial purpose, including use for your commercial purposes or any commercial purposes that will benefit you in any way, without our express prior written consent.             </li>
                        <li>
                            Use the Services for any purpose that is fraudulent or otherwise unlawful.    </li>
                        <li>
                            Collect, monitor, extract or copy information about users of the Services in any way, including through reverse engineering.             </li>
                        <li>
                            Circumvent, remove, alter, deactivate, degrade or thwart any technological measure or content protections of the Services or interfere with the operation of the Services or the servers or networks used to make the Services available, including by hacking or defacing any portion of the Services, or violate any requirement or policy of such servers or networks.             </li>
                        <li>
                            Restrict or inhibit any other person from using the Services, including using the Services in any manner that could disable, overburden, damage, disrupt or impair the Services or interfere with any other party's use of the Services or use any device, software or routine that causes the same.             </li>
                        <li>
                            Reproduce, modify, adapt, translate, create derivative works of, sell, rent, lease, loan, timeshare, distribute or otherwise exploit any portion of (or any use of) the Services except as expressly authorized under these Terms of Service, including using cheats, automation software (bots), hacks, modifications (mods) or any other unauthorized third-party software designed to modify the Services, without our express prior written consent.             </li>
                        <li>
                            Reverse engineer, duplicate, decompile, decode or disassemble any portion of the Services (including any underlying idea or algorithm), or attempt to do any of the same, except where such restriction is expressly prohibited by applicable law.             </li>
                        <li>
                            Remove any copyright, trademark or other proprietary rights notice from the Services.             </li>
                        <li>
                            Incorporate any portion of the Services into any product or service, without our express prior written consent.             </li>
                        <li>
                            Systematically download and store Services content.             </li>
                        <li>
                            Use any robot, spider, site search/retrieval application or other manual or automatic device to retrieve, index, “scrape,” “data mine” or otherwise gather Services content, or reproduce or circumvent the navigational structure or presentation of the Services, without our express prior written consent.              </li>
                        <li>
                            Use the Services in any way not expressly permitted by these Terms of Service.             </li>
                    </ul>
                     <p>
                        You are responsible for obtaining, maintaining and paying for all hardware, telecommunications and other equipment or services needed for you to use the Services. We do not guarantee that the App or the Services can be accessed and used on any particular device or with any particular service plan.
                    </p>

                <p>
                    <b>6. Electronic Communications    </b>
                </p>
                        <p class="title-content-spacing">
                            The information communicated as part of the Services may constitute an electronic communication
                            including without limitation push notifications, local client notifications, text messages,
                            picture messages, alerts, emails or other types of messages directly sent to you outside or
                            inside the App (“<u>Push Messages</u>”). When you communicate with us through the Services or via other
                            forms of electronic media, including without limitation e-mail, you are communicating with us
                            electronically. You agree that we may communicate electronically, subject to local privacy and
                            anti-spam laws, and that such communications, as well as notices, disclosures, agreements, and
                            other communications that we provide to you electronically, are equivalent to communications in
                            writing and shall have the same force and effect as if they were in writing and signed by the
                            party sending the communication. You acknowledge that, when you use the Services, your wireless
                            service provider may charge you fees for data, text messaging and/or other wireless access,
                            including in connection with Push Messages. You have control over the Push Messages settings,
                            and can opt in or out of these Push Messages through the Services or through your mobile device’s
                            operating system (with the possible exception of infrequent, important service announcements and
                            administrative messages). Please check with your wireless service provider to determine what fees
                            apply to your access to and use of the Services, including your receipt of Push Messages from us.
                            You are solely responsible for any fee, cost or expense that you incur to download, install and/or
                            use the Services on your mobile device, including for your receipt of push messages from us.
                         </p>

                <p>
                    <b>7. Registration</b>
                </p>
                        <p class="title-content-spacing">
                            You will need to register to use the Services by creating an account (“<u>Account</u>”). We may
                            reject, or require that you change, any user name, password or other information that you provide.
                            Your user name and password are for your personal use only. You are solely responsible for maintaining
                            the confidentiality of your credentials and for restricting access to your mobile device, computer,
                            and/or other means of accessing the Services. We are not responsible for any use of your credentials
                            caused by your failure to keep them confidential. You are solely responsible for all activities that
                            occur under your account, either with or without your knowledge. You must promptly notify us of any
                            unauthorized use of your credentials or account of which you become aware or have reason to suspect
                            that your Account or password have been stolen, misappropriated or otherwise compromised, or in case
                            of any actual or suspected unauthorized use of your Account. We recommend that, to the extent you access
                            the Services via a mobile device, you impose a password to protect said device.
                        </p>
                        <p>
                            You agree that any information you provide to us will be current, accurate and complete and that you
                            will keep such information up to date by notifying us of any changes.
                        </p>
                        <p>
                            We reserve the right to terminate any account at any time in our sole discretion, including without
                            limitation for any failure to comply with these Terms of Service, any fraud or abuse, or any
                            misrepresentation that you or anyone using your account may make to us.
                        </p>

                <p>
                    <b>8. Our Right to Use Submissions </b>
                </p>
                        <p class="title-content-spacing">
                            Nothing in these Terms of Service transfers any ownership rights in your Submissions. For each
                            Submission, you grant to us a worldwide, royalty-free, fully paid-up, non-exclusive, perpetual,
                            irrevocable, transferable and fully sublicensable (through multiple tiers) license, without
                            additional consideration to you or any third party, to access, host, cache, transmit, publish,
                            reproduce, distribute, perform and display (publicly or otherwise), create derivative works of,
                            adapt, modify, store and otherwise use, analyze and exploit such Submission, and to contact you
                            about your Submission, in any format or media now known or hereafter developed, and for any
                            purpose (including promotional purposes, including without limitation testimonials and advertising).
                            As part of the foregoing license grant you agree that (a) the other users, including without
                            limitation, medical service providers, of our Services shall have the right to comment on and/or
                            tag your Submissions and/or to use, publish, display, modify or include a copy of your Submissions
                            as part of their own use of the Services, and (b) we have the right to make any of your Submissions
                            available to third parties, so that those third parties can distribute, make derivative works of,
                            comment on and/or analyze your Submissions on other media and services (either alone or as part of
                            a collective work); except that the foregoing (a) and (b) shall not apply to any of your Submissions
                            that you post privately for non-public display on the Services.
                        </p>
                        <p>
                            In addition, if you provide to us any ideas, proposals or suggestions (“<u>Feedback</u>”), we will deem
                            such Feedback a Submission. You agree that Feedback is not confidential and that your provision
                            of it is gratuitous, unsolicited and without restriction and does not place us under any obligation
                            with respect to  such Feedback. You acknowledge and expressly agree that any contribution of
                            Feedback does not and will not give or grant you any right, title or interest in the Services
                            or in any such Feedback. All Feedback becomes our sole and exclusive property and we may use and
                            disclose Feedback in any manner and for any purpose whatsoever without further notice or
                            compensation to you and without retention by you of any proprietary or other right or claim.
                            You hereby assign to Company any and all right, title and interest (including, but not limited to,
                            any patent, copyright, trade secret, trademark, show-how, know-how, moral rights and any and all
                            other intellectual property right) that you may have in and to any and all Feedback. <b>Therefore,
                            please do not submit or send to us any ideas, suggestion or materials that you wish to keep confidential
                            or for which you expect to receive compensation. You agree that you will not have any claim against us
                            with respect to any use or non-use of Submissions</b>, When you submit Submissions, you understand that we
                            will have the right, but not the obligation, to use, display and publish your name, photograph,
                            likeness, voice, performance, biographical information and/or statements, throughout the world in
                            perpetuity on the Services and on any affiliate or successor site or social media channels owned or
                            operated by us or our affiliates. If we make use of any of these rights, you understand and agree
                            that you shall not receive any other consideration, payment, notification or credit, nor will you
                            have any approval over how we use them.
                        </p>
                        <p>
                            By posting or submitting a Submission through the Services You confirm that (a) you have, or have
                            obtained, all rights, licenses, consents, permissions, power and/or authority necessary to grant
                            the licenses granted herein for your Submission, (b) your Submissions are complete and accurate,
                            and (c) your Submissions and your provision of them to us are not fraudulent or otherwise in breach
                            of any applicable law or any right of any third party. You further irrevocably waive (and consent
                            to us performing any acts or omissions in relation to your Submissions and associated materials
                            that may be inconsistent with) any “moral rights” or other rights with respect to attribution of
                            authorship or integrity of materials regarding your Submission that you may have under any applicable
                            law. You agree that your Submission will not contain material subject to copyright or other
                            proprietary rights, unless you have the necessary permission or are otherwise legally entitled
                            to post the material and to grant us the license described above.
                        </p>
                        <p>
                            We may impose a maximum amount of storage for Submissions on the Services. We are not responsible
                            for any loss or harm you may suffer as a result of any deletion or failure to store any messages,
                            communications or other Submission associated with maintaining the maximum amount of storage.
                        </p>

                <p>
                    <b>9. Monitoring Use of the Services </b>
                </p>
                        <p class="title-content-spacing">
                            We may (but have no obligation to) monitor, evaluate, alter or remove Submissions before or after
                            they appear on the Services or analyze your access to or use of the Services. We may disclose
                            information regarding your access to and use of the Services, the circumstances surrounding the
                            transmission of Submissions, and personal information regarding users who make Submissions available,
                            in each case in accordance with applicable law or a request by a court or law enforcement or other
                            governmental authority, or otherwise in accordance with our Privacy Policy.
                        </p>

                <p>
                    <b>10. Your Right to Use the Services </b>
                </p>
                        <p class="title-content-spacing">
                            You acknowledge that all intellectual property rights in the Services, including the Site, belong
                            to us or our licensors. We and our licensors reserve all rights in connection with the Services and
                            its content (other than your Submissions), including, without limitation, the exclusive right to
                            create derivative works. You have no right in or to the Services other than the right to access
                            them in accordance with these Terms of Service and you agree not to take any action(s) inconsistent
                            with such ownership interests. Subject to your compliance with, and solely for the duration of,
                            these Terms of Service: (a) you may view one copy of the Site on any single device, solely for your
                            personal, non-commercial use; (b) we permit you, on a limited, non-exclusive, revocable,
                            non-transferable, non-sublicensable basis, to install and use the App on a device that you own
                            or control, solely for your personal, non-commercial use. The App is licensed (not sold) to you.
                            This license has the sole purpose of enabling you to use and enjoy the benefit of the Services as
                            provided by us, in the manner permitted by these Terms of Service and subject to the use restrictions
                            described below. If you fail to comply with these Terms of Service, you must immediately cease using
                            the Services, and delete the App from your device. You are responsible for keeping your device secure
                            and protecting it appropriately.
                        </p>

                <p>
                    <b>11. Company’s Proprietary Rights </b>
                </p>
                        <p class="title-content-spacing">
                            We and our suppliers own the Services, which are protected by proprietary rights and laws, including
                            all of our brand names, trademarks, service marks, designs, slogans and any associated logos
                            (collectively, “<u>Marks</u>”). All Marks on the Services not owned by us are the property of their
                            respective owners, who may or may not be affiliated with, connected to, or sponsored by us.
                            You may not use our Marks in connection with any product or service that is not ours or in any
                            manner that is likely to cause confusion. Nothing contained on the Services should be construed
                            as granting any right to use any Marks without the express prior written consent of the owner.
                        </p>

                <p>
                    <b>12. Third Party Materials; Links </b>
                </p>
                        <p class="title-content-spacing">
                            The Services may allow access to third-party information, products, services, content, data,
                            applications or other materials, including Submissions (collectively, “<u>Third Party Materials</u>”),
                            and including any access via links. By using the Services, you acknowledge and agree that we are
                            not responsible for examining or evaluating the content, accuracy, completeness, timeliness,
                            validity, copyright compliance, legality, decency, quality or any other aspect of such Third Party
                            Materials or websites. We do not control or endorse, and are not liable or responsible to you or
                            any other person for, any third-party Services, Third Party Materials websites, or for any other
                            materials, products, or services of third parties. Third Party Materials and links to other websites
                            are provided solely as a convenience to you. We have no obligation to monitor Third Party Materials,
                            and we may block or disable access to any Third Party Materials at any time. Your access or use of
                            Third Party Materials is at your own risk and is subject to any additional terms, conditions and
                            policies applicable to such materials.
                        </p>

                <p>
                    <b>13. Additional Terms </b>
                </p>
                        <p class="title-content-spacing">
                            Additional terms may govern certain features or content of the Services, including without limitation
                            offers, prize draws, competitions, contests and sweepstakes. By participating in any activity as
                            part of the Services governed by additional terms, including without limitation a prize draw,
                            competition, contest or sweepstakes with Official Rules, you agree that you will be subject to
                            those additional terms in addition to these Terms of Service.
                        </p>

                <p>
                    <b>14. Disclaimer of Warranties </b>
                </p>
                        <p class="title-content-spacing">
                            YOUR ACCESS TO AND USE OF THE SERVICES ARE AT YOUR OWN RISK. TO THE FULLEST EXTENT PERMITTED
                            UNDER APPLICABLE LAW, THE SERVICES ARE MADE AVAILABLE TO YOU ON AN “AS IS,” AND “AS AVAILABLE”
                            BASIS, WITHOUT ANY WARRANTIES OR CONDITIONS OF ANY KIND, WHETHER EXPRESS, IMPLIED OR STATUTORY.
                        </p>
                        <p>
                            WE DISCLAIM ALL WARRANTIES WITH RESPECT TO THE SERVICES TO THE FULLEST EXTENT PERMISSIBLE UNDER
                            APPLICABLE LAW, INCLUDING THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
                            NON-INFRINGEMENT AND TITLE.
                        </p>
                        <p>
                            TO THE FULLEST EXTENT PERMISSIBLE UNDER APPLICABLE LAW, AND SUBJECT TO ANY APPLICABLE TERMS AND
                            CONDITIONS OR POLICIES APPLICABLE TO THE USE OF THIRD PARTY MATERIALS AS SET OUT IN SECTION 12,
                            THIRD PARTY MATERIALS ARE MADE AVAILABLE TO YOU ON AN “AS IS,” AND “AS AVAILABLE” BASIS, WITHOUT
                            ANY WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED. WE DISCLAIM ALL WARRANTIES WITH RESPECT
                            TO THIRD PARTY MATERIALS.
                        </p>
                        <p>
                            WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, TO THE FULLEST EXTENT PERMISSIBLE UNDER APPLICABLE
                            LAW, WE MAKE NO REPRESENTATION OR WARRANTY THAT (A) THE SERVICES WILL BE SECURE, THAT ANY USER NAME,
                            PASSWORD OR OTHER SECURITY MEASURE THAT YOU MAY USE OR ALLOW OTHERS TO USE IN CONNECTION WITH THE
                            SERVICES WILL PREVENT UNAUTHORIZED ACCESS TO YOUR SERVICES ACCOUNT OR RELATED INFORMATION, OR THAT
                            YOUR SERVICES ACCOUNT OR RELATED INFORMATION WILL NOT BE ACCESSED OR MISUSED BY ANY THIRD PARTY;
                            (B) THE COMPLETENESS, ACCURACY, AVAILABILITY, TIMELINESS, SECURITY OR RELIABILITY OF THE SERVICES;
                            (C) ANY HARM TO YOUR COMPUTER SYSTEM, LOSS OF DATA, OR OTHER HARM THAT RESULTS FROM YOUR ACCESS TO
                            OR USE OF THE SERVICES; (D) THE OPERATION OR COMPATIBILITY WITH ANY OTHER APPLICATION OR ANY
                            PARTICULAR SYSTEM OR DEVICE; (D) WHETHER THE SERVICES WILL MEET YOUR REQUIREMENTS OR BE AVAILABLE
                            ON AN UNINTERRUPTED, SECURE OR ERROR-FREE BASIS; AND (E) THE DELETION OF, OR THE FAILURE TO STORE
                            OR TRANSMIT, YOUR SUBMISSIONS AND OTHER COMMUNICATIONS MAINTAINED BY THE SERVICES. NO ADVICE OR
                            INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED FROM COMPANY PARTIES OR THROUGH THE SERVICES WILL
                            CREATE ANY WARRANTY OR REPRESENTATION NOT EXPRESSLY MADE HEREIN.
                        </p>
                        <p>
                            ALL DISCLAIMERS OF ANY KIND IN THESE TERMS OF SERVICE (INCLUDING IN THIS SECTION AND ELSEWHERE IN
                            THESE TERMS OF SERVICE) ARE MADE FOR THE BENEFIT OF BOTH COMPANY AND ITS AFFILIATES AND THEIR
                            RESPECTIVE SHAREHOLDERS, STOCKHOLDERS, DIRECTORS, OFFICERS, EMPLOYEES, AFFILIATES, AGENTS,
                            REPRESENTATIVES, LICENSORS, SUPPLIERS AND SERVICE PROVIDERS, AND THEIR RESPECTIVE SUCCESSORS
                            AND ASSIGNS (COLLECTIVELY, “<u>COMPANY PARTIES</u>”).
                        </p>
                        <p>
                            The Services may include inaccuracies, errors and materials that conflict with these Terms of Service.
                            Additionally, third parties may make unauthorized alterations to the Services.
                            If you become aware of any such alteration, please use the link at
                            <a href="/general/contact-us">Contact Us</a> and provide a
                            description of such alteration and its location on the Services.
                        </p>

                <p>
                    <b>15. Limitation of Liability </b>
                </p>
                        <p class="title-content-spacing">
                            NOTHING IN THESE TERMS OF SERVICE RESTRICTS, EXCLUDES OR MODIFIES OR PURPORTS TO RESTRICT, EXCLUDE
                            OR MODIFY ANY MANDATORY STATUTORY CONSUMER RIGHTS UNDER APPLICABLE LAW.
                        </p>
                        <p>
                            WITH RESPECT TO ANY CONDITIONS, WARRANTIES OR GUARANTEES THAT CANNOT BE EXCLUDED UNDER APPLICABLE
                            STATUTES, TO THE EXTENT PERMITTED BY APPLICABLE LAW, OUR LIABILITY IS LIMITED (AT OUR OPTION)
                            TO THE RESUPPLY OR REFUND OF THE COST OF THE RELEVANT PORTION OF THE SERVICES.
                        </p>
                        <p>
                            TO THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW: (A) WE WILL NOT BE LIABLE FOR ANY INDIRECT,
                            INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY OR PUNITIVE DAMAGES OF ANY KIND, OR LOSSES EVEN
                            IF COMPANY PARTIES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGE, IN EACH CASE ARISING OUT
                            OF OR IN CONNECTION WITH THE SERVICES OR THESE TERMS OF SERVICE, AND UNDER ANY CONTRACT, TORT
                            (INCLUDING NEGLIGENCE), STRICT LIABILITY OR OTHER THEORY (COLLECTIVELY, “<u>INDIRECT LOSSES</u>”).
                        </p>
                        <p>
                            WITHOUT LIMITING THE FOREGOING, WE WILL NOT BE LIABLE FOR INDIRECT LOSSES OF ANY KIND RESULTING
                            FROM YOUR USE OF OR INABILITY TO USE THE SERVICES OR FROM ANY PRODUCTS OR THIRD PARTY MATERIALS,
                            INCLUDING FROM ANY VIRUS THAT MAY BE TRANSMITTED IN CONNECTION THEREWITH.
                        </p>
                        <p>
                            SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES,
                            SO THE ABOVE EXCLUSION OR LIMITATION MAY NOT APPLY TO YOU. WITH RESPECT TO ANY CONDITIONS,
                            WARRANTIES OR GUARANTEES THAT CANNOT BE EXCLUDED UNDER STATUTE, TO THE EXTENT PERMITTED UNDER
                            APPLICABLE LAW, OUR LIABILITY IS LIMITED (AT OUR OPTION) TO THE RESUPPLY OR REFUND OF THE
                            COST OF RELEVANT SERVICES.
                        </p>
                        <p>
                            OUR MAXIMUM AGGREGATE LIABILITY FOR ALL DAMAGES, LOSSES AND CAUSES OF ACTION ARISING OUT OF OR
                            IN CONNECTION WITH THE SERVICES OR THESE TERMS OF SERVICE, WHETHER IN CONTRACT, TORT (INCLUDING
                            NEGLIGENCE) OR OTHERWISE, WILL NOT EXCEED THE GREATER OF (A) THE TOTAL AMOUNT, IF ANY, PAID IN
                            THE PAST SIX (6) MONTHS BY YOU TO US TO USE THE SERVICES; AND (B) ONE HUNDRED UNITED STATES
                            DOLLARS ($100). THE FOREGOING LIMITATIONS WILL APPLY EVEN IF THE ABOVE STATED REMEDY FAILS
                            OF ITS ESSENTIAL PURPOSE.
                        </p>
                        <p>
                            ALL LIMITATIONS OF LIABILITY OF ANY KIND IN THESE TERMS OF SERVICE (INCLUDING IN THIS SECTION AND
                            ELSEWHERE IN THESE TERMS OF SERVICE) ARE MADE FOR THE BENEFIT OF BOTH COMPANY AND COMPANY PARTIES.
                        </p>

                <p>
                    <b>16. Third Party Claims </b>
                </p>
                        <p class="title-content-spacing">
                            If we are sued by a third party as a result of (a) your breach of these Terms of Service, (b) your
                            infringement of any third-party right, (c) any unauthorized use of the Services; (d) your Submission,
                            or (e) your negligence or willful misconduct, then, to the fullest extent permitted by applicable law,
                            you agree that you shall indemnify and hold Company Parties harmless from and against any and all
                            claims, liabilities, damages, judgments, awards, losses, costs, expenses and fees (including attorneys’
                            fees) incurred by Company Parties.
                        </p>

                <p>
                    <b>17. Termination</b>
                </p>
                        <p class="title-content-spacing">
                            You may stop using the Services, and thereby terminate these Terms of Service, at any time. We may
                            terminate or suspend your access to or use of the Services if you do not comply with these Terms
                            of Service, engage in any fraud or abuse, or if you or anyone using your account makes any
                            misrepresentation to us. Where reasonable under the circumstances, we will provide you with
                            at least twenty-four (24) hours’ prior notice of termination or suspension, provided that if
                            we reasonably believe that you have materially breached these Terms of Service, we may immediately
                            terminate or suspend you. Upon any termination or suspension, your right to use the Services will
                            immediately cease and all licenses granted by us will terminate automatically, and we may, without
                            liability to you or any third party, immediately deactivate or delete your user name, password and
                            account, and all associated materials, without obligation to provide further access to such materials.
                            Your obligations under these Terms of Service shall survive any expiration or termination of these
                            Terms of Service. Termination will not limit any of our other rights or remedies at law or in equity.
                        </p>
                <p>
                    <b>18. Arbitration and class action waiver </b>
                </p>
                        <p class="title-content-spacing">
                            You agree that in the event of any dispute between you and Company Parties, you will first contact
                            us and make a good faith sustained effort to resolve the dispute before resorting to more formal
                            means of resolution, including without limitation, any court action.
                        </p>
                        <p>
                            After the informal dispute resolution process, any remaining dispute, controversy, or claim
                            (collectively, “<u>Claim</u>”) relating in any way to your use of our services and/or products,
                            including the Services, will be resolved by arbitration. You and Company agree that any
                            Claim will be settled by final and binding arbitration, using the English language, administered
                            by JAMS under its Comprehensive Arbitration Rules and Procedures (the “<u>JAMS Rules</u>”) then in
                            effect (those rules are deemed to be incorporated by reference into this section, and as of
                            the date of these Terms of Service). Arbitration will be handled by a sole arbitrator in
                            accordance with the JAMS Rules. Judgment on the arbitration award may be entered in any court
                            that has jurisdiction. Any arbitration under these Terms of Service will take place on an
                            individual basis – class arbitrations and class actions are not permitted. You understand that
                            by agreeing to these Terms of Service, you and Company are each waiving the right to trial by
                            jury or to participate in a class action or class arbitration. Notwithstanding the foregoing,
                            you and Company will have the right to bring an action in a court of proper jurisdiction for
                            injunctive or other equitable or conservatory relief, pending a final decision by the arbitrator.
                            You may instead assert your claim in “small claims” court, but only if your claim qualifies,
                            your claim remains in such court and your claim remains on an individual, non-representative
                            and non-class basis. An arbitrator shall not be bound by rulings in prior arbitrations involving
                            different users, but is bound by rulings in prior arbitrations involving the same user to the
                            extent required by applicable law.
                        </p>
                        <p>
                            Payment for any and all reasonable JAMS filing, administrative and arbitrator fees will be in
                            accordance with the JAMS Rules. If the value of your claim does not exceed $10,000, we will pay
                            for the reasonable filing, administrative and arbitrator fees associated with the arbitration,
                            unless the arbitrator finds that either the substance of your claim or the relief sought was
                            frivolous or brought for an improper purpose.
                        </p>
                        <p>
                            You have the right to opt-out and not be bound by the arbitration provisions set forth in these
                            Terms of Service by sending written notice of your decision to opt-out to
                            <a href="mailto:info@velysinsights.com">info&#64;velysinsights.com</a>
                            or to the U.S. mailing address listed at the bottom of these Terms of Service. The notice must be
                            sent to us within thirty (30) days of your registering to use the Services or agreeing to these
                            Terms of Service, otherwise you shall be bound to arbitrate disputes in accordance with these Terms
                            of Service. If you opt-out of these arbitration provisions, Company also will not be bound by them.
                        </p>

                <p>
                    <b>19. Injunctive Relief </b>
                </p>
                        <p class="title-content-spacing">
                            You agree that a breach of these Terms of Service will cause irreparable injury to Company for which
                            monetary damages would not be an adequate remedy and Company shall be entitled to equitable relief
                            in addition to any remedies we may have hereunder or at law without a bond, other security or proof
                            of damages.
                        </p>

                <p>
                    <b>20. Governing Law; Jurisdiction </b>

                </p>
                        <p class="title-content-spacing">
                            Unless otherwise prescribed by applicable law, these Terms of Service is governed by and shall be
                            construed in accordance with the laws of the State of New York, without regard to its principles
                            of conflicts of law, and regardless of your location. Except as otherwise expressly provided in
                            these Terms of Service, all arbitration and other litigation of any disputes between you and us
                            arising out of or related to the Services or these Terms of Service, whether based in contract,
                            tort, statute, fraud, misrepresentation or any other legal theory and including non-contractual
                            disputes or claims, will be subject to the exclusive jurisdiction of the federal and state courts
                            located in the State of New York, U.S.A., and you waive any jurisdictional, venue or inconvenient
                            forum objections to such courts. You and Company agree that the United Nations Convention on Contracts
                            for the International Sale of Goods will not apply to the interpretation or construction of these
                            Terms of Service.
                        </p>

                <p>
                    <b>21. Filtering </b>
                </p>
                        <p class="title-content-spacing">
                            Parental control protections (such as computer hardware, software or filtering services) are
                            commercially available that may assist you in limiting access to material that may be harmful
                            to or inappropriate for minors. Information identifying current providers of such protections
                            (which we do not endorse) is available from
                            <a href="https://en.wikipedia.org/wiki/Comparison_of_content-control_software_and_providers">
                            https://en.wikipedia.org/wiki/Comparison_of_content-control_software_and_providers.</a>
                        </p>

                <p>
                    <b>22. Information or Complaints </b>
                </p>
                        <p class="title-content-spacing">
                            If you have a question or complaint regarding the Services, please use the link at
                            <a href="general/contact-us">Contact Us.</a>
                            California residents may reach the Complaint Assistance Unit of the Division of Consumer Services
                            of the California Department of Consumer Affairs by mail at 1625 North Market Blvd., Suite N 112,
                            Sacramento, CA 95834, or by telephone at (800) 952-5210.
                        </p>

                <p>
                    <b>23. Copyright Infringement Claims </b>
                </p>
                    <p class="title-content-spacing">
                        If you believe in good faith that materials available on the Services infringe your copyright, you
                        may write to us by mail and request that we remove such material or block access to it. Please
                        be precise about the identity and location of the allegedly infringing materials. If you believe
                        in good faith that someone has wrongly filed a notice of copyright infringement against you, you
                        may send us a written counter-notice. Please note that you will be liable for damages if you
                        materially misrepresent that content or an activity is not infringing the copyrights of others.
                        Notices and counter-notices must be sent through the link <a href="general/contact-us">Contact Us.</a> In the United States, in
                        addition to contacting us by clicking <a href="general/contact-us">Contact Us</a> Company’s Agent for complaints related to the
                        Digital Millennium Copyright Act (DMCA) can be reached in writing at the following address:
                    </p>
                    <p class="address-to-right">
                            Trademark Law Department<br/>

                            Johnson & Johnson<br/>

                            One Johnson & Johnson Plaza<br/>

                            New Brunswick, NJ 08933<br/>
                    </p>
                    <p>
                        This address may also be used to contact us about copyright infringement claims in jurisdictions
                        outside of the United States, or you may contact us through clicking
                        <a href="general/contact-us">Contact Us.</a>
                    </p>

                <p>
                    <b>24. Export Controls </b>
                </p>
                    <p class="title-content-spacing">
                        The Services are subject to U.S. export controls restrictions. You agree that you will not export
                        or re-export, directly or indirectly, the Services and/or other information or materials provided
                        by us hereunder, to any country for which the United States or any other relevant jurisdiction requires
                        any export license or other governmental approval at the time of export without first obtaining such
                        license or approval. In particular, but without limitation, the Services may not be exported or re-exported
                        (a) into any U.S. embargoed countries or any country that has been designated by the U.S. Government
                        as a “terrorist supporting” country, or (b) to anyone listed on any U.S. Government list of prohibited
                        or restricted parties, including the U.S. Treasury Department's list of Specially Designated Nationals
                        or the U.S. Department of Commerce Denied Person’s List or Entity List. By using the Services, you
                        represent and warrant that you are not located in any such country or on any such list. You are responsible
                        for and hereby agree to comply at your sole expense with all applicable United States export laws and
                        regulations.
                    </p>

                <p>
                    <b>25. U.S. Government Restricted Rights </b>
                </p>
                    <p class="title-content-spacing">
                        The Services and related documentation are “Commercial Items”, as that term is defined at 48 C.F.R.
                        §2.101, consisting of “Commercial Computer Software” and “Commercial Computer Software Documentation”,
                        as such terms are used in 48 C.F.R. §12.212 or 48 C.F.R. §227.7202, as applicable. Consistent with 48
                        C.F.R. §12.212 or 48 C.F.R. §227.7202-1 through 227.7202-4, as applicable, the Commercial Computer
                        Software and Commercial Computer Software Documentation are being licensed to U.S. Government end users
                        (a) only as Commercial Items, and (b) with only those rights as are granted to all other end users
                        pursuant to the terms and conditions herein.
                    </p>

                <p>
                    <b>26. Forward-Looking Statements </b>
                </p>
                    <p class="title-content-spacing">
                        Some of the information on the Services may contain certain projections or other forward-looking
                        statements regarding future events or our future financial performance. Statements appearing on the
                        Services that concern us, our affiliates or our and their management and that are not historical
                        facts are “<u>Forward-Looking Statements.</u>” Word such as “expects,” “plans,” “believes,” “goals,” “continues,”
                        “may” and other variations of such words and similar expressions identify Forward-Looking Statements.
                        In addition, any statements that refer to or may imply future financial performance, our anticipated
                        growth and trends in our businesses, and other characterizations of future events or circumstances are
                        Forward-Looking Statements. Forward-Looking Statements are only predictions, and actual future events
                        may differ materially from those discussed in any Forward-Looking Statement. Various external factors
                        and risks affect our operations, markets, products, services and prices. These factors and risks are
                        described in our current annual report filed with the SEC and in other filings we make with the SEC.
                        You can access our most recent SEC filings via the SEC EDGAR system located at
                        <a href="https://www.sec.gov/" target="_blank" > www.sec.gov, </a> or you may
                        obtain these filings directly from us at no charge. We disclaim any obligation or responsibility to
                        update, revise or supplement any Forward-Looking Statement or any other statements appearing on the
                        Services.
                    </p>

                <p>
                    <b>27. Other Important Terms</b>
                </p>
                    <p class="title-content-spacing">
                        These Terms of Service do not, and shall not be construed to, create any partnership, joint
                        venture, employer-employee, agency or franchisor-franchisee relationship between you and us.
                        These Terms of Service are between you and us. Except as set forth in Sections 15, 16 and 27,
                        no other person shall have any rights to enforce any of the terms of these Terms of Service.
                        If any provision of these Terms of Service are found to be unlawful, void or for any reason
                        unenforceable, that provision will be deemed severable from these Terms of Service and will
                        not affect the validity and enforceability of any remaining provision. You may not assign,
                        transfer or sublicense any or all of your rights or obligations under these Terms of Service
                        without our prior written consent. We may assign, transfer or sublicense any or all of our
                        rights or obligations under these Terms of Service without restriction. No waiver by either
                        party of any breach or default under these Terms of Service will be deemed to be a waiver of
                        any preceding or subsequent breach or default. Any heading, caption or section title contained
                        herein is for convenience only, and in no way defines or explains any section or provision. All
                        terms defined in the singular shall have the same meanings when used in the plural, where
                        appropriate and unless otherwise specified. Any use of the term “including” or variations
                        thereof in these Terms of Service shall be construed as if followed by the phrase “without limitation.”
                        These Terms of Service, including any terms and conditions incorporated herein, are the entire agreement
                        between you and us relating to the subject matter of these Terms of Service, and, in the absence of
                        fraud, supersedes any and all prior or contemporaneous written or oral agreements or understandings
                        between you and us relating to such subject matter. Notices to you (including notices of changes to
                        these Terms of Service) may be made via posting to the Services or by e-mail (including in each case
                        via links), or by regular mail. Without limitation, a printed version of these Terms of Service and
                        of any notice given in electronic form shall be admissible in judicial or administrative proceedings
                        based upon or relating to these Terms of Service to the same extent and subject to the same conditions
                        as other business documents and records originally generated and maintained in printed form. The
                        Services are operated by us in the United States. Those who choose to access the Services from locations
                        outside the United States do so at their own initiative and are responsible for compliance with
                        applicable local laws. Neither party will be responsible for any failure to fulfill any obligation
                        due to any cause beyond its control.
                    </p>

                <p>
                    <b>28. How to Contact Us</b>
                </p>
                    <p class="title-content-spacing">
                        You may contact us regarding the Services or these Terms of Service at:
                    </p>
                    <p class="address-to-right">
                            Medical Device Business Services, Inc.<br/>
                            700 Orthopaedic Dr. <br/>
                            Warsaw, IN 46582 <br/>
                            Phone: (574) 267-8143 <br/>
                            Email: <a href="mailto:info@velysinsights.com">info&#64;velysinsights.com</a>
                    </p>

                    <a href="./docs/velys-insights-licenses.pdf" target="_blank">Licenses</a>

    <hr class="divider">
    <br/>
    <h4 class="title-section"> Privacy Policy</h4>

    <p>
        <b> Medical Device Business Services, Inc.</b>("We" or "Us") cares about your privacy and wants you to be familiar with how we collect, use, and disclose information.  This Privacy Policy describes our practices in connection with information that we or our service providers collect through the VELYS&trade; Insights portal (hereinafter the “<b>Service</b>”) operated and controlled by us from which you are accessing this Privacy Policy.  By providing personal information to us or by using the Service, you acknowledge that you have read and understand this Privacy Policy.
        </p>

        <p class="sub-title">USE BY MINORS </p>
        <p>
        The Service is not directed to individuals under the age of <b>18</b>, and we request that these individuals not provide personal information through the Service.
        </p>

        <p class="sub-title">INFORMATION COLLECTION</p>
        <p>
        We will ask you to submit personal information in order for you to use the Services. You will be informed what information is required and what information is optional.
        <br/><br/>
        We will combine the information you submit with other information we have collected from you, whether on- or offline. We may also combine it with information we receive about you from other sources, such as other Johnson & Johnson affiliates, publicly available information sources (including information from your publicly available social media profiles), and other third-party information providers.
        <br/><br/><u>If you submit any personal information relating to another individual to us, you represent that you have the authority to do so and to permit us to use the information in accordance with this Privacy Policy. </u>
        </p>

        <p class="sub-title ">SENSITIVE INFORMATION </p>
        <p>
                Unless we specifically request or invite you to do so, we ask that you not send us, and you not disclose, any sensitive personal information (e.g., Social Security numbers, information related to racial or ethnic origin, political opinions, religion or philosophical beliefs, health, sex life or sexual orientation, criminal background, or trade union membership, or biometric or genetic data for the purpose of uniquely identifying an individual) on or through the Service or otherwise to us. 

        </p>

        <p class="sub-title ">AUTOMATIC INFORMATION COLLECTION AND USE </p>
        <p>
                We and our service providers may automatically collect and use information in the following ways as you navigate around the Service:
        </p>

        <div class="Content-to-right">
        <p>
            <strong>Through your browser:</strong>
            Certain information is collected by most browsers, such as your Media Access Control (MAC) address, computer type (Windows or Mac), screen resolution, operating system name and version, and Internet browser type and version.  We may collect similar information, such as your device type and identifier, if you access the Service through a mobile device.  We use this information to ensure that the Service functions properly.
        </p>
        <p>
            <strong>Using cookies:</strong>
            Cookies are pieces of information stored directly on the computer you are using.  Cookies allow us to collect information such as browser type, time spent on the Service, pages visited, and language preferences.  We and our service providers use the information for security purposes, to facilitate navigation, display information more effectively, and to personalize your experience while using the Service.  We also use cookies to recognize your computer or device, which makes your use of the Service easier, such as to remember what is in your shopping cart.  In addition, we use cookies to gather statistical information about Service usage in order to continually improve its design and functionality, understand how individuals use it, and to assist us with resolving questions regarding it.  Cookies further allow us to select which of our advertisements or offers are most likely to appeal to you and display them while you are on the Service.  We may also use cookies in online advertising to track consumer responses to our advertisements. <br/>
        </p>
        <p>
                You can refuse to accept these cookies by following your browser’s instructions; however, if you do not accept them, you may experience some inconvenience in your use of the Service.  You may also not receive advertising or other offers from us that are relevant to your interests and needs.  To learn more about cookies, please visit
                 <a target="_blank" href="http://www.allaboutcookies.org/" class="link"
               data-dom-id="http://www.allaboutcookies.org"
               data-event-group="footer">
               http://www.allaboutcookies.org
            </a>.
        </p>
        <p>
            <strong>Using Flash cookies:</strong>
            Our use of Adobe Flash technology (including Flash Local Stored Objects (“Flash LSOs”)) allows us to, among other things, serve you with more tailored information, facilitate your ongoing access to and use of the Service, and collect and store information about your use of the Service.  If you do not want Flash LSOs stored on your computer, you can adjust the settings of your Flash player to block Flash LSO storage using the tools contained in the
            <a target="_blank" href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html" class="link"
               data-dom-id="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html"
               data-event-group="footer">
                Website Storage Settings Panel
            </a>.
            You can also control Flash LSOs by going to the
                    <a target="_blank" href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager03.html" class="link"
               data-dom-id="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager03.html"
               data-event-group="footer">
                Global Storage Settings Panel
            </a>
            and following the instructions.  Please note that setting the Flash Player to restrict or limit acceptance of Flash LSOs may reduce or impede the functionality of some Flash applications. <br/><br/>
        </p>
            <p>
                    <strong>Using pixel tags, web beacons, clear GIFs, or other similar technologies:</strong>
                    These may be used in connection with some Service pages and HTML-formatted email messages to, among other things, track the actions of users and email recipients, measure the success of our marketing campaigns, and compile statistics about Service usage.
            </p>

        <p>
            <strong>Interest-based advertising:</strong>
            We may use third-party advertising companies to serve advertisements regarding goods and services that may be of interest to you when you access and use the Service and other online services, based on information relating to your access to and use of the Service and other online services on any of your devices.  To do so, these companies may place or recognize a unique cookie on your browser (including through the use of pixel tags).  They may also use these technologies, along with information they collect about your online use, to recognize you across the devices you use, such as a mobile phone and a laptop.<br/><br/>
            If you would like more information about this practice, and to learn how to opt out of it in desktop and mobile browsers on the particular device on which you are accessing this Privacy Policy, please visit
            <a target="_blank" href="http://optout.aboutads.info/?c=2&lang=EN" class="link"
            data-dom-id="http://optout.aboutads.info/?c=2&lang=EN"
            data-event-group="footer">
            http://optout.aboutads.info/#/
         </a>
         and
         <a target="_blank" href="http://optout.networkadvertising.org/?c=1" class="link"
            data-dom-id="http://optout.networkadvertising.org/?c=1"
            data-event-group="footer">
            http://optout.networkadvertising.org/#/
         </a>
         .
        </p>
        <p>
                <strong>IP address:</strong>
                Your IP address is a number that is automatically assigned to your computer by your Internet Service Provider.  An IP address is identified and logged automatically in our server log files whenever a user visits the Service, along with the time of the visit and the pages visited.  Collecting IP addresses is standard practice and is done automatically by many online services.  We use IP addresses for purposes such as calculating Service usage levels, diagnosing server problems, and administering the Service.  We may also derive your approximate location from your IP address.
        </p>

        <p>
                <strong>Device Information:</strong>
                If you access the Services through your mobile device, We may collect information about your mobile device, such as a unique device identifier, to understand how you use the Service.
        </p>
        </div>

        <p class="sub-title">HOW WE USE AND DISCLOSE INFORMATION </p>

        <p>We use and disclose information you provide to us as described to you in the Collection Statement. Please see the section entitled “Choices and Access,” below, to learn how you may opt out of certain of our uses and disclosures.<br/><br/>
        Where required by applicable law, we will obtain your consent to our use of your personal information at the point of information collection.  We may also use information from or about you as necessary to perform a contract, to comply with a legal obligation or for our legitimate business interests.  We may also rely on other legal bases, specifically for:
        </p>

        <p class="sub-title"><li class="list-color">Providing the functionality of the Service and fulfilling your requests.</li></p>

        <ul class="list-style" type="circle">
            <li>
                to provide the functionality of the Service to you and providing you with related customer service;         </li>
            <li>
                to respond to your inquiries and fulfill your requests, such as to send you documents you request or email alerts;         </li>
            <li>
                 to send you important information regarding our relationship with you or regarding the Service, changes to our terms, conditions, and policies and/or other administrative information.         </li>
        </ul>
        <p>
                We will engage in these activities to manage our contractual relationship with you and/or to comply with a legal obligation.
        </p>
        <p class="sub-title"><li class="list-color">Accomplishing our business purposes.</li>   </p>

        <ul class="list-style" type="circle">
            <li>
                for data analysis, for example, to improve the efficiency of the Service;
            </li>
            <li>
                for audits, to verify that our internal processes function as intended and are compliant with legal, regulatory, or contractual requirements;
             </li>
            <li>
                for fraud and security monitoring purposes, for example, to detect and prevent cyberattacks or attempts to commit identity theft;
            </li>
            <li>
                for developing new products and services;
            </li>
            <li>
                for enhancing, improving or modifying our website or products and services;
            </li>
            <li>
                for identifying Service usage trends, for example, understanding which parts of our Service are of most interest to users; and
            </li>
            <li>
                for determining the effectiveness of our promotional campaigns, so that we can adapt our campaigns to the needs and interests of our users.
            </li>
        </ul>
        <p>
             We will engage in these activities to manage our contractual relationship with you, to comply with a legal obligation, and/or because we have a legitimate interest.
              </p>

                <p class="sub-title"><li class="list-color">Analysis of Personal Information to provide personalized services.</li>   </p>

        <ul class="list-style" type="circle">
            <li>
                to better understand you, so that we can personalize our interactions with you and provide you with information and/or offers tailored to your interests;         </li>
            <li>
                to better understand your preferences so that we can deliver content via the Service that we believe will be relevant and interesting to you.         </li>
        </ul>

        <p>
                We will provide personalized services either with your consent or because we have a legitimate interest.
        </p>
        <p>We also disclose information collected through the Service: </p>
        <ul class="list-style">
            <li class="section"> to our affiliates for the purposes described in this Privacy Policy.  A list of our affiliates is available at
                <a target="_blank" href="https://johnsonandjohnson.gcs-web.com/financial-information/sec-filings" class="link"
                data-dom-id="https://johnsonandjohnson.gcs-web.com/financial-information/sec-filings"
                data-event-group="footer">
                http://www.investor.jnj.com/sec.cfm
                </a>
                (click on the link for Form 10K, Exhibit 21, under “SEC Filings”). <b>Medical Device Business Services, Inc</b> . is the party responsible for the management of the jointly-used Personal Information;
            </li>

            <li  class="section">to our third-party partners with whom we have existing business relationships;
            </li>

            <li  class="section">to our third-party service providers who provide services such as website hosting and moderating, mobile application hosting, data analysis, payment processing, order fulfillment, infrastructure provision, IT services, customer service, email and direct mail delivery services, auditing, and other services, in order to enable them to provide services; and
            </li>

            <li  class="section">as permitted by applicable law, to a third party in the event of any reorganization, merger, sale, joint venture, assignment, transfer, or other disposition of all or any portion of our business, assets, or stock (including in connection with any bankruptcy or similar proceedings).
            </li>
        </ul>

        <p>
            In addition, we may use and disclose your information as we believe to be necessary or appropriate:  (a) to comply with legal process or applicable law, which may include laws outside your country of residence; (b) as permitted by applicable law to respond to requests from public and government authorities, which may include authorities outside your country of residence; (c) to enforce our terms and conditions; and (d) to protect our rights, privacy, safety, or property, and/or that of our affiliates, you, or others.  We may also use and disclose your information in other ways, after obtaining your consent to do so.     </p>
        <p>
            We may use and disclose information we collect automatically as described above, under “Automatic Information Collection and Use.”
            In addition, where allowed by applicable law, we may use and disclose information that is not in personally identifiable form for any purpose.  If we combine information that is not in personally identifiable form with information that is identifiable (such as combining your name with your geographical location), we will treat the combined information as personal information as long as it is combined.
        </p>

        <p class="sub-title">LOCALLY STORED DATA </p>

        <p>Certain information, including information you may consider personal to you, may be stored locally on your device, including any mobile device. Please consult with your device’s documentation on how to manage local storage and how to apply appropriate security controls to the device for the protection of such information.  Information or data that is stored on the device is your responsibility and may not be retrievable in the event that your device is lost or inoperable.    </p>

        <p class="sub-title">CHOICES AND ACCESS  </p>

        <p><b>Your choices regarding our use and disclosure of your personal information </b></p>

        <p>We will not use your information for marketing purposes.  We may send you important transactional and administrative messages, from which you cannot opt out. </p>

        <p><b>How you can access, change, or delete your personal information </b></p>

        <p><b>If you would like to review, correct, update, restrict, or delete your personal information, or if you would like to request to receive an electronic copy of your personal information for purposes of transmitting it to another company (to the extent these rights are provided to you by applicable law), please contact us via e-mail with your request to <a href="mailto:info@velysinsights.com">info&#64;velysinsights.com</a>.  We will respond to your request as soon as reasonably practicable and no later than one month after receipt. If circumstances cause any delay in our response, you will be promptly notified and provided a date for our response.  </b></p>

        <p class="sub-title">CROSS-BORDER TRANSFER</p>
        <p>Your personal information may be stored and processed in any country where we have facilities or service providers, and by using our Service or by providing consent to us (where required by law), your information may be transferred to countries outside of your country of residence, including to the United States, which may provide for different data protection rules than in your country.  Appropriate contractual and other measures are in place to protect personal information when it is transferred to our affiliates or third parties in other countries. </p>

        <p class="sub-title">SECURITY</p>
        <p>We seek to use reasonable organizational, technical, and administrative measures designed to protect personal information under our control.  Unfortunately, no data transmission over the Internet or data storage system can be guaranteed to be 100% secure.  If you have reason to believe that your interaction with us is no longer secure (for example, if you feel that the security of any account you have with us has been compromised), please immediately notify us in accordance with the <i>“Contacting Us”</i> section below. </p>

        <p class="sub-title">RETENTION PERIOD </p>
        <p>We will retain your personal information for as long as needed or permitted in light of the purpose(s) for which it was obtained.  The criteria used to determine our retention periods include:  (i) the length of time we have an ongoing relationship with you and provide the Service to you; (ii) whether there is a legal obligation to which we are subject; and (iii) whether retention is advisable in light of our legal position (such as in regard to applicable statutes of limitations, litigation, or regulatory investigations). </p>

        <p class="sub-title">THIRD PARTY SITES AND SERVICES </p>
        <p>This Privacy Policy does not address, and we are not responsible for, the privacy, information, or other practices of any third parties, including any third party operating any site or online service (including, without limitation, any application) that is available through this Service or to which this Service contains a link.  The availability of, or inclusion of a link to, any such site or property on the Service does not imply endorsement of it by us or by our affiliates. </p>

        <p class="sub-title">CONTACTING US </p>

        <p><b>Medical Device Business Services, Inc.</b> located at 700 Orthopaedic Dr., Warsaw, IN  46582 is the company responsible for collection, use, and disclosure of personal information under this Privacy Policy. </p>
        <p>If you have any questions about this Privacy Policy, please contact us via e-mail with your request to <a href="mailto:info@velysinsights.com">info&#64;velysinsights.com</a>, or please write to the following address: </p>

        <p class="contact-us">Medical Device Business Services, Inc.<br/>

            700 Orthopaedic Dr. <br/>

            Warsaw, IN 46582 </p>

        <p class="sub-title">UPDATES TO THIS PRIVACY POLICY </p>
        <p>We may change this Privacy Policy.  Any changes to this Privacy Policy will
                                   become effective when we post the revised Privacy Policy on the Service.
                                   Your use of the Service following these changes means that you accept the
                                   revised Privacy Policy.
                                   We recommend that you regularly review the Privacy Policy when you visit
                                   the Service. This policy was last updated on
                                   <span><b> January 20, 2020</b></span>
                                   </p>
      </div>

      <div class="privacy-and-terms-selection">
        <div class="form-item">
            <label class="checkbox-container privacy-and-terms-selection-label" for="privacy-and-terms-selection-checkbox">By clicking on 'I Accept' below, I Confirm that I have read and agree to the terms of use and privacy policy.
                <input class="custom-input custom-input-gray form-input"
            #terms
            id="privacy-and-terms-selection-checkbox"
            data-dom-id="i_accept"
            formControlName="accepted"
            type="checkbox"
            (click)="selectTerms(terms)"
            [disabled]="isSubmitted" />
                <span class="checkmark"></span>
            </label>
        </div>
      </div>

      <div class="form-buttons">
        <button type="submit"
                class="button submit-button"
                data-dom-id="i_accept_submit"
                [disabled]="!privacyAndTermsForm.value.accepted || isSubmitted"
                (click)="submitForm(privacyAndTermsForm.value.accepted)">
          I Accept
      </button>
      </div>

    </form>
  </div>
</div>
