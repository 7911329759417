import {
    ComponentFactoryResolver,
    ComponentRef,
    Injectable,
    ViewContainerRef
} from '@angular/core';

import clone from 'lodash/clone';
import merge from 'lodash/merge';

@Injectable()
export class HpHcpDynamicLoaderService {

    public constructor(
        private _factoryResolver: ComponentFactoryResolver
    ) { }

    public load(viewContainerRef: ViewContainerRef, dynamicComponent, params = {}): ComponentRef<any> {
        viewContainerRef.clear();

        const factory = this._factoryResolver.resolveComponentFactory(dynamicComponent);
        let component = factory.create(viewContainerRef.parentInjector);
        component = this.setComponentParameters(component, params);
        viewContainerRef.insert(component.hostView);

        return component;
    }

    private setComponentParameters(componentInstance, instance) {
      return merge(clone(componentInstance), { instance });
    }

}
