import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HpHcpAuth } from '../../shared/service/hp-hcp-auth.service';
import { UserModel } from '../../shared/model/user.model';
import { HpHcpUrlRedirect } from '../../shared/service/hp-hcp-url-redirect.service';
import { AppConstants } from '../../config/constants';
import { DOCUMENT } from '@angular/common';

const EXPIRED_PASSWORD_STATUS_CODE = 412;

/*
 * @author      @version    @date           @description
 * HSenevir     01          Nov 29, 2023    AFLL-20257 - Enable SSO using Auth code
 */
@Component({
    selector: 'hp-hcp-sso-app',
    templateUrl: './sso.html',
    styleUrls: ['./sso.scss']
})
export class SsoComponent implements OnInit {
    private postLoginRoute = '';
    public isErrorMessageShown = false;
    public errorMessage = '';

    constructor(
        private routeParams: ActivatedRoute,
        private authService: HpHcpAuth,
        private router: Router,
        private urlRedirectService: HpHcpUrlRedirect,
        @Inject(DOCUMENT) private document: Document
    ) {

    }

    public ngOnInit() {
        this.routeParams.queryParams.subscribe(({code}) => {
            if (code) {
                this.sso(code);
            }
        });
    }

    public sso(code) {
        this.authService.sso(
            {code},
            this.successfulLogin.bind(this),
            this.failedLogin.bind(this)
        );
    }

    private failedLogin({message, code}) {
        if (code === EXPIRED_PASSWORD_STATUS_CODE) {
            const expiredPassword = true;
            this.router.navigate(['forgotpassword'], {queryParams: {expiredPassword}});
        }
        this.errorMessage = message;
        this.isErrorMessageShown = true;
    }

    private successfulLogin(userData: UserModel = null) {
        const orgSelectionRequired = this.authService.orgSelectionPermitted();
        orgSelectionRequired
            ? this.navigateToOrganizationsPage()
            : this.postLoginRoute
            ? this.navigateWithPageViewToRequestedPage(userData)
            : this.navigateWithPageViewToMainPage(userData);
    }

    private navigateWithPageViewToRequestedPage(userData) {
        const navigateNext = this.router.navigateByUrl(this.postLoginRoute);
        if (userData) {
            navigateNext.then(() => {
                window.location.reload();
            });
        }
    }

    private navigateWithPageViewToMainPage(userData) {
        const navigateNext = this.router.navigate([this.urlRedirectService.urls.dashboard]);
        if (userData) {
            navigateNext.then(() => {
                window.location.reload();
            });
        }
    }

    private navigateToOrganizationsPage() {
        const redirect: string = this.postLoginRoute;
        this.router
            .navigate([
                this.urlRedirectService.urls.organizations,
                {redirect}
            ]);
    }

    public navigateToVDSPortal() {
        this.document.location.href = AppConstants.vdsUrl;
    }
}
