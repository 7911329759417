import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { HcpDatePipe } from './pipe/hcp-date-pipe';
import { HcpDomIdPipe } from './pipe/hcp-dom-id.pipe';
import { HcpDomIdPrefixedIndex } from './pipe/hcp-dom-id-prefixed-index.pipe'
import { HcpPhoneFormatPipe } from './pipe/hcp-phone-format.pipe';
import { RoundPipe } from './pipe/round.pipe';
import { SpacesToUnderscoresPipe } from './pipe/spaces-to-underscores.pipe';
import { HyphenWordToCaps } from './pipe/hyphen-word-to-caps';

@NgModule({
    imports: [
        CommonModule
    ],
    exports: [
        HcpDatePipe,
        HcpDomIdPipe,
        HcpDomIdPrefixedIndex,
        HcpPhoneFormatPipe,
        RoundPipe,
        SpacesToUnderscoresPipe,
        HyphenWordToCaps
    ],
    declarations: [
        HcpDatePipe,
        HcpDomIdPipe,
        HcpDomIdPrefixedIndex,
        HcpPhoneFormatPipe,
        RoundPipe,
        SpacesToUnderscoresPipe,
        HyphenWordToCaps
    ]
})
export class PipeModule {}
