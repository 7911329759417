/**
 * Copyright: Copyright © 2023
 * This file contains trade secrets of Johnson & Johnson. No part may be reproduced or transmitted in any
 * form by any means or for any purpose without the express written permission of Johnson & Johnson.
 */

import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { HpHcpActionListService } from './service/hp-hcp-action-list.service';
import { HpHcpAdvanceSavedFilterService } from './service/hp-hcp-advance-saved-filter.service';
import { HpHcpAdvanceSearchFilterService } from './service/hp-hcp-advance-search-filter.service';
import { HpHcpAuth } from './service/hp-hcp-auth.service';
import { HpHcpBctEventService } from './service/hp-hcp-bct-event.service';
import { HpHcpBrowserPrint } from './service/hp-hcp-browser-print.service';
import { HpHcpCropper } from './service/hp-hcp-cropper.service';
import { HpHcpDatePickerService } from './service/hp-hcp-date-picker.service';
import { HpHcpDocumentTitleService } from './service/hp-hcp-document-title.service';
import { HpHcpDynamicLoaderService } from './service/hp-hcp-dynamic-loader.service';
import { HcpEmailPreferencesService } from './service/hp-hcp-email-preferences.service';
import { HpHcpFileReader } from './service/hp-hcp-file-reader.service';
import { HpHcpGAService } from './service/hp-hcp-ga.service';
import { HpHcpHttp } from './service/hp-hcp-http.service';
import { HpHcpInputTextFormService } from './service/hp-hcp-input-text-format.service';
import { HpHcpLegalDocumentService } from './service/hp-hcp-legal-document.service';
import { HpHcpLocalStorage, STORAGE_NAMES } from './service/hp-hcp-localstorage.service';
import { HpHcpChartsInfoMessagesService } from './service/hp-hcp-charts-info-message.service';
import { HpHcpMultOrgService } from './service/hp-hcp-multi-org.service';
import { HpHcpNotificationsService } from './service/hp-hcp-notification.service';
import { HpHcpRefreshKeyService } from './service/hp-hcp-refresh-key.service';

import { TkrCohortData } from './service/tkr-cohort-data.service';
import { TkrRiskData } from './service/tkr-patient-risk-data.service';
import { TkrPostOpMetricData } from './service/tkr-patient-post-op-metric-data.service';
import { TkrKoosHoosData } from './service/tkr-patient-koos-hoos-data.service';
import { TkrAsesData } from './service/tkr-patient-ases-data.service';
import { TkrCancelSurgeryService } from './service/tkr-cancel-surgery.service';
import { TkrHcpChecklistService } from './service/tkr-hcp-checklist.service';
import { TkrDsIntakeFormService } from './service/tkr-ds-intake-form.service';
import { TkrSiteLocation } from './service/tkr-site-location.service';
import { TkrExternalSsoService } from './service/tkr-ds-sso-navigation.service';
import { PatientGenericMediaService } from './service/tkr-patient-generic-media.service';
import { PatientIntraOpNotesService } from './service/tkr-patient-intra-op-notes.service';

import {
    HpHcpManageSurgeonGuard,
    HpHcpRoutesAuthGuard,
    HpHcpRoutesBariatricsGuard,
    HpHcpRoutesEmptyOrganizationGuard,
    HpHcpRoutesOrganizationalAdminGuard
} from './service/hp-hcp-routes-guard.service';
import { HpHcpScrollService } from './service/hp-hcp-scroll.service';
import { HpHcpSessionSync } from './service/hp-hcp-session-sync.service';
import { HpHcpSessionPolling } from './service/hp-hcp-session-polling.service';
import { HpHcpSurgeonService } from './service/hp-hcp-surgeon.service';
import { HpHcpUrlRedirect } from './service/hp-hcp-url-redirect.service';
import { HpHcpUserAgentService } from './service/hp-hcp-user-agent.service';
import { HpHcpUserConfigService } from './service/hp-hcp-user-config.service';
import { HpHcpUsersService } from './service/hp-hcp-users.service';
import { HpHcpUserSession } from './service/hp-hcp-user-session.service';
import { HpHcpValidators } from './service/hp-hcp-validators.service';
import { HelperUtility } from './utils/helper-utility';
import { TkrDsAppointmentService } from './service/tkr-ds-appointment.service';
import { TkrHcpVrasService } from './service/tkr-hcp-vras.service';

/*
 * @author      @version    @date           @description
 * HSenevir     1           Mar 10, 2023    AFLL-15210 Added Vras Service class
 * PPareek      2           Jul 18, 2024    AFLL-21762 | Changes done to upgrade to Angular v 18
 *
 */

@NgModule({ imports: [CommonModule], providers: [
        HpHcpDynamicLoaderService,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class ServiceModule {
    public static forRoot(): ModuleWithProviders<ServiceModule> {
        return {
            ngModule: ServiceModule,
            providers: [
                HpHcpActionListService,
                HpHcpAdvanceSavedFilterService,
                HpHcpAdvanceSearchFilterService,
                HpHcpAuth,
                HpHcpBctEventService,
                HpHcpBrowserPrint,
                HpHcpCropper,
                HpHcpDatePickerService,
                HpHcpDocumentTitleService,
                HcpEmailPreferencesService,
                HpHcpFileReader,
                HpHcpGAService,
                HpHcpHttp,
                HpHcpInputTextFormService,
                HpHcpLegalDocumentService,
                HpHcpLocalStorage,
                HpHcpChartsInfoMessagesService,
                HpHcpManageSurgeonGuard,
                HpHcpMultOrgService,
                HpHcpNotificationsService,
                HpHcpRefreshKeyService,
                HpHcpRoutesAuthGuard,
                HpHcpRoutesBariatricsGuard,
                HpHcpRoutesEmptyOrganizationGuard,
                HpHcpRoutesOrganizationalAdminGuard,
                HpHcpScrollService,
                HpHcpSessionPolling,
                HpHcpSessionSync,
                HpHcpSurgeonService,
                HpHcpUrlRedirect,
                HpHcpUserAgentService,
                HpHcpUserConfigService,
                HpHcpUserSession,
                HpHcpUsersService,
                HpHcpValidators,
                TkrCohortData,
                TkrRiskData,
                TkrPostOpMetricData,
                TkrKoosHoosData,
                TkrAsesData,
                TkrCancelSurgeryService,
                TkrHcpChecklistService,
                TkrHcpVrasService,
                TkrDsIntakeFormService,
                TkrDsAppointmentService,
                TkrSiteLocation,
                TkrExternalSsoService,
                PatientGenericMediaService,
                PatientIntraOpNotesService,
                HelperUtility,
                { provide: 'STORAGE_NAMES', useValue: STORAGE_NAMES }
            ]
        };
    }
}