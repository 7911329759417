import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { DirectiveModule } from './directive.module';
import { PipeModule } from './pipe.module';
import { UiModule } from './ui.module';

@NgModule({
    imports: [ TranslateModule ],
    exports: [
        DirectiveModule,
        PipeModule,
        UiModule,
        TranslateModule
    ]
})
export class SharedModule { }
