import { Injectable } from '@angular/core';
import { HpHcpHttp } from './hp-hcp-http.service';

@Injectable()
export class TkrPostOpMetricData {

    constructor (
        private _hpHcpHttp: HpHcpHttp
    ) {}

    public getPatientStepsData(patientId, success, failure) {
        this._hpHcpHttp.get(this._hpHcpHttp.urls.chart.stepData,{ urlParam: patientId }).subscribe(success, failure);
    }
    
    public getPatientVASPainData(patientId, success, failure) {
        this._hpHcpHttp.get(this._hpHcpHttp.urls.chart.vasPainData,{ urlParam: patientId }).subscribe(success, failure);
    }

    public getPatientROMData(patientId, success, failure) {
        this._hpHcpHttp.get(this._hpHcpHttp.urls.chart.romData,{ urlParam: patientId }).subscribe(success, failure);
    }

    public getPreOpBaselineData(patientId, success, failure) {
        this._hpHcpHttp.get(this._hpHcpHttp.urls.chart.preOpBaselineData,{ urlParam: patientId }).subscribe(success, failure);
    }
}