<footer
    [ngClass]="{'tkr-footer': isAuthenticated, 'tkr-footer-unsigned': !isAuthenticated, 'tkr-footer-unsigned-sso': isSso}">
    <mat-toolbar color="primary" class="footer-container">
        <mat-toolbar-row class="footer-links">

            <img src="../../../../assets/img/DPS_Logo@2x.png" class="dps-logo-img" alt="Depuy Synthes">
            <button mat-button color="primary" class="footer-button" (click)="goToPrivacyPolicyScreen()">
                <span class="footer-text" translate>footer.privacyPolicy</span>
            </button>
            <button mat-button color="primary" class="footer-button" (click)="goToCookiePolicyScreen()">
                <span class="footer-text" translate>footer.cookiePolicy</span>
            </button>
            <button id="ot-sdk-btn" *ngIf="userSession" class="ot-sdk-show-settings" (click)="goToCookieSettings()" translate>footer.cookieSettings</button>
            <button mat-button color="primary" class="footer-button" (click)="goToContactUsScreen()">
                <span class="footer-text" translate>footer.contactUs</span>
            </button>
            <button mat-button color="primary" class="footer-button" *ngIf="country=='US'">
                <span class="footer-text">
                <a target="_blank"
                    href="https://privacyportal.onetrust.com/webform/96f23ee1-34e3-41d6-8d5a-07f0d554152b/659417d6-807f-4eb1-87a8-709ce6363e5c?website=velys.jnj.com"
                    class="link" translate>footer.doNotSellOrShareTextOne <br/> footer.doNotSellOrShareTextTwo</a>
                </span>
            </button>

        </mat-toolbar-row>
        <mat-toolbar-row class="footer-copyright">
            <p class="footer-copyright-text" translate [translateParams]="{year: year}">
                footer.copyrightTextOne<br />footer.copyrightTextTwo<br />footer.copyrightTextThree</p>
        </mat-toolbar-row>
    </mat-toolbar>
</footer>
