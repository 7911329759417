/**
 * Copyright: Copyright © 2023
 * This file contains trade secrets of Johnson & Johnson. No part may be reproduced or transmitted in any
 * form by any means or for any purpose without the express written permission of Johnson & Johnson.
 */

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HpHcpHttp } from './hp-hcp-http.service';

/*
 * @author      @version    @date           @description
 * HSenevir     1           Mar 10, 2023    AFLL-15210 Added VRAS service class for VRAS related API calls
 * EElangav     2           Mar 17, 2023    AFLL-15210 Fixed the caseReport get endpoint routing
 * TPreena      3           Mar 30, 2023    AFLL-15233 Integrated CSV Download API function
 * RPenimos     4           Jan 04, 2024    AFLL-20289 VPP/VI Portal | Generate surgeon's report PDF in VPP and VI Portal 
*/
@Injectable()
export class TkrHcpVrasService {
    public getCaseReport = new Subject();

    constructor(
        private hpHcpHttp: HpHcpHttp
    ) { }

    public uploadAndVerifyCaseReport(requestBody, success, failure) {
        const optionalParams = {
            requestBody: requestBody,
            isFormData: true
        };
        return this.hpHcpHttp.post(this.hpHcpHttp.urls.vras.verifyCaseFile, optionalParams).subscribe(success, failure);
    }

    public uploadAndSelectCaseReportFile(userId, requestBody, success, failure) {
        const optionalParams = {
            requestBody: requestBody,
            urlParam: userId,
            isFormData: true
        };
        return this.hpHcpHttp.post(this.hpHcpHttp.urls.vras.caseReport, optionalParams).subscribe(success, failure);
    }

    public getSelectedCaseReportFile(userId, success, failure) {
        return this.hpHcpHttp.get(this.hpHcpHttp.urls.vras.caseReport, { urlParam: userId }).subscribe(success, failure);
    }

    public downloadCSVOfCaseReport(userId, success, failure) {
        return this.hpHcpHttp.get(this.hpHcpHttp.urls.vras.downloadCaseReport, { urlParam: userId }).subscribe(success, failure);
    }

    public getDeletedCaseReportFile (userId, success, failure) {
        return this.hpHcpHttp.delete(this.hpHcpHttp.urls.vras.caseReport, { urlParam: userId }).subscribe(success, failure);
    }

    public downloadPDFCaseReport(userId, success, failure) {
        return this.hpHcpHttp.get(this.hpHcpHttp.urls.vras.downloadPDF, { urlParam: userId }).subscribe(success, failure);
    }
}
