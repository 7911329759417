<div class="hp-hcp-patient-surgery-item">
    <div class="information">
        <div class="title">
            <div class="icon" *ngIf="completed">
                <i class="fa fa-check-circle item-done"></i>
            </div>
             <div class="icon" *ngIf="isOverdue && !completed">
                 <img src="../../../../assets/img/flag.png" alt="overdue" />
            </div>
            <span class="name" [ngClass]="{'item-due': isOverdue && !completed }">{{title}}</span>
            <i class="fa fa-refresh recurring" *ngIf="isRecurring"></i>
            <span class="name patient-added-tag" *ngIf="patientAdded && !isRecurring">(Patient Added)</span>
        </div>
        <div class="date">{{date}}</div>
        <div class="date" *ngIf="occurrenceDate">{{occurrenceDate}}</div>
        <ng-content></ng-content>
    </div>
    <div class="additional" *ngIf="type">
        <div class="type">
            <span>{{type}}</span>
        </div>
    </div>
</div>
