import { Injectable } from '@angular/core';
import { HpHcpHttp } from './hp-hcp-http.service';
import { Observable } from 'rxjs';
import { PatientRiskScoreModel } from '../../shared/model/patient-risk-score.model'

@Injectable()
export class TkrRiskData {

    constructor (
        private _hpHcpHttp: HpHcpHttp
    ) {}

    public getPatientRiskData(patientId, siteId, success, failure) {
        this._hpHcpHttp.get(this._hpHcpHttp.urls.patient.riskScore +"/"+ patientId +"/"+ siteId).subscribe(success, failure);
    }

    public getPatientRiskDataFromModel(patientId): Observable<PatientRiskScoreModel> {
        return this._hpHcpHttp.get(this._hpHcpHttp.urls.patient.riskScore +"/"+ patientId);
    }
}