import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { flatMap, mergeMap } from 'rxjs/operators';

import { HpHcpAdvanceSavedFilterService } from '../../shared/service/hp-hcp-advance-saved-filter.service';
import { HpHcpAdvanceSearchFilterService } from './hp-hcp-advance-search-filter.service';
import { HpHcpHttp } from './hp-hcp-http.service';
import { HpHcpUrlRedirect } from './hp-hcp-url-redirect.service';
import { HpHcpUserSession } from './hp-hcp-user-session.service';
import { UserModel } from '../model/user.model';
import { environment } from '../../../environments/environment';

export const INCORRECT_EXISTING_PASSWORD_STATUS_CODE = 403;
export const PASSWORD_EMPTY_ERROR = 'Password cannot be empty.';
export const PASSWORD_STRENGTH_CRITERIA_ERROR = 'Password does not meet strength criteria. Please enter a valid password.';
export const PASSWORDS_MATCH_ERROR = 'Password does not match what you entered above. Please try again.';
export const RECENTLY_USED_STATUS_CODE = 412;
export const RECENTLY_CHANGED_STATUS_CODE = 416;

@Injectable()
export class HpHcpAuth {

    private userLoggedIn = new Subject<boolean>();

    constructor (
        private _hpHcpAdvanceSavedFilterService: HpHcpAdvanceSavedFilterService,
        private _hpHcpAdvanceSearchFilterService: HpHcpAdvanceSearchFilterService,
        private _hpHcpHttp: HpHcpHttp,
        private _hpHcpUrlRedirect: HpHcpUrlRedirect,
        private _hpHcpUserSession: HpHcpUserSession,
        private _router: Router
    ) {
        this.userLoggedIn.next(false);
    }

    public forgotPassword(requestBody, success, failure) {
        this._hpHcpHttp
            .post(this._hpHcpHttp.urls.user.forgotPassword, { requestBody })
            .subscribe(success, failure);
    }

    public newPassword({ newPassword, passwordResetKey, userType }) {
        const requestBody = { newPassword, passwordResetKey };
        return this._hpHcpHttp.post(this._hpHcpHttp.urls.user.forgotPasswordReset, { requestBody, urlParam: userType });
    }

    public login(requestBody, success, failure) {
        const onSuccess = (loginResponse: any) => {
            environment.country = loginResponse.organization.country;
            if (loginResponse.legalRequired) {
                this._hpHcpUserSession.setUserLegalAcceptSession(loginResponse);
                this._router.navigate([this._hpHcpUrlRedirect.urls.privacyAndTerms]);
                return;
            }
            this.setUserLoggedIn(true);
            this._storeUserData(loginResponse);
            success(loginResponse);
        };

        this._clearPreviousServerSession()
          .pipe(
              flatMap(() => this._hpHcpHttp.post(this._hpHcpHttp.urls.user.login, { requestBody }))
          ).subscribe(onSuccess, failure);
    }

    public sso(requestBody, success, failure) {
        const onSuccess = (loginResponse: any) => {
            if (loginResponse.legalRequired) {
                this._hpHcpUserSession.setUserLegalAcceptSession(loginResponse);
                this._router.navigate([this._hpHcpUrlRedirect.urls.privacyAndTerms]);
                return;
            }
            this.setUserLoggedIn(true);
            this._storeUserData(loginResponse);
            success(loginResponse);
        };

        this._clearPreviousServerSession()
            .pipe(
                mergeMap(() => this._hpHcpHttp.post(this._hpHcpHttp.urls.user.sso, { requestBody }))
            ).subscribe(onSuccess, failure);
    }

    public removeLoginData(callback?: Function) {
        this.setUserLoggedIn(false);
        this._hpHcpAdvanceSavedFilterService.disposeData();
        this._hpHcpAdvanceSearchFilterService.disposeData();
        this._hpHcpUserSession.clearSession();
        if (callback) {
          callback();
        }
    }

    public getLogoutParams(): string {
        // if window.name is available, this means we should not clear vds session.
        let prams = '';
        if (window.name) {
            prams = 'cl=false';
        }
        return prams;
    }

    public logout(success, failure) {
        const onSucess = () => this.removeLoginData(success);
        this._hpHcpHttp
            .get(this._hpHcpHttp.urls.user.logout)
            .subscribe(onSucess, failure);
    }

    public resetPassword({ currentPassword, newPassword }) {
        const requestBody = {
            newPassword,
            password: currentPassword
        };

        return this._hpHcpHttp.post(this._hpHcpHttp.urls.user.resetPassword, { requestBody });
    }

    public verifyPasswordResetKey({ passwordResetKey, userType }, failure) {
        const successfulRequest = () => undefined;
        this._hpHcpHttp
            .post(this._hpHcpHttp.urls.user.verifyPasswordResetKey, { requestBody: { passwordResetKey }, urlParam: userType })
            .subscribe(successfulRequest, failure);
    }

    public verifyStaffInvitationCode(invitationCode) {
        return this._hpHcpHttp.post(
          this._hpHcpHttp.urls.user.verifyStaffInvitationCode,
          { requestBody: invitationCode }
        );
    }

    public declineAccountActivation(invitationCode) {
        return this._hpHcpHttp.post(
          this._hpHcpHttp.urls.user.declineAccountActivation,
          { requestBody: {invitationCode} }
        );
    }

    public activateStaffAccountByInvitation(requestBody) {
        return this._hpHcpHttp
          .post(this._hpHcpHttp.urls.user.activateStaffAccount, { requestBody });
    }

    public orgSelectionPermitted() {
        const isOrgAdmin = this._hpHcpUserSession.isOrganizationalAdmin();
        const isMultiOrgUser = this._hpHcpUserSession.isMultiOrgUser();
        return isOrgAdmin && isMultiOrgUser;
    }

    private _clearPreviousServerSession(): Observable<Object> {
        return this._hpHcpHttp.get(this._hpHcpHttp.urls.user.logout);
    }

    private _storeUserData(userData: UserModel) {
        this._hpHcpUserSession.setUserSession(userData);
    }

    private setUserLoggedIn(userLoggedIn: boolean) {
        this.userLoggedIn.next(userLoggedIn);
    }

    public getUserLoggedIn(): Observable<boolean> {
        return this.userLoggedIn.asObservable();
    }

}
