import { Injectable } from '@angular/core';
import { HpHcpHttp } from './hp-hcp-http.service';

@Injectable()
export class TkrDsAppointmentService {

    constructor(
        private hpHcpHttp: HpHcpHttp
    ) { }

    public createAppointment(patientId, appointmentDetails, success, failure) {
        return this.hpHcpHttp.post(this.hpHcpHttp.urls.appointment.createAppointment, { urlParam: patientId, requestBody: appointmentDetails }).subscribe(success, failure);
    }

    public updateAppointment(patientId, appointmentDetails, success, failure) {
        return this.hpHcpHttp.put(this.hpHcpHttp.urls.appointment.updateAppointment, { urlParam: patientId, requestBody: appointmentDetails }).subscribe(success, failure);
    }

    public sendTelehealthInvite(docsperaPatientId, docsperaEventId, success, failure) {
        return this.hpHcpHttp.post(this.hpHcpHttp.urls.appointment.telehealthInvite + docsperaEventId + '/telehealth', { urlParam: docsperaPatientId }).subscribe(success, failure);
    }

    public getTelehealthMeetUrl(docsperaPatientId, docsperaEventId, success, failure) {
        return this.hpHcpHttp.get(this.hpHcpHttp.urls.appointment.telehealthInvite + docsperaEventId + '/telehealth', { urlParam: docsperaPatientId }).subscribe(success, failure);
    }

    public getAppointments(userId, success, failure) {
        return this.hpHcpHttp.get(this.hpHcpHttp.urls.appointment.getAppointments, { urlParam: userId }).subscribe(success, failure);
    }
}
