import {HpHcpUserSession} from '../service/hp-hcp-user-session.service';
import {Injectable} from '@angular/core';
import { EMR } from '../constants/product-types';
@Injectable()
/*
 * @author      @version    @date           @description
 * SVijay16     01          Jul 05, 2023    AFLL-17977 Hip Risk Score I Edit Patient Info - Approach (Cemented/Uncemented) & Computer Assisted Surgery (Yes/No)
 * SVijay16     02          Jul 20, 2023    AFLL-18773 - Display 'High Risk Outcomes' column for primary knee surgery patients in the Dashboard
 * SVijay16     03          Jul 31, 2023    AFLL-17910 Portal | Update existing EMR and Frontline Knee Risk Score checks across the services
 * SVijay16     04          Aug 09, 2023    AFLL-17910 Added function that returns if the careplan of the patient and the associated risk score is enabled
 * SVijay16     05          Aug 14, 2023    AFLL-17910 Passed sites array to utility function.
 * SVijay16     06          Aug 14, 2023    AFLL-19416 Added null check fr site config features
 */
export class HelperUtility {

    constructor (
        private _hpHcpUserSession: HpHcpUserSession
    ) { }

    public hasPatientSiteFrontline(sites: Array<any>): boolean {
        let isFrontLine = false;
        for (let i = 0; i < sites.length; i++) {
            isFrontLine = (this.hasRiskScoreEnabledSite(sites, 'hip') || this.hasRiskScoreEnabledSite(sites, 'knee'));
            if (isFrontLine) {
                break;
            }
        }
        return isFrontLine;
    }

    public isKneeRiskScorePatient(sites: Array<any>, carePlanKey: string) {
        return this.hasRiskScoreEnabledSite(sites, 'knee') && this.isKneeCarePlanSelected(carePlanKey);
    }

    public isHipRiskScorePatient(sites: Array<any>,  carePlanKey: string) {
        return this.hasRiskScoreEnabledSite(sites, 'hip') && this.isHipCarePlanSelected(carePlanKey);
    }

    public hasPatientSiteEMR(sites: Array<any>): boolean {
        let isEMR = false;
        for (let i = 0; i < sites.length; i++) {
            isEMR = sites[i].siteConfigFeatures ? sites[i].siteConfigFeatures.emr : false;
            if (isEMR) {
                break;
            }
        }
        return isEMR;
    }

    public hasRiskScoreEnabledSite(sites: Array<any>, plan: String): boolean {
        let isRiskScoreEnabled = false;
        let isEMREnabled = false;
        for (let i = 0; i < sites.length; i++) {
            isEMREnabled = sites[i].siteConfigFeatures ? sites[i].siteConfigFeatures.emr : false;
            if (plan == "knee") {
                isRiskScoreEnabled = sites[i].siteConfigFeatures ? sites[i].siteConfigFeatures.kneeRiskScore : false;
            } else if (plan == "hip") {
                isRiskScoreEnabled = sites[i].siteConfigFeatures ? sites[i].siteConfigFeatures.hipRiskScore : false;
            }
            if (isEMREnabled && isRiskScoreEnabled) {
                break;
            }
        }
        return isEMREnabled && isRiskScoreEnabled;
    }

    /*Checking if all the sites are fontline enabled to determine if we need to remove risk score column completely */
    public isFrontlineAvailableAllSites(): boolean {
        let isFrontline = false;
        const userSession =  this._hpHcpUserSession.getUserSession();
        for (let i = 0; i < userSession.sites.length; i++) {
            let featureList = userSession.sites[i].productDetails.features
            let isSiteFrontlineEnabled = featureList.indexOf('frontline') !== -1;
            isFrontline = isSiteFrontlineEnabled;
            // if one of the sites does not have frontline enabled, then we do not need to check the rest
            if (!isSiteFrontlineEnabled) {
                break;
            }
        }
        return isFrontline;
    }

    public isEmrSiteForSiteId(selectedSiteId: number): boolean {
        let isEmrSite = false;
        const userSession =  this._hpHcpUserSession.getUserSession();
        for (let i = 0; i < userSession.sites.length; i++) {
            if (userSession.sites[i].id === selectedSiteId) {
                isEmrSite = userSession.sites[i].siteConfigFeatures ? userSession.sites[i].siteConfigFeatures.emr : false;
                break;
            }
        }
        return isEmrSite;
    }

    public isHipProcedureSelected(procedureType: String): boolean {
        let isHipProcedure = false;
        if (procedureType.toLowerCase() === 'hip replacement') {
            isHipProcedure = true;
        }
        return isHipProcedure;
    }

    public isShoulderProcedureSelected(procedureType: String): boolean {
        let isShoulderProcedure = false;
        if (procedureType.toLowerCase() === 'shoulder replacement') {
            isShoulderProcedure = true;
        }
        return isShoulderProcedure;
    }

    public isKneeProcedureSelected(procedureType: String): boolean {
        let isKneeProcedure = false;
        if (procedureType.toLowerCase() === 'knee replacement') {
            isKneeProcedure = true;
        }
        return isKneeProcedure;
    }

    public isRevisionProcedureSelected(procedureType: any): boolean {
        let isRevisionProcedure = false;
        if (procedureType != null && procedureType.toLowerCase().indexOf('revision') !== -1) {
            isRevisionProcedure = true;
        }
        return isRevisionProcedure;
    }

    public isProcedureNotAssigned(procedureType: any): boolean {
        let isProcedureNotAssigned = false;
        if (procedureType != null && procedureType.toLowerCase().indexOf('not assigned') !== -1) {
            isProcedureNotAssigned = true;
        }
        return isProcedureNotAssigned;
    }

    public isHipCarePlanSelected(carePlanKey: String): boolean {
        return carePlanKey.includes('hips_care_module'); 
    }

    public isKneeCarePlanSelected(carePlanKey: String): boolean {
        return carePlanKey.includes('knees_care_module'); 
    }

    public isShoulderCarePlanSelected(carePlanKey: String): boolean {
        return carePlanKey.includes('shoulders_care_module'); 
    }

    public isOverflow(el: HTMLElement): boolean {
        let curOverflow = el.style.overflow;
        if (!curOverflow || curOverflow === 'visible') {
            el.style.overflow = 'hidden';
        }
        let isOverflowing = el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight;
        el.style.overflow = curOverflow;
        return isOverflowing;
    }

    isPromsEnabled() {
        const userSession =  this._hpHcpUserSession.getUserSession();
        for (let i = 0; i < userSession.sites.length; i++) {
            if (userSession.sites[i].siteConfigFeatures && userSession.sites[i].siteConfigFeatures.hasOwnProperty('promsExporting') &&
            userSession.sites[i].siteConfigFeatures.promsExporting) {
                return true;
            }
        }
        return false;
    }
    
    isRTMEnabled() {
        const userSession = this._hpHcpUserSession.getUserSession();
        for (let i = 0; i < userSession.sites.length; i++) {
            if (userSession.sites[i].siteConfigFeatures && userSession.sites[i].siteConfigFeatures.hasOwnProperty('rtm') &&
            userSession.sites[i].siteConfigFeatures.rtm) {
                return true;
            }
        }
        return false;
    }
}
