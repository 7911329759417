/**
 * Copyright: Copyright © 2023
 * This file contains trade secrets of Johnson & Johnson. No part may be reproduced or transmitted in any
 * form by any means or for any purpose without the express written permission of Johnson & Johnson.
 */
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HpHcpUrlRedirect } from '../../service/hp-hcp-url-redirect.service';
import { HpHcpUserSession } from '../../service/hp-hcp-user-session.service';
import { UserModel } from '../../model/user.model';
import { TranslateService } from '@ngx-translate/core';
import { SiteModel } from '../../model/site.model';
import { HelperUtility } from '../../utils/helper-utility';

/*
 *  @author     @version    @date           @description
 *  ZDaudy      01          May 25, 2023    AFLL-17602 - RTM | Decouple the Global Navigation and sub-navigation for 'Reports'
 *  JVelagal    02          May 29, 2023    AFLL-17603 RTM | 'Create RTM Reports' page
 */

@Component({
    selector: 'tkr-sub-navigation',
    templateUrl: './sub-navigation.html',
    styleUrls: ['./sub-navigation.scss']
})
export class SubNavigationComponent implements OnInit {
    public isViewReport = false;
    public isCreateProms = false;
    public isCreateRtm = false;
    isShowPROMs: boolean;
    isShowRTM: boolean;
    private userSession: UserModel;

    constructor(
        public urlRedirectService: HpHcpUrlRedirect,
        private router: Router,
        private _helperUtility: HelperUtility,
        private hpHcpUserSession: HpHcpUserSession,
        public translateService: TranslateService
    ) {
        this.isShowPROMs = this._helperUtility.isPromsEnabled();
        this.isShowRTM = this._helperUtility.isRTMEnabled();
    }

    public ngOnInit() {
        this._helperUtility.isPromsEnabled();
        this._helperUtility.isRTMEnabled();
        this.isViewReport = this.urlRedirectService.compareBaseUrl(
            this.urlRedirectService.urls.viewReports, this.router.url
        );
        this.isCreateProms = this.urlRedirectService.compareBaseUrl(
            this.urlRedirectService.urls.createReports, this.router.url
        );
        this.isCreateRtm = this.urlRedirectService.compareBaseUrl(
            this.urlRedirectService.urls.rtmReports, this.router.url
        );
    }

    public navigateToViewReports() {
        const viewReportsUrl = `${this.urlRedirectService.urls.viewReports}`;
        this.router.navigateByUrl(viewReportsUrl);
    }

    goToGeneratedReport() {
        this.router.navigateByUrl(this.urlRedirectService.urls.viewReports);
    }

    public goToCreateReport() {
        this.router.navigateByUrl(this.urlRedirectService.urls.createReports);
    }

    public goToRtmReport() {
        this.router.navigateByUrl(this.urlRedirectService.urls.rtmReports);
    }
}
