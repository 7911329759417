import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '@angular/core';
import { disableDebugTools } from '@angular/platform-browser';

import { AppModule } from './app/app.module';
import { HpHcpSessionSync } from './app/shared/service/hp-hcp-session-sync.service';
import { environment } from './environments/environment';
import { delay, first } from 'rxjs/operators';

const SESSION_SYNC_DELAY = 500;
const sessionSynchronizer = new HpHcpSessionSync();

if (environment.production) {
    enableProdMode();
    disableDebugTools();
}

sessionSynchronizer.syncSession()
    .pipe(delay(SESSION_SYNC_DELAY))
    .pipe(first())
    .subscribe(() => {
        platformBrowserDynamic().bootstrapModule(AppModule);
    });
