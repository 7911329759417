import { Component, EventEmitter, Input, Output } from '@angular/core';

const DISMISS_WITH_COMMENT_CODE = 'DISMISS_WITH_COMMENT';

@Component({
    selector: 'hp-hcp-dismiss-comment',
    templateUrl: './dismiss-comment.html',
    styleUrls: ['./dismiss-comment.scss']
})
export class DismissCommentComponent {

    public comment: string = '';
    public isDismissDisabled: boolean = true;

    @Input() public notificationId: number;
    @Input() public displayOnRightSide: boolean;

    @Output() public onCloseBox: EventEmitter<any> = new EventEmitter();
    @Output() public onDismissWithComment: EventEmitter<any> = new EventEmitter();

    public closeBox() {
        this.onCloseBox.emit();
    }

    public dismissWithComment() {
        const notificationAction = {
            action: DISMISS_WITH_COMMENT_CODE,
            comment: this.comment,
            notificationId: this.notificationId
        };
        this.onDismissWithComment.emit(notificationAction);
        this.closeBox();
    }

    public updateComment(comment) {
        this.comment = comment;
        this.isDismissDisabled = !comment.length;
    }

}
