import { Injectable } from '@angular/core';

const BROWSER_SUPPORTED_LIST = [
    {
        key: 'chrome',
        customName: 'Google Chrome'
    },
    {
        key: 'edge',
        customName: 'Microsoft Edge'
    },
    {
        key: 'firefox',
        customName: 'Firefox'
    },
    {
        key: 'msie',
        customName: 'Internet Explorer'
    },
    {
        key: 'opera',
        customName: 'Opera'
    },
    {
        key: 'opr',
        customName: 'Opera'
    },
    {
        key: 'safari',
        customName: 'Safari'
    }
];

@Injectable()
export class HpHcpUserAgentService {

    private _browserName;

    public constructor() {
        this.setUserBrowserName();
    }

    public getUserBrowserName(): string {
        return this._browserName;
    }

    public setUserBrowserName() {
        let userAgentReturnString: string = navigator.userAgent.toLowerCase();
        let supportedBrowser = BROWSER_SUPPORTED_LIST
            .filter((browser) => userAgentReturnString.indexOf(browser.key.toLowerCase()) !== -1)[0];
        this._browserName = supportedBrowser ? supportedBrowser.customName : 'Other';
    }

}
