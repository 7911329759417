<div #progressBar id="progress-bar-container-{{idPrefix}}-{{id}}" class="hp-hcp-progressbar-circular" [ngClass]="type" [class.critical]="isCritical">

    <div class="inner"
        *ngIf="!shouldDisplayEmptyState()">
        <div>
            <span class="metric fraction" *ngIf="displayPercentage">{{percentage * 100 | round }}%</span>
            <span class="metric fraction" *ngIf="!displayPercentage">{{numerator}}/{{denominator}}</span>
            <span class="metric title" *ngIf="title">{{title}}</span>
        </div>
    </div>

    <hp-hcp-patient-empty-state-column class="inner" *ngIf="shouldDisplayEmptyState()">
    </hp-hcp-patient-empty-state-column>

</div>
