<div>
    <div class="expired-subscription-modal-body">
        <div class="update-confirmation">
            <div class="message">
                Please note that as of  <b>{{data.subscriptionExpireDate | hcpDate: 'utcDate'}}</b> your subscription has expired.
                <br />
                <br />
                While you will be able to access the patient profiles that were added prior to the mentioned date, you will not be able to add new patients to the portal.
            </div>
        </div>
        <div class="form-buttons">
            <button type="button" class="button" (click)="closeSubscriptionExpirationModal()">
                Close
            </button>
        </div>
    </div>
</div>
