<div class="hp-hcp-input-text">
    <div class="input-error" *ngIf="hasError">
        {{errorMessage}}
    </div>
    <div class="form-input-group">
        <input #inputBox class="form-input" hasFloatingLabel="has-floating-label"
               [attr.data-dom-id]="domId"
               [attr.data-category-id]="categoryId"
               [class.form-input-error]="hasError"
               [placeholder]="placeholder"
               [type]="type"
               [value]="value"
               [disabled]="disabled"
               (keydown)="keyDown($event)"
               (input)="onChange($event.target.value)" />
        <label class="floating-label" >{{placeholder}}</label>
        <i class="fa icon fa-exclamation-circle error-icon" *ngIf="hasError"></i>
    </div>
</div>
