import { Pipe, PipeTransform } from '@angular/core';

const PHONE_LENGTH = 10;
const PHONE_DEFAULT_FORMAT = '(___) ___-____';
const PHONE_DEFAULT_PLACEHOLDER = '_';

@Pipe({ name: 'hcpPhoneFormat' })
export class HcpPhoneFormatPipe implements PipeTransform {
  public transform(
    rawPhoneNumber: string,
    phoneFormatString: string = PHONE_DEFAULT_FORMAT,
    placeholder: string = PHONE_DEFAULT_PLACEHOLDER
  ): string {

    if (!this._isValidPhoneNumber(rawPhoneNumber)) {
      return '';
    }

    const digits = rawPhoneNumber.split('');
    const regx = new RegExp(`(${placeholder})`, 'g');

    let digitCounter = 0;

    return phoneFormatString.replace(regx, () => digits[digitCounter++]);
  }

  private _isValidPhoneNumber(rawPhoneNumber) {
    return new RegExp(`^\\d{${PHONE_LENGTH}}$`).test(rawPhoneNumber);
  }

}
