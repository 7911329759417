<div class="hp-hcp-dismiss-notification" click-outside (clickOutside)="closeListOnFocusOut()">
    <div class="dismiss-list-wrapper {{notificationType}}" *ngIf="isListVisible">
        <div class="head-section">
            <div class="dismiss-list-title">ACTION TAKEN</div>
            <a data-dom-id="notification_action_menu_close" class="fa fa-chevron-up dismiss-arrow link-icon" (click)="toggleDismissList()"></a>
        </div>
        <div class="dismiss-list">
            <div *ngFor="let action of actionsList"
                 attr.data-dom-id="notification_action_taken_{{action.code | lowercase}}"
                 [bctEventCapture]="['CALL_PATIENT','EMAIL_PATIENT'].indexOf(action.code) > -1"
                 [bctEventCode]="action.code"
                 [bctPatientId]="patientId"
                 [bctEventSection]="patientSurgeryPhase"
                 class="dismiss-list-item"
                 (click)="dismissNotification(action.code)">
                {{action.name}}
            </div>
        </div>
    </div>
    <div data-dom-id="notification_action_menu_open" class="dismiss-notification-wrapper {{notificationType}}" (click)="toggleDismissList()">
        <div data-dom-id="notification_action_menu_open" class="dots-wrapper">
            <i data-dom-id="notification_action_menu_open" class="dots fa fa-stop"></i>
            <i data-dom-id="notification_action_menu_open" class="dots fa fa-stop"></i>
            <i data-dom-id="notification_action_menu_open" class="dots fa fa-stop"></i>
        </div>
        <i data-dom-id="notification_action_menu_open" class="fa fa-chevron-down arrow"></i>
    </div>
</div>
