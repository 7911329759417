<div class="hp-hcp-account-activation-declined">

    <div class="container" *ngIf="shouldShowConfirmation">
        <h3 class="title">
            Declined Activation
        </h3>
        <p>You've declined to activate your Health Partner Care Portal account.</p>
        <p>If you declined the invitation on accident, contact your Health Partner Care Portal administrator for a new invitation.</p>
    </div>

    <div class="container password-changed" *ngIf="responseError">
        <h3 class="title">
            Activation Error!
        </h3>
        <p class="error">{{responseError}}</p>
    </div>

</div>
