import { Component, Input } from '@angular/core';

@Component({
    selector: 'hp-hcp-surgery-date-changed-icon',
    templateUrl: './surgery-date-changed-icon.html',
    styleUrls: ['./surgery-date-changed-icon.scss']
})
export class SurgeryDateChangedIconComponent {

    @Input() public previousSurgeryDate: boolean;

}
