<div class="wrapper" click-outside (clickOutside)="close()">
    <div class="slc-box">
        <div class="lbl">{{ label }}</div>
        <div class="lbl-content" [ngClass]="{'hidden': fixedContent}" (click)="toggleContent()">
            <div class="slc-value">{{fieldLabel}}</div>
            <i class=" link fa" [ngClass]="{ 'open-dropdown fa-chevron-down': !open, 'close-dropdown fa-times': open}"></i>
        </div>
        <div class="slc-content" [ngClass]="{'active': open, 'fixed': fixedContent}"  #content>
            <div *ngFor="let option of options; let currentOptionIndex = index"
                class="slc-option option"
                [ngClass]="{'active': !multiple && option.selected, 'fixed': fixedContent}" >
                <hp-hcp-checkbox
                    *ngIf="multiple; else single"
                    class="slc-option-multiple"
                    [eventGroup]="eventGroup"
                    [domId]="withPrefixedIndexAsDomId ? (withPrefixedIndexAsDomId | domIdPrefixedIndex:currentOptionIndex) : (option.name | domId)"
                    [(ngModel)]="option.selected"
                    (ngModelChange)="onChkChange()"
                    [disabled]="option.disabled">
                    {{option.name}}
                </hp-hcp-checkbox>
                <ng-template #single>
                    <span class="slct-option-simple"
                        [attr.data-dom-id]="option.name | domId"
                        [attr.data-event-group]="eventGroup"
                        data-category-id="list"
                        [ngClass]="{'disabled': option.disabled}"
                        (click)="selectOption(option)">
                        {{ option.name }}
                    </span>
                </ng-template>
            </div>
            <div *ngIf="!options.length" class="slc-option option">{{ optionsNotFound }}</div>
        </div>
    </div>
</div>
