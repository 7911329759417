import { Injectable } from '@angular/core';
import { HpHcpHttp } from './hp-hcp-http.service';

@Injectable()
export class PatientIntraOpNotesService {

    constructor(
        private _hpHcpHttp: HpHcpHttp
    ) { }

    public getIntraOpNotesData(patientId, success, failure) {
        this._hpHcpHttp.get(this._hpHcpHttp.urls.patient.intraOpNotes + '/values', { urlParam: patientId }).subscribe(success, failure);
    }

    public saveIntraOpNotesData(patientId, requestBody, success, failure) {
        this._hpHcpHttp.post(this._hpHcpHttp.urls.patient.intraOpNotes, { urlParam: patientId, requestBody }).subscribe(success, failure);
    }

}