/**
 * Copyright: Copyright © 2023
 * This file contains trade secrets of Johnson & Johnson. No part may be reproduced or transmitted in any
 * form by any means or for any purpose without the express written permission of Johnson & Johnson.
 */   
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

/*
 * @author      @version    @date            @description
 * PPareek      01          Dec 13, 2023    AFLL-19609 - Fixed Sonar Issues | Removed duplicate Import.
 */  
@Component({
    selector: 'hp-hcp-subscription-expired-modal',
    templateUrl: './subscription-expired-modal.html',
    styleUrls: ['./subscription-expired-modal.scss']
})
export class ExpiredSubscriptionModalComponent {

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<ExpiredSubscriptionModalComponent>
    ) {
        dialogRef.disableClose = true;
    }

    public closeSubscriptionExpirationModal() {
        this.dialogRef.close(false);
    }

}
