import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

import { UserModel } from '../../shared/model/user.model';

import { HpHcpGAService } from '../../shared/service/hp-hcp-ga.service';
import { HpHcpLegalDocumentService } from '../../shared/service/hp-hcp-legal-document.service';
import { HpHcpUrlRedirect } from '../../shared/service/hp-hcp-url-redirect.service';
import { HpHcpUserSession } from '../../shared/service/hp-hcp-user-session.service';
import { LegalAcceptModel } from '../../shared/model/legal-accept.model';

@Component({
  selector: 'hp-hcp-privacy-and-terms',
  templateUrl: './privacy-and-terms.html',
  styleUrls: ['./privacy-and-terms.scss']
})
export class PrivacyAndTermsComponent implements OnInit, OnDestroy {
  public isSubmitted = false;
  public checked = false;
  public privacyAndTermsForm: FormGroup;
  private _legalAcceptSessionData: LegalAcceptModel;

  constructor(
    private _hpHcpGaService: HpHcpGAService,
    private _legalDocumentService: HpHcpLegalDocumentService,
    private _hpHcpUrlRedirect: HpHcpUrlRedirect,
    private _hpHcpUserSession: HpHcpUserSession,
    private _router: Router
  ) { }

  public ngOnInit() {
    this._legalAcceptSessionData = this._hpHcpUserSession.getUserLegalAcceptSession();

    if (!this._legalAcceptSessionData) {
      this._router.navigate([this._hpHcpUrlRedirect.urls.login]);
      return;
    }

    this.privacyAndTermsForm = new FormGroup({
      accepted: new FormControl(false)
    });
  }

  public goToLogin() {
    this._router.navigateByUrl(this._hpHcpUrlRedirect.urls.login);
  }

  public selectTerms(terms) {
    const selectedAction = terms.checked ? 'check' : 'uncheck';
    this._hpHcpGaService.sendClickEvent(terms, selectedAction);
  }

  public submitForm(isAccepted: boolean) {

    this.isSubmitted = true;

    const { legalRequired, ...requestBody } = this._legalAcceptSessionData;

    this._legalDocumentService
      .postPrivacyAndTerms(requestBody)
      .subscribe(this._successfulLogin.bind(this));
  }

  private _successfulLogin(userData: UserModel) {
    this._hpHcpUserSession.removeUserLegalAcceptSession();
    this._hpHcpUserSession.setUserSession(userData);
    this._hpHcpUserSession.startGaSession(userData);
    this._router.navigate([this._hpHcpUrlRedirect.urls.dashboard]);
  }

  public ngOnDestroy(): void {
    this._hpHcpUserSession.removeUserLegalAcceptSession();
  }
}
