import { Injectable } from '@angular/core';
import { concatMap, map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { HpHcpUserConfigService } from '../../shared/service/hp-hcp-user-config.service';
import { HpHcpUserSession } from '../../shared/service/hp-hcp-user-session.service';
import { HpHcpUsersService } from '../../shared/service/hp-hcp-users.service';
import { OrganizationModel } from '../../shared/model/organization.model';
import { UserModel } from '../../shared/model/user.model';

@Injectable()
export class HpHcpMultOrgService {
    private _unsubscribe$: Subject<void> = new Subject();

    public constructor(
        private _userConfigService: HpHcpUserConfigService,
        private _sessionService: HpHcpUserSession,
        private _userService: HpHcpUsersService
    ) {}

    public ngOnDestroy() {
        this._unsubscribe$.next();
        this._unsubscribe$.complete();
    }

    public switchOrganization(
        organization: OrganizationModel,
        callback: Function = null
    ) {
        const userRequest$ = this._userService.saveMultiOrgSelection({
            organizationId: organization.id
        });

        const combinedRequest$ = userRequest$.pipe(
            map(({ sites = [] }: UserModel) => {
                const userSession = this._sessionService.getUserSession();
                this._sessionService.setUserSession({
                    ...userSession,
                    organization,
                    sites
                });
                this._sessionService.setMultiOrgSelected(true);
            }),
            concatMap(() => this._userConfigService.fetchCarePlan())
        );

        combinedRequest$
            .pipe(takeUntil(this._unsubscribe$))
            .subscribe(carePlan => {
                this._sessionService.setCarePlanSession(carePlan);
                if (callback) {
                    callback();
                }
            });
    }
}
