import {
    AfterViewChecked,
    Directive,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    Output,
    Renderer2
} from '@angular/core';
import { NgModel } from '@angular/forms';

const MASK_FLAGS = ['D', 'M', 'Y'];

@Directive({
    selector: '[dateFormat]'
})
export class DateFormatDirective implements AfterViewChecked {
    @Input() public dateFormat: string;

    @Output() public onChange: EventEmitter<string> = new EventEmitter();

    private _cursor: number;
    private inputValue: string;

    private set selectionEnd(value: number) {
        this._elementRef.nativeElement.selectionEnd = value;
    }

    private get selectionStart() {
        return this._elementRef.nativeElement.selectionStart;
    }

    private get value() {
        return this._model.control.value;
    }

    private set value(val) {
        this._model.control.setValue(val);
    }

    constructor(
        private _elementRef: ElementRef,
        private _model: NgModel,
        private _renderer: Renderer2
    ) {}

    public ngAfterViewChecked() {
        if (!this.value) {
            this._renderer.setProperty(
                this._elementRef.nativeElement,
                'value',
                this.dateFormat
            );
        }
    }

    @HostListener('cut', ['$event'])
    @HostListener('paste', ['$event'])
    public preventCutAndPaste(event: KeyboardEvent) {
        event.preventDefault();
    }

    @HostListener('input', ['$event.target.value'])
    public onInput(value) {
        this.inputValue = value;
        this.value = this._getFormattedDate();
        this._setCursorPosition();
        this.onChange.emit(this.value);
    }

    @HostListener('select', ['$event'])
    public preventMultiSelect() {
        this.selectionEnd = this.selectionStart;
    }

    private _getFormattedDate(): string {
        const cursor = this.selectionStart - 1;
        return this.inputValue.length < this.dateFormat.length
            ? this._handleDeletion(cursor)
            : this._handleInsert(cursor);
    }

    private _handleDeletion(cursor: number) {
        let newInputValue = '';
        const deleted: number = this.dateFormat.length - this.inputValue.length;

        for (let i = 1; i <= deleted; i++) {
            newInputValue = this._replaceInputWithPlaceholder(
                this.inputValue,
                this.dateFormat[cursor + i],
                cursor + i
            );
            this._cursor = cursor + i;
        }

        return newInputValue;
    }

    private _handleInsert(cursor: number) {
        const char = this.inputValue[cursor];
        let newInputValue = this._replaceCharAt(this.inputValue, cursor, '');
        if (this._isValidInputPosition(cursor)) {
            let isCharValid = this._validateCharOnPosition(char, cursor);
            if (isCharValid) {
                newInputValue = this._replaceCharAt(
                    newInputValue,
                    cursor,
                    char
                );
                this._cursor = cursor + 1;
            } else {
                this._cursor = cursor;
            }
        } else {
            this._cursor = ++cursor;
            let isCharValid = this._validateCharOnPosition(char, cursor);
            if (isCharValid) {
                newInputValue = this._replaceCharAt(
                    newInputValue,
                    cursor,
                    char
                );
                this._cursor = cursor + 1;
            } else {
                const isDivider =
                    Array.from(this._getMaskSeparators().values()).indexOf(
                        char
                    ) !== -1;
                this._cursor = isDivider ? cursor : --cursor;
            }
        }
        return newInputValue;
    }

    private _isValidInputPosition(cursor: number) {
        const separators: Map<number, string> = this._getMaskSeparators();
        const separatorKeys: number[] = Array.from(separators.keys());
        const inputKeys: number[] = this._getPlaceholders(separatorKeys);
        return inputKeys.indexOf(cursor) !== -1;
    }

    private _replaceInputWithPlaceholder(
        strValue: string,
        placeholderChar: string,
        index: number
    ): string {
        if (strValue !== undefined) {
            return (
                strValue.substring(0, index) +
                placeholderChar +
                strValue.substring(index)
            );
        }
    }

    private _validateCharOnPosition(
        inputChar: string,
        position: number
    ): boolean {
        let regex: RegExp;
        let isValid: boolean;
        const digitRegEx = '[\\d]';
        switch (this.dateFormat[position]) {
            case 'D':
            case 'M':
            case 'Y':
                regex = new RegExp(digitRegEx);
                isValid = regex.test(inputChar);
                break;
            default: {
                isValid = false;
            }
        }
        return isValid;
    }

    private _replaceCharAt(
        strValue: string,
        index: number,
        char: string
    ): string {
        if (strValue !== undefined) {
            return (
                strValue.substring(0, index) +
                char +
                strValue.substring(index + 1)
            );
        }
    }

    private _setCursorPosition(): void {
        this._elementRef.nativeElement.setSelectionRange(
            this._cursor,
            this._cursor
        );
    }

    private _getMaskSeparators(): Map<number, string> {
        const separators = new Map<number, string>();
        Array.from(this.dateFormat).forEach((_, i) => {
            const char = this.dateFormat.charAt(i);
            if (MASK_FLAGS.indexOf(char) === -1) {
                separators.set(i, char);
            }
        });
        return separators;
    }

    private _getPlaceholders(separatorKeys: number[]): number[] {
        const placeholders: number[] = [];
        Array.from(this.dateFormat).forEach((_, i) => {
            if (separatorKeys.indexOf(i) === -1) {
                placeholders.push(i);
            }
        });
        return placeholders;
    }
}
