import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { HpHcpHttp } from './hp-hcp-http.service';
import { DismissActionModel } from '../model/dismiss-action.model';
import { DismissModel } from '../model/dismiss.model';

import findIndex from 'lodash/findIndex';

const LAST_ACTION_NAME = 'DISMISS_WITH_COMMENT';

export const PRIORITY_BASE = 1000;
export const PRIORITY_SELECTORS = {
    1000: 'low-urgency',
    2000: 'medium-urgency',
    3000: 'high-urgency'
};
export const ICONS_NAMES = {
    appEngagement: 'not-connected-icon',
    essentialEducation: 'essential_edu_icon',
    notConnectedPatient: 'not-connected-icon',
    pain: 'pain-icon'
};

@Injectable()
export class HpHcpNotificationsService {

    private _actionList = new BehaviorSubject(null);
    private _hpHcpHttp: HpHcpHttp;
    private _isHttpRequestCalled = false;

    constructor(hpHcpHttp: HpHcpHttp) {
        this._hpHcpHttp = hpHcpHttp;
    }

    public getNotificationActions(callback) {
        if (!this._isHttpRequestCalled) {
            this._fetchNotificationActions();
        }
        this._actionList.subscribe(response => callback(response));
    }

    public updateNotification(userId: number, dismissAction: DismissActionModel, callback: Function) {
        const requestBody = {
            notificationId: dismissAction.notificationId,
            action: dismissAction.action,
            comment: dismissAction.comment || undefined
        };

        this._hpHcpHttp
            .put(this._hpHcpHttp.urls.notification.update,  {
                urlParam: userId,
                requestBody
            })
            .subscribe(isUpdated => callback(isUpdated));
    }

    public disconnectNotification(userId: number, dismissAction: DismissActionModel, callback: Function) {
        const requestBody = {};

        this._hpHcpHttp
            .put(this._hpHcpHttp.urls.notification.disconnect, {
                urlParam: userId,
                requestBody
            })
            .subscribe(isDisconnected => callback(isDisconnected));
    }

    private _fetchNotificationActions() {
        this._isHttpRequestCalled = true;
        this._hpHcpHttp.get(this._hpHcpHttp.urls.notification.actions)
            .subscribe((response: Array<DismissModel>) => {
                this._actionList.next(this._moveDismissActionToEnd(response, ['code', LAST_ACTION_NAME]));
            });
    }

    private _moveDismissActionToEnd(list: Array<any>, item: Array<string>) {
        const itemsToSlice = 1;
        const index = findIndex(list, item);
        const copyList = list.slice();
        copyList.push(copyList.splice(index, itemsToSlice)[0]);
        return copyList;
    }
}
