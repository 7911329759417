<div class="hp-hcp-toggle-menu-wrapper">
  <div ngbDropdown class="d-inline-block">
    <button class="btn btn-link dropdown-title" ngbDropdownToggle>
      {{ title }}
      <span class="caret"></span>
    </button>
    <ul ngbDropdownMenu toggleMenu>
      <li (click)="handleSelect(selectIndex)"
          [attr.data-dom-id]="withPrefixedIndexAsDomId ? (withPrefixedIndexAsDomId | domIdPrefixedIndex:selectIndex) : (option.name | domId)"
          [attr.data-category-id]="categoryId"
          [attr.data-event-group]="eventGroup"
          class="dropdown-item"
          *ngFor="let option of sortedOptions; let selectIndex = index"
          [toggleMenuActive]="selected && selected.id && (selected.id === option.id)"
          >
        {{option.name}}
      </li>
    </ul>
  </div>
</div>
