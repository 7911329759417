<input id="{{name}}"
            name="{{name}}"
            type="checkbox"
            class="checkbox custom-input custom-input-gray form-input"
            (change)="onChange($event)"
            [attr.data-dom-id]="domId"
            [attr.data-event-group]="eventGroup"
            data-category-id="check_box"
            [disabled]="disabled"
            [checked]="_checked">
<label for="{{name}}" class="checkbox-label small-checkbox custom-checkbox outter {{ class }}"
    [attr.data-event-group]="eventGroup"
    attr.data-dom-id="label-{{ domId }}"
    [ngClass]="{'disabled': disabled}">
    <ng-content></ng-content>
</label>
<span></span>
