import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HpHcpHttp } from './hp-hcp-http.service';

@Injectable()
export class TkrKoosHoosData {

    constructor (
        private _hpHcpHttp: HpHcpHttp
    ) {}

    public getPatientKoosData(patientId, success, failure) {
        this._hpHcpHttp.get(this._hpHcpHttp.urls.chart.koosData,{ urlParam: patientId }).subscribe(success, failure);
    }

    public getPatientHoosData(patientId, success, failure) {
        if (environment.country == 'UK') {
            this._hpHcpHttp.get(this._hpHcpHttp.urls.chart.oxfordHipData, { urlParam: patientId }).subscribe(success, failure);
        } else {
            this._hpHcpHttp.get(this._hpHcpHttp.urls.chart.hoosData, { urlParam: patientId }).subscribe(success, failure);
        }
    }

    public getPatientOxfordKneeData(patientId, success, failure) {
        this._hpHcpHttp.get(this._hpHcpHttp.urls.chart.oxfordKneeData, { urlParam: patientId }).subscribe(success, failure);
    }
}
