<header class="tkr-header">
    <mat-toolbar color="primary">
        <mat-toolbar-row>

            <img src="../../../../assets/img/velys-logo-v2.png" class="header-logo-img" alt="VELYS Insights"
                (click)="goToDashboardView()">

            <span class="fill-space"></span>

            <button mat-button [ngClass]="{ 'dashboard-text-selected': isDashboard }" class="navigation-text"
                (click)="goToDashboardView()">{{'header.dashboard' | translate }}</button>

            <button mat-button *ngIf="isDocsperaSite" class="navigation-text" (click)="navigateToDocSpera('calendar')"
                translate>{{'header.calendar' | translate }}</button>

            <button mat-button *ngIf="isSmartSchedulerSite && isSmartSchedulerUser" class="navigation-text"
                (click)="navigateToDocSpera('smart-scheduling')" translate>{{'header.smart_scheduler' | translate
                }}</button>

            <button mat-button [ngClass]="{ 'reports-text-selected': isReport }" *ngIf="isAdmin && isShowReport"
                class="navigation-text" (click)="navigateToViewReports()" translate>{{'header.reports' | translate
                }}</button>

            <div *ngIf="isDocsperaSite" class="more-options-container">
                <button mat-button id="ds-more-options" (click)="toggleDSMenu($event)">
                    <span id="ds-more-options" translate>header.more</span>
                    <mat-icon id="ds-more-options" iconPositionEnd>expand_more</mat-icon>
                </button>
                <mat-card *ngIf="showDropdownMenuDS" class="header-dropdown-options-ds">
                    <button class="header-menu-item-ds" (click)="navigateToDocSpera('messages')"
                        translate>header.messages</button>
                    <mat-divider class="divider-style"></mat-divider>
                    <span>
                        <button class="header-menu-item-ds" (click)="navigateToDocSpera('contacts')"
                            translate>header.contacts</button>
                    </span>
                </mat-card>
            </div>

            <button [ngClass]="{ 'mat-disabled-button': isSubscriptionExpired }" mat-button class="add-patient-text"
                (click)="goToAddPatientView()">
                <img src="../../../../assets/img/add-patient.png" class="add-patient-button" alt="add patient">
                {{ 'header.patient' | translate }}
            </button>

            <div class="seperator-style"></div>
            <div class="user-options-container">
                <button mat-button id="user-options" (click)="toggleActiveMenu($event)">
                    <span id="user-options">{{username}}</span>
                    <mat-icon id="user-options" iconPositionEnd>expand_more</mat-icon>
                </button>
                <mat-card *ngIf="showDropdownMenu" class="header-dropdown-options">
                    <div *ngIf="vrasPatientCount && isDashboard" class="row background">
                        <div class="vras-count">{{vrasPatientCount}}</div>
                        <div class="vras-count-message">
                            <div class="row">
                                <div class="vras-header" translate>emrPatientInformation.vrasTooltipTitle</div>
                            </div>
                            <div class="row">
                                <div class="vras-header-menu-item" translate>header.vrasProcedurePerformed</div>
                            </div>
                        </div>
                    </div>
                    <mat-divider *ngIf="vrasPatientCount" class="divider-style"></mat-divider>
                    <div *ngIf="canLoadPage" (click)="goToMyProfile()" data-dom-id="my_settings">
                        <button class="header-menu-item" translate>header.my_login_info</button>
                    </div>
                    <span *ngIf="isDocsperaSite">
                        <mat-divider class="divider-style"></mat-divider>
                        <button class="header-menu-item" (click)="navigateToDocSpera('profile')"
                            translate>header.my_profile</button><br>
                        <span *ngIf="isAdmin">
                            <mat-divider class="divider-style"></mat-divider>
                            <button class="header-menu-item" (click)="navigateToDocSpera('admin-settings')"
                                translate>header.admin_settings</button><br>
                        </span>
                    </span>
                    <mat-divider class="divider-style"></mat-divider>
                    <button class="header-menu-item" (click)="logout()" translate>header.log_out</button>
                </mat-card>
            </div>
            <div class="seperator-style hide-lang"></div>
            <div class="user-options-container hide-lang">
                <button mat-button id="language-options" (click)="toggleLanguageMenu($event)">
                    <span id="user-options" name="selectedLang">{{selectedLang}}</span>
                    <mat-icon id="user-options" iconPositionEnd>expand_more</mat-icon>
                </button>
                <mat-card *ngIf="showLanguageMenu" class="header-dropdown-options">
                    <div *ngIf="canLoadPage" data-dom-id="my_settings">
                        <button class="header-menu-item" (click)="toggleLanguage('en-US')">en-US</button>
                    </div>
                    <div *ngIf="canLoadPage" data-dom-id="my_settings">
                        <button class="header-menu-item" (click)="toggleLanguage('en-GB')">en-GB</button>
                    </div>
                    <div *ngIf="canLoadPage" data-dom-id="my_settings">
                        <button class="header-menu-item" (click)="toggleLanguage('de')">de</button>
                    </div>
                </mat-card>
            </div>
        </mat-toolbar-row>
    </mat-toolbar>

</header>
