<button
  class="button button-{{class}}"
  [attr.data-category-id]="categoryId"
  [attr.data-dom-id]="domId"
  [attr.data-event-group]="eventGroup"
  [ngClass]="{'disabled': disabled}"
  [type]="type"
  [disabled]="disabled">
  <ng-content></ng-content>
</button>
