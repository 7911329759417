import { Directive, ElementRef, Output, EventEmitter } from '@angular/core';

@Directive({
    selector: '[click-outside]',
    host: {
        '(document:click)': 'onDocumentClick($event)'
    }
})
export class ClickOutsideDirective {

    @Output() public clickOutside: EventEmitter<any> = new EventEmitter();

    private _elementRef: ElementRef;

    constructor(elementRef: ElementRef) {
        this._elementRef = elementRef;
    }

    public onDocumentClick(event) {
        const clickedInside = this._elementRef.nativeElement.contains(event.target);
        if (!clickedInside) {
            this.clickOutside.emit(null);
        }
    }
}
