import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';

import {  ToastrService } from 'ngx-toastr';

import { HpHcpAuth, RECENTLY_USED_STATUS_CODE, RECENTLY_CHANGED_STATUS_CODE,
    PASSWORD_STRENGTH_CRITERIA_ERROR, PASSWORDS_MATCH_ERROR } from '../../shared/service/hp-hcp-auth.service';
import { HpHcpValidators } from '../../shared/service/hp-hcp-validators.service';

const ROUTES = {
    forgotPassword: 'forgotpassword',
    login: 'login'
};

@Component({
    selector: 'hp-hcp-reset-password',
    templateUrl: './reset-password.html',
    styleUrls: ['./reset-password.scss']
})
export class ResetPasswordComponent implements OnInit {

    public errors = {
        newPasswordError: undefined,
        confirmPasswordError: undefined
    };
    public passwordResetConfirm = false;
    public resetPasswordForm: FormGroup;
    public submitted = false;
    public submittedAndAllowed = false;

    private _authService: HpHcpAuth;
    private _hpHcpValidators: HpHcpValidators;
    private _passwordResetKey: string;
    private _routeParams: ActivatedRoute;
    private _router: Router;
    private _toastr: ToastrService;
    private _userType: string;

    constructor(
        hpHcpAuth: HpHcpAuth,
        router: Router,
        routeParams: ActivatedRoute,
        formBuilder: FormBuilder,
        toastr: ToastrService,
        hpHcpValidators: HpHcpValidators
    ) {
        this._authService = hpHcpAuth;
        this._hpHcpValidators = hpHcpValidators;
        this._router = router;
        this._routeParams = routeParams;
        this._toastr = toastr;

        this.resetPasswordForm = formBuilder.group({
            'password': [''],
            'confirmedPassword': ['']
        });
    }

    public ngOnInit() {
        this._routeParams.queryParams.subscribe(({ email, userType, passwordResetKey }) => {
            this._userType = userType;
            this._passwordResetKey = passwordResetKey;
            this.verifyPasswordResetKey();
        });
    }

    public clearInputErrors () {
        this.errors.newPasswordError = undefined;
        this.errors.confirmPasswordError = undefined;
    }

    public goToLogin() {
        this._router.navigate([ROUTES.login]);
    }

    public verifyPasswordResetKey() {
        const failedRequest = () => {
            const expiredLink = true;
            this._router.navigate([ROUTES.forgotPassword], { queryParams: { expiredLink } });
        };

        this._authService.verifyPasswordResetKey(
            {
                passwordResetKey: this._passwordResetKey,
                userType: this._userType
            },
            failedRequest
        );
    }

    public resetPassword() {
        this.errors = {
            newPasswordError: undefined,
            confirmPasswordError: undefined
        };
        this.submitted = true;
        const password = this.resetPasswordForm.value.password;
        const confirmedPassword = this.resetPasswordForm.value.confirmedPassword;
        const isPasswordValid = this._isPasswordValid(password);
        const isConfirmedPasswordValid = this._isConfirmedPasswordValid(password, confirmedPassword);

        if (!isPasswordValid || !isConfirmedPasswordValid) {
            return;
        }

        this.submittedAndAllowed = true;
        this._authService.newPassword({
            newPassword: password,
            passwordResetKey: this._passwordResetKey,
            userType: this._userType
        }).pipe(first()).subscribe(() => this._successfulPasswordReset(),
        (error) => this._failedPasswordReset(error));
    }

    private _isPasswordValid(password: string): boolean {
        if (!this._hpHcpValidators.passwordStrength(password)) {
           this.errors.newPasswordError = PASSWORD_STRENGTH_CRITERIA_ERROR;
           return false;
        }

        return true;
    }

    private _failedPasswordReset({code, message}) {
        this.clearInputErrors();
        this.submittedAndAllowed = false;
        switch (code) {
            case RECENTLY_USED_STATUS_CODE:
            case RECENTLY_CHANGED_STATUS_CODE:
                this.errors.newPasswordError = message;
                this.resetPasswordForm.patchValue({ confirmedPassword: '' });
                return;
        }
        this._toastr.error(message, 'Error');
    }

    private _isConfirmedPasswordValid(password: string, confirmedPassword: string): boolean {
        const isConfirmedPasswordValid = password && (password === confirmedPassword);
        if (!isConfirmedPasswordValid) {
            this.errors.confirmPasswordError = PASSWORDS_MATCH_ERROR;
        }
        return isConfirmedPasswordValid;
    }

    private _successfulPasswordReset() {
        this.submittedAndAllowed = false;
        this.passwordResetConfirm = true;
    }

}
