<div class="hp-hcp-forgot-password">
    <div class="forgot-password-wizard" *ngIf="!emailSent">
        <div class="logo-row text-center">
            <img class="logo" src="../../../assets/img/velys-logo-signin.png" alt="velys Logo signin" />
        </div>
        <div class="title" *ngIf="!isExpiredPassword">Forgot your password?</div>
        <div class="title" *ngIf="isExpiredPassword">Your Password Has Expired</div>
        <div class="summary">
            <div class="expired-summary" *ngIf="isExpiredPassword">For your security, your password expires every 90 days.</div>
            Please enter the email address that is registered with the VELYS™ Insights portal. We will send you an email that will allow you to reset your password.
        </div>
        <form [formGroup]="forgotPasswordForm" class="forgot-password-form-group">
            <div class="input-field-row">
                <label for="email">Email</label>
                <input class="form-email-input"
                    type="email" formControlName="email" placeholder="" id="email" autocomplete="off"/>
                <span class="error-message" *ngIf="isInValidEmail">Please fill in a valid email address</span>
            </div>

            <div class="text-center">
                <button mat-raised-button color="primary" class="button" (click)="onSubmit()" [disabled]="isSubmitted">
                    Submit
                </button>
            </div>
            <a class="cancel-link" routerLink="/login" routerLinkActive="active">Cancel</a>
        </form>
    </div>
    <div class="confirmation-page" *ngIf="emailSent">
        <div class="logo-row text-center">
            <img class="logo" src="../../../assets/img/velys-logo-signin.png" alt="velys Logo signin" />
        </div>
        <div class="title">
            All Set
        </div>
        <div class="summary ">
            You should receive an email shortly with a link to reset your password.
        </div>
        <div class="text-center">
            <button mat-raised-button color="primary" class="button" (click)="goToLogin()">
                Return To Login
            </button>
        </div>
    </div>

    <hp-hcp-expired-link-modal *ngIf="showExpiredLinkModal" (closeModal)="closeLinkExpirationModal()">
    </hp-hcp-expired-link-modal>

</div>


