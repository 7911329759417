<div class="hp-hcp-dropdown" [ngClass]="{'disabled': isDisabled}" click-outside (clickOutside)="closeDropdownOnFocusOut()">
    <div class="dropdown-wrapper">
        <div class="disable-dropdown" *ngIf="isDisabled"></div>
        <div class="head-content" [ngClass]="{'title-opened': !isHidden}" (click)="toggleDropDown()">
            <span>{{title}}</span>
            <i class="fa" [ngClass]="{
                'open-dropdown fa-chevron-down': isHidden,
                'close-dropdown fa-times': !isHidden
            }"></i>
        </div>
        <div class="content" *ngIf="!isHidden">
            <ng-content></ng-content>
        </div>
    </div>
</div>
