<tkr-progress-spinner>
</tkr-progress-spinner>
<section class="hp-hcp-auth-app" [ngClass]="{'velys-signin-bg':!isTermsAndPrivacyPage()}">
    <div class="auth-container">
        <div class="auth-forms" [ngClass]="{'auth-forms-terms-privacy': isTermsAndPrivacyPage()}">
            <router-outlet></router-outlet>
        </div>
    </div>
    <tkr-footer></tkr-footer>
</section>
