export const enum ProductTypes {
    COORDINATION_FRONTLINE_EMR = 'coordination-frontline-emr',
    COORDINATION_EMR = 'coordination-emr',
    COORDINATION_NOEMR = 'coordination-no-emr',
    FRONTLINE_EMR = 'frontline-emr',
    COORDINATION_EG_FRONTLINE_EMR = 'coordination-eg-frontline-emr',
    COORDINATION_EG_NOEMR = 'coordination-eg-no-emr',
    COORDINATION_EMR_DOCFULL = "coordination-emr-docfull",
    COORDINATION_EMR_DOC_INTAKE= "coordination-emr-doc-intake"
}

export const EmrProducts = [ProductTypes.COORDINATION_FRONTLINE_EMR, ProductTypes.COORDINATION_EMR,
    ProductTypes.FRONTLINE_EMR, ProductTypes.COORDINATION_EG_FRONTLINE_EMR, ProductTypes.COORDINATION_EMR_DOCFULL, 
    ProductTypes.COORDINATION_EMR_DOC_INTAKE];

export const FrontlineProducts = [ProductTypes.COORDINATION_FRONTLINE_EMR, ProductTypes.FRONTLINE_EMR, 
    ProductTypes.COORDINATION_EG_FRONTLINE_EMR];

export const EGProducts = [ProductTypes.COORDINATION_EG_FRONTLINE_EMR, ProductTypes.COORDINATION_EG_NOEMR];

export const EMR = 'emr';

export const DocsperaBasicfeature = 'docspera-basic-features';

export const DocsperaSmartScheduler= 'docspera-smart-scheduler';