import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';

import { HpHcpAuth } from '../../shared/service/hp-hcp-auth.service';
import { HpHcpValidators } from '../../shared/service/hp-hcp-validators.service';

import { MatDialog } from '@angular/material/dialog';
import { ExpiredLinkModalComponent } from './expired-link-modal/expired-link-modal.component';

@Component({
    selector: 'hp-hcp-forgot-password',
    templateUrl: './forgot-password.html',
    styleUrls: ['./forgot-password.scss']
})
export class ForgotPasswordComponent implements OnInit {

    public emailSent = false;
    public forgotPasswordForm: FormGroup;
    public isExpiredPassword = false;
    public isSubmitted = false;
    public showExpiredLinkModal: boolean;

    private _authService: HpHcpAuth;
    private _routeParams: ActivatedRoute;
    private _toastr: ToastrService;
    private _dialog: MatDialog;
    private _router: Router;

    constructor(
        dialog: MatDialog,
        hpHcpAuth: HpHcpAuth,
        hpHcpValidators: HpHcpValidators,
        formBuilder: FormBuilder,
        router: Router,
        toastr: ToastrService,
        routeParams: ActivatedRoute
    ) {
        this._authService = hpHcpAuth;
        this._routeParams = routeParams;
        this._router = router;
        this._toastr = toastr;
        this._dialog = dialog;

        this.forgotPasswordForm = formBuilder.group({
            'email': ['', [hpHcpValidators.email, Validators.required]]
         });
    }

    public ngOnInit() {
        const handleQueryParams = ({ expiredLink, expiredPassword }) => {
            if (expiredLink) {
                this._dialog.open(ExpiredLinkModalComponent);
            } else if (expiredPassword) {
                this.isExpiredPassword = true;
            }
        };

        this._routeParams.queryParams.subscribe(handleQueryParams);
    }

    public closeLinkExpirationModal() {
        this.showExpiredLinkModal = false;
    }

    public onSubmit() {
        if (!this.forgotPasswordForm.valid || this.isInValidEmail) {
            return;
        }
        this.isSubmitted = true;
        const email = this.forgotPasswordForm.value.email;
        const emailConfirmationCallback = (res) => {
            if (res.message === 'The email address you entered does not exist') {
                this._toastr.error(res.message, 'Error');
            } else if (res.message === 'You have exceeded the maximum password reset attempts allowed. Please wait a few minutes and try again.') {
                this._toastr.error(res.message, 'Error');
            } else {
                this.emailSent = true;
            }
        this.isSubmitted = false;
        };
        const requestFailedCallback = error => {
            this._toastr.error(error.message, 'Error');
            this.isSubmitted = false;
        };
        this._authService.forgotPassword({ email }, emailConfirmationCallback, requestFailedCallback);
    }

    public goToLogin() {
        this._router.navigate(['login']);
    }

    get isInValidEmail() {
        const { errors }: any = this.forgotPasswordForm.get('email');
        return (errors != undefined && errors.badFormat);
    }
}
