import { Component } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'hp-hcp-expired-link-modal',
    templateUrl: './expired-link-modal.html',
    styleUrls: ['./expired-link-modal.scss']
})
export class ExpiredLinkModalComponent {

    constructor(
        private dialogRef: MatDialogRef<ExpiredLinkModalComponent>
    ){
        dialogRef.disableClose = true;
    }

    public closeLinkExpirationModal() {
        this.dialogRef.close(false);
    }

}
