/**
 * Copyright: Copyright © 2023
 * This file contains trade secrets of Johnson & Johnson. No part may be reproduced or transmitted in any
 * form by any means or for any purpose without the express written permission of Johnson & Johnson.
 */
import { Injectable } from '@angular/core';
import { HpHcpSessionSync } from './hp-hcp-session-sync.service';
/*
 * @author      @version    @date           @description
 * EElangav     01          Apr 11, 2023    AFLL-15217 - Added Total VRAS Patients Count
 * HSenevir     02          Jul 17, 2023    AFLL-18875 - Removed sessionInterrupted tracking with same browser multiple tab tracking
 */
export const STORAGE_NAMES = {
    connectKey: 'connectKey',
    expiringPasswordAlert: 'expiringPasswordAlert',
    isRegistrationInProgress: 'isRegistrationInProgress',
    multiOrgSelected: 'multiOrgSelected',
    passwordExpirationDays: 'passwordExpirationDays',
    patient: 'patient',
    sessionExpiration: 'sessionExpiration',
    userCarePlanConfig: 'userCarePlanConfig',
    userEmail: 'userEmail',
    userLegalAcceptSession: 'userLegalAcceptSession',
    userSession: 'userSession',
    vrasMaxAttemptsRetry: 'retryAfter',
    countOfVrasPatients: 'countOfVrasPatients'
};

@Injectable()
export class HpHcpLocalStorage {

    private _localStorage = sessionStorage;
    // localStorage
    private localStorage = localStorage;

    constructor(private _sessionSync: HpHcpSessionSync) {}

    public setItem(field: string, value: string) {
        this._localStorage.setItem(field, value);
        this._sessionSync.setItem(field, value);
    }

    public setLocalStorageItem(field: string, value: string) {
        this.localStorage.setItem(field, value);
        this._sessionSync.setItem(field, value);
    }

    public getItem(field: string): string {
        return this._localStorage.getItem(field);
    }

    public getLocalStorageItem(field: string): string {
        return this.localStorage.getItem(field);
    }

    public removeItem(field: string) {
        this._sessionSync.removeItem(field);
        this._localStorage.removeItem(field);
    }

    public removeLocalStorageItem(field: string) {
        this._sessionSync.removeItem(field);
        this.localStorage.removeItem(field);
    }

}
