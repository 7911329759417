import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, forkJoin } from 'rxjs';

import { FilterSavedModel } from '../model/filter-saved.model';
import { HpHcpAdvanceSearchFilterService } from './hp-hcp-advance-search-filter.service';
import { HpHcpHttp } from './hp-hcp-http.service';

import isEmpty from 'lodash/isEmpty';
import xor from 'lodash/xor';

@Injectable()
export class HpHcpAdvanceSavedFilterService {

    private _allowUpdates = true;
    private _dataAnnouncedSource = new ReplaySubject<any>(1);
    private _savedFilterData = { saveAvailable: false, filterData: [] };

    constructor(
        private _hpHcpAdvanceSearchFilterService: HpHcpAdvanceSearchFilterService,
        private _hpHcpHttp: HpHcpHttp
    ) {}

    public dataAnnounced(): Observable<any> {
        return this._dataAnnouncedSource.asObservable();
    }

    public announceData() {
        this._dataAnnouncedSource.next(this._savedFilterData);
    }

    public disposeData() {
        this._dataAnnouncedSource.complete();
        this._dataAnnouncedSource = new ReplaySubject<any>(1);
    }

    public callSavedFilters(queryParams: Array<Object> = []) {
        queryParams.push(this._hpHcpAdvanceSearchFilterService.cacheBustingQueryObject());
        this._hpHcpHttp.get(this._hpHcpHttp.urls.patient.filtersSaved, { queryParams })
            .subscribe(this.setInitialValues.bind(this));
    }

    public saveNewFilter(filterName: string) {
        const requestBody: FilterSavedModel = {
            name: filterName,
            filters: this._hpHcpAdvanceSearchFilterService.getSelectedSubSelections()
        };
        this._hpHcpHttp.post(this._hpHcpHttp.urls.patient.filtersSaved, { requestBody })
            .subscribe((response) => {
                if (response) {
                    this.callSavedFilters();
                }
            });
    }

    public deleteSelectedFilters() {
        const filterIds = this.getSelectedFilterIds();
        let deleteHttpCalls = [];

        filterIds.forEach(id => {
            deleteHttpCalls.push(this._hpHcpHttp.delete(`${this._hpHcpHttp.urls.patient.filtersSaved}/${id}`));
        });

        forkJoin(deleteHttpCalls)
            .subscribe((response) => {
                if (response) {
                    this.callSavedFilters();
                }
            });

    }

    public updateSaveAvailableStatus() {
        this._savedFilterData.saveAvailable = this._hpHcpAdvanceSearchFilterService.hasSelectedSubSelections();
    }

    public updateSubFilterSelectionState() {
        if (this._allowUpdates) {
            this.updateSelectedSavedFilter();
            this.updateSaveAvailableStatus();
            this.announceData();
        }
    }

    public updateSelectedSavedFilter() {
        const allSubSelectedValues = this._hpHcpAdvanceSearchFilterService.getSelectedSubSelections()
            .map(filterValue =>  filterValue.values).reduce((acc, values) => acc.concat(values, []), []);
        this.unSelectAllSavedFilters();
        const savedFilterMatch = this._savedFilterData.filterData.find((filter) => {
            const allSelectedValues = filter.filters.map(filterValue => filterValue.values)
                                            .reduce( (acc, values) => acc.concat(values, []), []);
            return isEmpty(xor(allSelectedValues, allSubSelectedValues));
        });

        if (savedFilterMatch) {
            savedFilterMatch.selected = true;
        }
    }

    public unSelectAllSavedFilters() {
        this._savedFilterData.filterData.forEach(data => data.selected = false);
    }

    public getSelectedFilterIds(): Array<string> {
        return this._savedFilterData.filterData.filter(({ selected }) => selected).reduce((acc, { id }) => acc.concat(id, []), []);
    }

    public hasSelectedFilters(): boolean {
        return !!this.getSelectedFilterIds().length;
    }

    public enableUpdates() {
        this._allowUpdates = true;
    }

    public disableUpdates() {
        this._allowUpdates = false;
    }

    private setInitialValues(response) {
        this._savedFilterData.filterData = response;
        this.updateSubFilterSelectionState();
    }

}
