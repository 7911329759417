/**
 * Copyright: Copyright © 2023
 * This file contains trade secrets of Johnson & Johnson. No part may be reproduced or transmitted in any
 * form by any means or for any purpose without the express written permission of Johnson & Johnson.
 */
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject} from 'rxjs';
import { HpHcpHttp } from './hp-hcp-http.service';
import { FilterModel } from '../model/filter.model';
import { FilterItemModel } from '../model/filter-item.model';
import { FilterSavedValueModel } from '../model/filter-saved-value.model';
import { FiltersSortsModel } from '../model/filters-sorts.model';
import { FilterValueModel } from '../model/filter-value.model';
import { KeyValuePair } from '../model/key-value-pair.model';
import { ModifiedFilterReponseModel } from '../model/modified-filter-reponse.model';
import { ModifiedFilterSortReponseModel } from '../model/modified-filter-sort-reponse.model';

import cloneDeep from 'lodash/cloneDeep';
/*
 * @author      @version    @date           @description
 * EElangav     1           Mar 8, 2023     AFLL-16089 Fixed Show VRAS Patient checkbox issues.
 * PPareek      2           Dec 13, 2023    AFLL-19609 - Fixed Sonar Issues | Removed NOT - NOT condition.
 *
 */
@Injectable()
export class HpHcpAdvanceSearchFilterService {

    private _dataAnnouncedSource = new ReplaySubject<any>(1);
    private _MASTER_VALUE: String = 'pre-surgery';
    private _modifiedData: ModifiedFilterSortReponseModel = { 'sorts': [], 'filters': [], 'subFilters': [] };
    public showVrasPatient = new Subject();  

    constructor(private _hpHcpHttp: HpHcpHttp) {
    }

    public dataAnnounced(): Observable<any> {
        return this._dataAnnouncedSource.asObservable();
    }

    public announceData() {
        this._dataAnnouncedSource.next(this._modifiedData);
    }

    public disposeData() {
        this._dataAnnouncedSource.complete();
        this._dataAnnouncedSource = new ReplaySubject<any>(1);
    }

    public callFiltersSorts(queryParams: Array<Object> = []): any {
        queryParams.push(this.cacheBustingQueryObject());
        this._hpHcpHttp.get(this._hpHcpHttp.urls.patient.filtersSorts, { queryParams })
            .subscribe(this.setInitialValues.bind(this));
    }

    public clearAllSubSelections() {
        this._modifiedData.subFilters.forEach(modifiedFilter => modifiedFilter.data.forEach(
            filterValue => filterValue.selected = filterValue.hideFromDropdown && filterValue.value !== this._MASTER_VALUE
                ? filterValue.selected
                : filterValue.value === this._MASTER_VALUE
        ));
    }

    public applyAllSubSelections() {
        this._modifiedData.filters.forEach((modifiedFilter, i) => {
            modifiedFilter.data.forEach((filterValue, x) => {
                filterValue.selected = this._modifiedData.subFilters[i].data[x].selected;
            });
        });
        this.syncMasterFilters();
        this.announceData();
    }

    public syncAllSubSelections() {
        this._modifiedData.subFilters.forEach((modifiedFilter, i) => {
            modifiedFilter.data.forEach((filterValue, x) => {
                filterValue.selected = this._modifiedData.filters[i].data[x].selected;
            });
        });
        this.syncMasterFilters();
        return this;
    }

    public syncAllSubSelectionsToAllPatients() {
        this._modifiedData.subFilters.forEach((modifiedFilter, i) => {
            modifiedFilter.data.forEach((filterValue, x) => {
                filterValue.selected = this._modifiedData.filters[i].data[x].selected;
            });
        });
        this.selectAllPatientsFilter();
        return this;
    }

    public syncMasterFilters() {
        if (this.hasMainSelectedFilters()) {
            this.unSelectMasterFilter();
        } else {
            this.selectMasterFilter();
        }
    }

    public unSelectMasterFilter() {
        this._modifiedData.filters.forEach(modifiedFilter => modifiedFilter.data.forEach(
            filterValue => filterValue.selected = filterValue.value === this._MASTER_VALUE ? false : filterValue.selected));
    }

    public selectMasterFilter() {
        this._modifiedData.filters.forEach(modifiedFilter => modifiedFilter.data.forEach(
            filterValue => filterValue.selected = filterValue.value === this._MASTER_VALUE ? true : filterValue.selected));
    }

    public selectAllPatientsFilter() {
        this._modifiedData.filters.forEach(modifiedFilter => modifiedFilter.data.forEach(
            filterValue => filterValue.selected = filterValue.value === 'all' ? true : filterValue.selected));
    }

    public hasSelectedFilters(): boolean {
        return !!this._modifiedData.filters.filter(modifiedFilter =>
            modifiedFilter.data.filter(filterValue => filterValue.selected === true).length).length;
    }

    public hasMainSelectedFilters(): boolean {
        return !!this._modifiedData.filters.filter(modifiedFilter =>
            modifiedFilter.data.filter(filterValue =>
                filterValue.selected === true && filterValue.isMain && filterValue.value !== this._MASTER_VALUE).length).length;
    }

    public hasMultiMainSelectedFilters(): boolean {
        let counter = 0;
        this._modifiedData.filters.forEach((modifiedFilter) => {
            counter += modifiedFilter.data.filter(filterValue =>
                filterValue.selected === true && filterValue.isMain && filterValue.value !== this._MASTER_VALUE).length;
        });
        return counter > 1;
    }

    public resetMainFilters() {
        this._modifiedData.filters.forEach(modifiedFilter => modifiedFilter.data.forEach(filterValue =>
            filterValue.selected = filterValue.isMain ? false : filterValue.selected));
    }

    public sortsQuery(): Array<KeyValuePair> {
        return this._modifiedData.sorts.filter(sort => sort.selected).map(sort => {
            return {
                name: `sort`,
                value: sort.value
            };
        });
    }

    public filtersQuery(): Array<KeyValuePair> {
        return this._modifiedData.filters.map(modifiedFilter =>
            modifiedFilter.data.filter(filterValue => filterValue.selected).map(filterValue => {
                return {
                    name: `${modifiedFilter.name}[]`,
                    value: filterValue.value
                };
            })
        ).filter(KeyValuePair => KeyValuePair.length).reduce((acc, KeyValuePair) => acc.concat(KeyValuePair, []));
    }

    public getSelectedSubSelections(): Array<FilterSavedValueModel> {
        return this._modifiedData.subFilters.map(({ name, data }) => {
            return {
                name,
                values: data.filter(({ selected, value, hideFromDropdown }) =>
                    selected && value !== this._MASTER_VALUE && !!!hideFromDropdown).map(({ value }) => value)
            };
        }).filter(({ values }) => values.length);
    }

    public hasSelectedSubSelections(): boolean {
        return this._modifiedData.subFilters.findIndex(({ data }) => {
            return data.findIndex(({ hideFromDropdown, selected, value }) => {
                return selected && (value !== this._MASTER_VALUE || !!hideFromDropdown);
            }) > -1;
        }) > -1;
    }

    public applySavedFilters(savedFilters: Array<FilterSavedValueModel>) {
        this.clearAllSubSelections();
        savedFilters.forEach(savedfilter => {
            this._modifiedData.subFilters.forEach(modifiedFilter => {
                if (savedfilter.name === modifiedFilter.name) {
                    modifiedFilter.data.forEach(filterValue => {
                        filterValue.selected = (savedfilter.values.indexOf(filterValue.value) > -1);
                    });
                }
            });
        });
        this.applyAllSubSelections();
    }

    public cacheBustingQueryObject(): Object {
        return {
            name: 'version',
            value: Math.floor(Date.now() / 1000)
        };
    }

    public generateFilterByDomId(subFilter: FilterValueModel): string {
        let selectedFilterBy = subFilter.label.toLowerCase();
        switch (subFilter.type) {
            case 'surgeon':
                selectedFilterBy = 'doctor_' + subFilter.value;
                break;
            case 'surgeryType':
                selectedFilterBy = selectedFilterBy.match(/\b(\w)/g).join('');
                break;
        }
        selectedFilterBy = selectedFilterBy.replace(/[\s|-]/g, `_`);
        return `filter_by_${selectedFilterBy}`;
    }

    public subFilterGenerateDomId(subFilter: FilterValueModel): string {
        const domID = this.generateFilterByDomId(subFilter);
        switch (subFilter.type) {
            case 'site':
                return `${domID}`;
            case 'notificationType':
                return `${domID}`;
            default:
                const selectedAction = subFilter.selected ? 'uncheck' : 'check';
                return `${domID}_${selectedAction}`;
        }

    }

    public subFilterGetSelectedAction(subFilter: FilterValueModel): string {
        const selectedAction = subFilter.selected ? 'uncheck' : 'check';
        return `${selectedAction}`;
    }

    public getAdvanceFilterQueryParams(queryParams: Array<object>, sortingArray): Array<object> {
        this._modifiedData.sorts = sortingArray;
        const filters = this.filtersQuery() || [];
        const sortBy = this.sortsQuery() || [];
        const archiveFlag = [{ name: 'archiveFlag', value: false }];

        return [
            ...queryParams,
            ...filters,
            ...sortBy,
            ...archiveFlag
        ];
    }

    private setInitialValues(response: FiltersSortsModel) {
        this.setInitialFilters(response.filters);
        this._modifiedData.sorts = response.sorts;
        this.syncAllSubSelections().announceData();
    }

    private setInitialFilters(filterJson: FilterModel) {
        let ModifiedFilterReponse: Array<ModifiedFilterReponseModel> = [];

        Object.keys(filterJson).forEach((filterName) => {
            ModifiedFilterReponse.push(this.getModifiedFilterObject(filterName, filterJson[filterName]));
        });

        this._modifiedData.filters = cloneDeep(ModifiedFilterReponse);
        this._modifiedData.subFilters = cloneDeep(ModifiedFilterReponse);
    }

    private getModifiedFilterObject(filterName: string, filterItem: FilterItemModel): ModifiedFilterReponseModel {
        const isMaster = filterItem.items.reduce((acc, filterValue) => acc || (filterValue.value === this._MASTER_VALUE), false);
        filterItem.items.map(filter => filter.type = filterName);
        return {
            data: cloneDeep(filterItem.items),
            isMaster: isMaster,
            label: filterItem.label,
            name: filterName
        };
    }

}
