/**
 * Copyright: Copyright © 2023
 * This file contains trade secrets of Johnson & Johnson. No part may be reproduced or transmitted in any
 * form by any means or for any purpose without the express written permission of Johnson & Johnson.
 */
import { Injectable } from '@angular/core';

/*
 * @author      @version    @date           @description
 * EElangav     1           Feb 24, 2023    AFLL-16864 Patient Profile- VRAS Case Report Tab
 * JVelagal     2           May 29, 2023    AFLL-17603 RTM | 'Create RTM Reports' page
 * Jvelagal     3           June 06, 2023   AFLL-17603 RTM | Added space in Urls
 * ZDaudy       4           June 26, 2023   AFLL-18198 RTM | Renamed the Url from RTM to Remote-Monitoring
 * LMudalig     5           Sep 19, 2023    AFLL-19367 - Portal : VPP-Patients app: Updates to the US 'Terms of Use'
 * LMudalig     6           Sep 19, 2023    AFLL-19367 - Portal : VPP-Patients app: Updates to the US 'Privacy Policy'
 * RPenimos     7           Feb 08, 2024    AFLL-20731 - ANZ | Update the translation file logic to support ANZ Launch
 * RPenimos     8           Feb 13, 2024    AFLL-20711 - ANZ | Localize Privacy Policy copy for a HCP user
 * RPenimos     9           Feb 15, 2024    AFLL-20711 - ANZ | Localize Privacy Policy copy for a HCP user
 */

@Injectable()
export class HpHcpUrlRedirect {

    public urls = {
        dashboard: '/dashboard',
        createReports: '/reports/create-reports/proms',
        rtmReports: '/reports/create-reports/remote-monitoring',
        patientAssignSurveys: '/assign-surveys',
        patientProfile: '/patient/profile/',
        patientProfileEdit: '/patient/edit/',
        patientProfileEditActionList: '/action-items',
        patientProfileInformation: '/information',
        patientProfileRiskScore: '/risk-score',
        patientProfileActionList: '/action-list',
        patientProfileCommunication: '/communication',
        patientProfilePostOp: '/post-op',
        patientIntraOpNotes: '/intra-op-notes',
        patientVrasCaseReport: '/vras-case-report',

        login: '/login',
        sso: '/sso',

        addPatient: '/patient/register',

        hcpProfile: '/hcp/profile',
        hcpManageSurgeons: '/hcp/manage-surgeons',
        hcpManageUsers: '/hcp/manage-users',
        organizations: '/hcp/organizations',
        privacyAndTerms: '/privacy-and-terms',
        PrivacyPolicy: '/general/privacy-policy',
        CookiePolicies: '/general/cookie-policies',
        TermsOfUse:  '/general/terms-of-use',
        FaqDefault: '/general/faq/default',
        ContactUs: '/general/contact-us',
        MobilePrivacyPolicy: '/general/mobile/privacy-policy',
        MobileTermsOfUse: '/general/mobile/terms-of-use',
        MobileCollectionStatement: '/general/mobile/collection-statement',
        privacyPolicy: {
            'us': '/general/privacy-policy',
            'uk': '/general/uk-privacy-policy',
            'au': '/general/anz-privacy-policy',
            'nz': '/general/anz-privacy-policy'
        } ,
        viewReports: '/reports/view-reports',
        MobileStatementOfChangesTermsOfUse: '/general/mobile/statement-of-changes-terms-of-use',
        MobileStatementOfChangesPrivacyPolicy: '/general/mobile/statement-of-changes-privacy-policy'
    };

    public readonly organizationAdminUrls = {
        hcpManageSurgeons: this.urls.hcpManageSurgeons,
        hcpManageUsers: this.urls.hcpManageUsers,
        organizations: this.urls.organizations
    };

    private _location;

    constructor() {
        this._location = window.location;
    }

    public navigate(url: string, queryParams?: Object) {
        this._location.href = this._urlWithParams(url, queryParams);
    }

    public isOrganizationAdminUrl(url: string) {
        return Object.keys(this.organizationAdminUrls).some(urlKey =>
            this.compareBaseUrl(this.organizationAdminUrls[urlKey], url));
    }

    public compareBaseUrl(baseUrl: string, subjectUrl: string): boolean {
        return (new RegExp(`^${baseUrl}([;|?|/].*)?$`)).test(subjectUrl);
    }

    private _urlWithParams(url: string, queryParams: Object): string {
        if (!queryParams) {
            return url;
        }

        const queryString = Object.keys(queryParams)
                    .map((parameter: string) => {
                        const value = queryParams[parameter];
                        return `${encodeURIComponent(parameter)}=${encodeURIComponent(value)}`;
                    })
                    .join('&');

        return `${url}?${queryString}`;
    }

}
