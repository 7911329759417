import { Component, Input, TemplateRef } from '@angular/core';

@Component({
    selector: 'hp-hcp-information-icon',
    templateUrl: './information-icon.html',
    styleUrls: ['./information-icon.scss']
})
export class InformationIconComponent {

    @Input() public title: string | TemplateRef<any>;
    @Input() public iconName = 'fa-info-circle';
    @Input() public useNgContent = false;
    @Input() public tooltipPlacement: 'top' | 'left' | 'bottom' | 'right' = 'top';
    @Input() public type: 'regular' | 'wide' = 'regular';

}
