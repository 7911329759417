import { Injectable } from '@angular/core';

import Cropper from 'cropperjs';

@Injectable()
export class HpHcpCropper {

    public getCropper() {
        return Cropper;
    }

}
