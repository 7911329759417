import {
  Component, EventEmitter, forwardRef, Injectable, Input, OnChanges, Output, TemplateRef,
  ViewChild, ViewContainerRef
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import {
  NgbCalendar, NgbDateParserFormatter, NgbDatepicker, NgbDatepickerConfig,
  NgbDatepickerI18n,
  NgbDateStruct
} from '@ng-bootstrap/ng-bootstrap';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { NgbDateCustomParserFormatter } from './ngb-date-custom-parser-formatter';
import { I18N_VALUES } from '../../constants/date-picker-I18n-values';
import { HpHcpUserSession } from '../../service/hp-hcp-user-session.service';
import { DateUtility } from '../../utils/date-utility';

const MAX_DAY = 31;
const MAX_MONTH = 12;
const MAX_YEAR_INTERVAL = 10;

const MIN_DAY = 1;
const MIN_MONTH = 1;
const MIN_YEAR_INTERVAL = 100;

@Injectable()
export class I18n {
  language = "";
}

// Define custom service providing the months and weekdays translations
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {
  constructor(private _i18n: I18n) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
  }
  getWeekdayLabel(weekday: number): string {
    return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
  }
  getWeekLabel(): string {
    return I18N_VALUES[this._i18n.language].weekLabel;
  }
  getMonthShortName(month: number): string {
    return I18N_VALUES[this._i18n.language].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }
  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}/${date.month}/${date.year}`;
  }
}

@Component({
  selector: 'hp-hcp-date-picker',
  templateUrl: './date-picker.html',
  styleUrls: ['./date-picker.scss'],
  host: {
    '(document:keydown.escape)': 'this.isOpen = false'
  },
  providers: [
    NgbDatepickerConfig,
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatePickerComponent),
      multi: true
    },
    I18n,
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }
  ]
})
export class DatePickerComponent implements ControlValueAccessor, OnChanges {

  @ViewChild('datepicker', { static: false }) public datepicker: NgbDatepicker;
  @ViewChild('target', { read: ViewContainerRef, static: false }) public target: ViewContainerRef;

  @Input() public textFieldDomId = 'date_picker_field';
  @Input() public iconDomId = 'date_picker_icon';
  @Input() public label: string | TemplateRef<any>;
  @Input() public position: string | TemplateRef<any> = 'right';

  @Output() public onChange = new EventEmitter<string>();

  public inputModel: string;
  public datepickerModel: NgbDateStruct;
  public isDisabled = false;
  public isOpen = false;
  public isTplLabel: boolean;
  public today: NgbDateStruct;
  public dateFormat: string;
  public region: string;

  constructor(
    public calendar: NgbCalendar,
    private config: NgbDatepickerConfig,
    private formatter: NgbDateParserFormatter,
    private translateService: TranslateService,
    private i18n: I18n,
    private _hpHcpUserSession: HpHcpUserSession
  ) {
    this.today = calendar.getToday();
    this.region = this._hpHcpUserSession.getUserSession().organization.country;
    this.i18n.language = this.region ? this.region : 'US';
    this.dateFormat = I18N_VALUES[this.i18n.language].dateFormat;
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.manipulateDateBasedOnLocale(event);
    });
    this.config.minDate = {
      year: this.today.year - MIN_YEAR_INTERVAL,
      month: MIN_MONTH,
      day: MIN_DAY
    };
    this.config.maxDate = {
      year: this.today.year + MAX_YEAR_INTERVAL,
      month: MAX_MONTH,
      day: MAX_DAY
    };
  }

  public propagateChange: Function = (value) => null;

  public ngOnChanges() {
    this._setTplLabel();
  }

  public writeValue(dateInput: string): void {
    dateInput = DateUtility.getRegionDateFormat(dateInput);
    const dateObj: any = this.formatter.parse(dateInput);
    if (this.calendar.isValid(dateObj)) {
      this.inputModel = dateInput;
    }
  }

  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn: any): void {
    //
  }

  public setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  public handleInputModelChange(inputDate: any): void {
    this.datepickerModel = null;
    inputDate = DateUtility.getDefaultFormatDate(inputDate);
    this.propagateChange(inputDate);
    this.onChange.emit(inputDate);
  }

  public updateInputDate(dateObj): void {
    this.inputModel = this.formatter.format(dateObj);
    let usDate = this.inputModel;
    usDate = DateUtility.getDefaultFormatDate(this.inputModel);
    this.propagateChange(usDate);
    this.onChange.emit(usDate);
  }

  public updateCalendar(): void {
    this.datepickerModel = this.formatter.parse(this.inputModel);
    this.datepicker.navigateTo(this.datepickerModel);
  }

  public isDateToday(date: NgbDateStruct): boolean {
    return date.day === this.today.day
      && date.month === this.today.month
      && date.year === this.today.year;
  }

  public isDateMuted(
    currentMonth: number,
    date: NgbDateStruct,
    disabled: boolean,
    selected: boolean
  ): boolean {
    return !selected && (date.month !== currentMonth || disabled);
  }

  private _setTplLabel(): void {
    if (this.label instanceof TemplateRef) {
      this.isTplLabel = true;
      this.target.createEmbeddedView(this.label);
    }
  }

  private manipulateDateBasedOnLocale(event: LangChangeEvent): void {
    this.i18n.language = this.region;
    this.datepicker.navigateTo({ year: this.today.year, month: this.today.month - 1 });
    if (!this.inputModel || this.inputModel === this.dateFormat) {
      this.inputModel = this.dateFormat = I18N_VALUES[this.i18n.language].dateFormat;
      return;
    }

    if (!this.datepickerModel) {
      this.updateCalendar();
    }

    this.inputModel = this.formatter.format(this.datepickerModel);
  }
}
