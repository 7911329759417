import { Injectable } from '@angular/core';
import { HpHcpHttp } from './hp-hcp-http.service';

@Injectable()
export class TkrHcpChecklistService {

    constructor (
        private _hpHcpHttp: HpHcpHttp
    ) {}

    public getHcpChecklistData(docsperaPatientId, success, failure) {
        return this._hpHcpHttp.get(this._hpHcpHttp.urls.hcpCheckList, { urlParam: docsperaPatientId }).subscribe(success, failure);
    }

    public updateCheckListItem(request, docsperaPatientId, success, failure) {
        const optionalParams = {
            requestBody: request,
            urlParam: docsperaPatientId
        };
        return this._hpHcpHttp.put(this._hpHcpHttp.urls.hcpCheckList, optionalParams).subscribe(success, failure);
    }
}
