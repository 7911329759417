import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { HpHcpUrlRedirect } from '../shared/service/hp-hcp-url-redirect.service';

@Component({
    selector: 'hp-hcp-auth-app',
    templateUrl: './auth.html',
    styleUrls: ['./auth.scss']
})

export class AuthComponent {
    constructor(
        private _router: Router,
        public urlRedirectService: HpHcpUrlRedirect
    ) { }

    public goToLogin() {
        this._router.navigateByUrl(this.urlRedirectService.urls.login);
    }

    public isTermsAndPrivacyPage() {
        return this._router.url == '/privacy-and-terms';
    }
}
