import { AfterViewInit, Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

import isEmpty from 'lodash/isEmpty';

const BACKSPACE = 8;
const PHONE_PATTERN = /^\d{1,10}$/;
const PHONE_ALLOWED_CHAR = /[^\d]/g;

@Directive({
  selector: '[phoneFormat]'
})
export class PhoneFormatDirective implements AfterViewInit {
  @Input() public excludePlaceHolderIfEmpty = false;

  @Output() public onPhoneChange: EventEmitter<Object> = new EventEmitter();

  public inputTextFormat = '(___) ___-____';
  public prevText = '(___) ___-____';
  public inputTextFormatPlaceholder = '_';

  private _elementRef: ElementRef;

  constructor(elementRef: ElementRef) {
    this._elementRef = elementRef;
  }

  public ngAfterViewInit() {
    this._updateControl();
    this._elementRef.nativeElement.blur();
  }

  @HostListener('keypress', ['$event'])
  public keyEvent(event: KeyboardEvent) {
    const keyCode = event.keyCode;
    const key = event.key || String.fromCharCode(keyCode);

    if (keyCode !== BACKSPACE) {
      let rawNumber: string = this._getRawPhoneNumber(
        this._elementRef.nativeElement.value
      ).concat(key);
      if (this._isMaxChar(rawNumber) || !this._isValidInput(rawNumber)) {
        return false;
      }
    }
  }

  @HostListener('input')
  public onEvent() {
    this._updateControl();
  }

  private _isMaxChar(rawNumber): boolean {
    let textMaxLength: number = this.inputTextFormat.replace(
      RegExp(`[^${this.inputTextFormatPlaceholder}]`, 'g'),
      ''
    ).length;
    return rawNumber.length > textMaxLength;
  }

  private _isValidInput(rawNumber): boolean {
    return PHONE_PATTERN.test(rawNumber);
  }

  private _updateControl() {
    let rawNumber = this._getRawPhoneNumber(
      this._elementRef.nativeElement.value
    );

    let formattedNumber =
      this.excludePlaceHolderIfEmpty && isEmpty(rawNumber)
        ? ''
        : this._getFormatedPhoneNumber(
            this.inputTextFormat,
            this.inputTextFormatPlaceholder,
            rawNumber
          );

    let caretPos = this._getLastDigitPosition(formattedNumber, rawNumber) + 1;

    this._elementRef.nativeElement.value = formattedNumber;
    this._elementRef.nativeElement.selectionStart = caretPos;
    this._elementRef.nativeElement.selectionEnd = caretPos;
    if (this._elementRef.nativeElement.value !== this.inputTextFormat) {
      this.onPhoneChange.emit();
      this.prevText = this._elementRef.nativeElement.value;
    } else {
      if (this.prevText != this.inputTextFormat) {
          this._elementRef.nativeElement.value = '(___) ___-____';
      }
      this.onPhoneChange.emit();
    }
  }

  private _getFormatedPhoneNumber(
    formatString: string,
    digitPlaceholder: string,
    rawNumber: string
  ): string {
    const digits = rawNumber.split('');
    let digitCounter = -1;

    const replacer = (match, p1) => {
      digitCounter += p1 ? 1 : 0;
      return p1 && digits[digitCounter] ? digits[digitCounter] : match;
    };

    const regx = new RegExp(`(${digitPlaceholder})`, 'g');
    return formatString.replace(regx, replacer);
  }

  private _getRawPhoneNumber(formatedNumber: string): string {
    return formatedNumber.replace(PHONE_ALLOWED_CHAR, '');
  }

  private _getLastDigitPosition(
    formatedNumber: string,
    rawNumber: string
  ): number {
    return rawNumber.length > 0
      ? formatedNumber.lastIndexOf(rawNumber[rawNumber.length - 1])
      : -1;
  }
}
