<div class="pagination-section">
    <div class="pagination-wrapper" *ngIf="(listLength / currentPage) >= 1">
        <div class="page-info">
            <span *ngIf="firstItem !== listLength">{{firstItem}} -</span>
            {{(lastItem < (listLength)) ? (lastItem) : (listLength) }} OF {{listLength}}
        </div>
        <div class="pagination-controls">
            <button
              [attr.disabled]="isLeftDisabled ? true : null"
              [class.disabled]="isLeftDisabled"
              data-dom-id="pagination_arrow_backward"
              class="page-button page-button-left two-columns"
              (click)="navigateNext(-step)">
                <img *ngIf="!isLeftDisabled" data-dom-id="pagination_arrow_backward" src="../../../../assets/img/cal_arrow_left.svg" class="arrow-icon" alt="backward">
                <img *ngIf="isLeftDisabled" src="../../../../assets/img/cal_arrow_left_disabled.svg" class="arrow-icon" alt="backward">
            </button>
            <button
              [attr.disabled]="isRightDisabled ? true : null"
              [class.disabled]="isRightDisabled"
              data-dom-id="pagination_arrow_forward"
              class="page-button page-button-right two-columns"
              (click)="navigateNext(step)">
                <img *ngIf="!isRightDisabled" data-dom-id="pagination_arrow_forward" src="../../../../assets/img/cal_arrow_right.svg" class="arrow-icon" alt="forward">
                <img *ngIf="isRightDisabled" src="../../../../assets/img/cal_arrow_right_disabled.svg" class="arrow-icon" alt="forward">
            </button>
        </div>
    </div>
</div>
