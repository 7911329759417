import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ServiceModule } from './service.module';
import { HpHcpBctEventService } from './service/hp-hcp-bct-event.service';

import { AdjustSourceHeightToTargetDirective } from './directives/adjust-source-height-to-target.directive';
import { BctEventCaptureDirective } from './directives/bct-event-capture.directive';
import { EnterKeyClickDirective } from './directives/enter-key-click.directive';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { DateFormatDirective } from './directives/date-format.directive';
import { HasFloatingLabelClassDirective } from './directives/has-floating-label-class.directive';
import { InputTextFormatDirective } from './directives/input-text-format.directive';
import { ToggleMenuActiveDirective, ToggleMenuDirective } from './directives/toggle-menu.directive';
import { PhoneFormatDirective } from './directives/phone-format.directive';
import { ScrollElementIntoViewDirective } from './directives/scroll-element-into-view.directive';
import { DateRangePickerDirective } from './directives/date-range-picker.directive';

@NgModule({
    imports: [
        CommonModule,
        ServiceModule
    ],
    exports: [
        AdjustSourceHeightToTargetDirective,
        BctEventCaptureDirective,
        EnterKeyClickDirective,
        ClickOutsideDirective,
        DateFormatDirective,
        HasFloatingLabelClassDirective,
        InputTextFormatDirective,
        ToggleMenuActiveDirective,
        ToggleMenuDirective,
        PhoneFormatDirective,
        ScrollElementIntoViewDirective,
        DateRangePickerDirective
    ],
    providers: [
        HpHcpBctEventService
    ],
    declarations: [
        AdjustSourceHeightToTargetDirective,
        BctEventCaptureDirective,
        EnterKeyClickDirective,
        ClickOutsideDirective,
        DateFormatDirective,
        HasFloatingLabelClassDirective,
        InputTextFormatDirective,
        ToggleMenuActiveDirective,
        ToggleMenuDirective,
        PhoneFormatDirective,
        ScrollElementIntoViewDirective,
        DateRangePickerDirective
    ]
})
export class DirectiveModule {}
