import { Injectable } from '@angular/core';
import { HpHcpHttp } from './hp-hcp-http.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class TkrAsesData {

    constructor (
        private _hpHcpHttp: HpHcpHttp
    ) {}

    public getPatientAsesData(patientId, success, failure) {
        if (environment.country == 'UK') {
            this._hpHcpHttp.get(this._hpHcpHttp.urls.chart.oxfordShoulderData, { urlParam: patientId }).subscribe(success, failure);
        } else {
            this._hpHcpHttp.get(this._hpHcpHttp.urls.chart.asesData, { urlParam: patientId }).subscribe(success, failure);
        }
    }
}
