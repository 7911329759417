import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';

const WORKER_URL = '/session-sync-worker.js';

const SET_ITEM = 'SET_ITEM';
const REMOVE_ITEM = 'REMOVE_ITEM';
const ALL_DATA = 'ALL_DATA';
const USER_LOGOUT = 'USER_LOGOUT';
const CLIENT_CLOSE = 'CLIENT_CLOSE';

const instance_Id = Date.now() + Math.round(Math.random() * 1000);

@Injectable()
export class HpHcpSessionSync {
    private _fireFoxSharedWorker: any = null;
    private _outPort: any = null;
    private _id: number;
    private _window: any;
    public isServiceWorkerAvailable = false;

    public get isServiceWorkerActive(): boolean {
        return Boolean(navigator.serviceWorker.controller) || this._fireFoxSharedWorker;
    }

    constructor(private _router: Router = null) {
        this._id = instance_Id;
        this._window = window;

        if ('SharedWorker' in window) {
            this._fireFoxSharedWorker = new SharedWorker(WORKER_URL);
            this.isServiceWorkerAvailable = true;
            this._outPort = this._fireFoxSharedWorker.port;
        } else if ('serviceWorker' in navigator) {
            this.isServiceWorkerAvailable = true;
            navigator.serviceWorker.register(WORKER_URL, {scope: '/'})
                .then((reg) => {
                })
                .catch((err) => {
                });
            this._outPort = navigator.serviceWorker.controller;
        }

        this._window.onbeforeunload = () => {
            const message = this.getMessage(CLIENT_CLOSE);
            this._outPort.postMessage(JSON.stringify(message));
        };
    }

    public syncSession() {
        if (!this.isServiceWorkerAvailable || !this.isServiceWorkerActive) {
            return new Observable((observer) => {
                observer.next(true);
                observer.complete();
            });
        }

        return new Observable((observer) => {
            const msgChannel = new MessageChannel();
            const onmessage = (e) => {
                let received;
                try {
                    received = JSON.parse(e.data);
                } catch (error) {
                    observer.next(true);
                    observer.complete();
                    return;
                }
                switch (received.type) {
                    case ALL_DATA:
                        for (let key in received.data) {
                            sessionStorage.setItem(key, received.data[key]);
                        }
                        observer.next(true);
                        observer.complete();
                        break;
                    case USER_LOGOUT:
                        sessionStorage.clear();
                        this._router && this._router.navigateByUrl('/login');
                        break;
                }
            };

            msgChannel.port1.onmessage = onmessage;
            if (this._fireFoxSharedWorker) {
                this._outPort.onmessage = onmessage;
            }
            const message = this.getMessage(ALL_DATA);
            // second parameter required in case of service worker only
            this._outPort.postMessage(JSON.stringify(message), [msgChannel.port2]);
        });
    }

    public setItem(key, value) {
        if (!this.isServiceWorkerAvailable || !this.isServiceWorkerActive) {
            return;
        }
        const message = this.getMessage(SET_ITEM, {key, value});
        this._outPort.postMessage(JSON.stringify(message));
    }

    public removeItem(key) {
        if (!this.isServiceWorkerAvailable || !this.isServiceWorkerActive) {
            return;
        }
        const message = this.getMessage(REMOVE_ITEM, {key});
        this._outPort.postMessage(JSON.stringify(message));
    }

    public userLogout() {
        if (!this.isServiceWorkerAvailable || !this.isServiceWorkerActive) {
            return;
        }
        const message = this.getMessage(USER_LOGOUT);
        this._outPort.postMessage(JSON.stringify(message));
    }

    private getMessage(type: string, data: object = {}): object {
        return {
            type,
            id: this._id,
            ...data
        };
    }
}
