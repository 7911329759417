<hp-hcp-information-icon
    class="surgery-date-info"
    data-dom-id="surgery_date_info"
    *ngIf="previousSurgeryDate"
    [title]="surgeryDateTooltip"
    type="wide">
</hp-hcp-information-icon>
<ng-template #surgeryDateTooltip>
    Surgery date changed from {{ previousSurgeryDate | date: 'MM/dd/y' }}.<br/>
    Action items were reset to reflect this new date.
</ng-template>
