import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { HpHcpAuth } from '../../../shared/service/hp-hcp-auth.service';

const ERROR_MESSAGE = 'The application has encountered an unknown error.';

@Component({
    selector: 'hp-hcp-account-activation-declined',
    templateUrl: './activation-activation-decline.html',
    styleUrls: ['../account-activation.scss']
})
export class AccountActivationDeclineComponent implements OnInit {
    public responseError: string;
    public shouldShowConfirmation = false;

    constructor(
        private _authService: HpHcpAuth,
        private _routeParams: ActivatedRoute
    ) {}

    public ngOnInit() {
        this._routeParams.queryParams.subscribe(
          ({ invitationCode = '' }) => this.declineAccountActivation(invitationCode)
        );
    }

    public declineAccountActivation(invitationCode) {
        this._authService
            .declineAccountActivation(invitationCode)
            .subscribe(
              () => this.shouldShowConfirmation = true,
              ({ message = ERROR_MESSAGE }) => this.responseError = message
            );
    }
}
