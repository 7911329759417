import { Injectable } from '@angular/core';

@Injectable()
export class HpHcpDatePickerService {

  public setDateAsEmptyIfInvalid(dateFromDatePicker: string): string {
    return /^\d{2}\/\d{2}\/\d{4}$/.test(dateFromDatePicker)
      ? dateFromDatePicker
      : '';
  }

  public setTimeAsEmptyIfInvalid(formattedTime: string): string {
    return /^(0?[1-9]|1[012])(:[0-5]\d) [APap][mM]$/.test(formattedTime)
      ? formattedTime
      : '';
  }

}
