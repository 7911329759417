import { Injectable } from '@angular/core';
import { Subscription, ReplaySubject } from 'rxjs';
import { HpHcpHttp } from '../../shared/service/hp-hcp-http.service';

@Injectable()
export class HpHcpRefreshKeyService {

    private _dataAnnouncedSource = new ReplaySubject<any>(1);
    private _responseData;

    constructor(private _hpHcpHttp: HpHcpHttp) {
    }

    public subscribe(callback): Subscription {
        return this._dataAnnouncedSource.asObservable().subscribe(callback);
    }

    public announceData() {
        this._dataAnnouncedSource.next(this._responseData);
    }

    public disposeData() {
        this._responseData = null;
        this._dataAnnouncedSource.complete();
        this._dataAnnouncedSource = new ReplaySubject<any>(1);
    }

    public refreshConnectKey(id: number) {
        this._hpHcpHttp.post(this._hpHcpHttp.urls.patient.refreshKey, { urlParam: id })
             .subscribe(this.setInitialValues.bind(this));
    }

    private setInitialValues(response) {
        this._responseData = response;
        this.announceData();
    }

}
