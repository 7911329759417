export const DateTimeFormatUtility = {
    'default': {
        'dateFormat': {
            'regionDateFormat': 'MM/DD/YYYY',
            'middleEndianDate': 'MMM DD, YYYY',
            'romVasStepHoverDateFormat': 'yyyy-MM-dd',
            'datePickerFormat': 'mmddyyyy'
        },
        'timeFormat': {
            'shortTime': 'h:mm a',
            'shortTimeExtended': 'hh:mm a'
        }
    },
    'us': {
        'dateFormat': {
            'regionDateFormat': 'MM/DD/YYYY',
            'middleEndianDate': 'MMM DD, YYYY',
            'romVasStepHoverDateFormat': 'yyyy-MM-dd',
            'datePickerFormat': 'mmddyyyy'
        },
        'timeFormat': {
            'shortTime': 'h:mm a',
            'shortTimeExtended': 'hh:mm a'
        }
    },
    'uk': {
        'dateFormat': {
            'regionDateFormat': 'DD/MM/YYYY',
            'middleEndianDate': 'DD MMM YYYY',
            'romVasStepHoverDateFormat': 'dd-MM-yyyy',
            'datePickerFormat': 'ddmmyyyy'
        },
        'timeFormat': {
            'shortTime': 'H:mm',
            'shortTimeExtended': 'HH:mm'
        }
    },
    'au': {
        'dateFormat': {
            'regionDateFormat': 'DD/MM/YYYY',
            'middleEndianDate': 'DD MMM YYYY',
            'romVasStepHoverDateFormat': 'dd-MM-yyyy',
            'datePickerFormat': 'ddmmyyyy'
        },
        'timeFormat': {
            'shortTime': 'H:mm',
            'shortTimeExtended': 'HH:mm'
        }
    },
    'nz': {
        'dateFormat': {
            'regionDateFormat': 'DD/MM/YYYY',
            'middleEndianDate': 'DD MMM YYYY',
            'romVasStepHoverDateFormat': 'dd-MM-yyyy',
            'datePickerFormat': 'ddmmyyyy'
        },
        'timeFormat': {
            'shortTime': 'H:mm',
            'shortTimeExtended': 'HH:mm'
        }
    }
}