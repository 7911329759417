import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

export interface ToggleMenuItemModel {
  id: null|number;
  name: string;
};

@Component({
  selector: 'hp-hcp-toggle-menu',
  templateUrl: './toggle-menu.html',
  styleUrls: ['./toggle-menu.scss']
})
export class ToggleMenuComponent implements OnChanges {
  @Input() public categoryId = 'button';
  @Input() public domId;
  @Input() public eventGroup;
  @Input() public options: Array<ToggleMenuItemModel>;
  @Input() public selected: ToggleMenuItemModel;
  @Input() public withPrefixedIndexAsDomId;

  @Output() public onSelect = new EventEmitter<ToggleMenuItemModel>();

  public title: string;
  public sortedOptions: Array<ToggleMenuItemModel>

  public ngOnChanges() {
    this.title = this.selected.name;
    this.sortedOptions = [...this.options].sort((a, b) => a.name.localeCompare(b.name));
  }

  public handleSelect(index) {
    const selected = {...this.sortedOptions[index]};
    this.title = selected.name;
    this.onSelect.emit(selected);
  }
}
