/**
 * Copyright: Copyright © 2023
 * This file contains trade secrets of Johnson & Johnson. No part may be reproduced or transmitted in any
 * form by any means or for any purpose without the express written permission of Johnson & Johnson.
 */
import { Injectable } from '@angular/core';
import { HpHcpHttp } from '../../shared/service/hp-hcp-http.service';

/**
 * @author      @version    @date               @description
 * APawar22     01          March 1, 2023       AFLL-16496 Export PROMs data | Generate Report - Queue Requests
 * APawar22     02          March 10, 2023      AFLL-16498 Export PROMs data | List of generated reports
 * APawar22     03          March 17, 2023      AFLL-16948 Download report API Integration
 * HSenevir     04          Apr 01, 2023        AFLL-16880 - Supporting Angular CLI build and refactoring
 * RParamma     05          Apr 17, 2023        AFLL-16501 Export PROMs data | Populate the AJRR file - Fields 'Q' to 'AG' (#17 to #33)
 * JSumandi     06          Nov 24, 2023        AFLL-17265 PROMs| Fixed the Date Picker Validation Issues in Create Reports Page, added "getRegExpression,getsplitedDate" functions
 */
@Injectable()
export class ReportService {
    private hpHcpHttp: HpHcpHttp;

    constructor(hpHcpHttp: HpHcpHttp) {
        this.hpHcpHttp = hpHcpHttp;
    }

    public createReportQueue(staffId, sites, startDate, endDate, reportType, success, failure) {
        const dateRange = startDate + ' ' + '-' + ' ' + endDate;
        const requestBody = {
            staffId: staffId,
            siteIds: sites,
            reportTypeName: reportType,
            dateRange: dateRange
        };
        return this.hpHcpHttp.post(this.hpHcpHttp.urls.report.createReportQueue, {requestBody}).subscribe(success, failure);
    }

    public listReports(reportType, success, failure) {
        const queryParams = [
            {name: 'reportTypeName', value: reportType}
        ];
        return this.hpHcpHttp.get(this.hpHcpHttp.urls.report.listReports, {queryParams}).subscribe(success, failure);
    }

    public downloadReport(reportId, success, failure) {
        return this.hpHcpHttp.get(this.hpHcpHttp.urls.report.downloadReport, {urlParam: reportId}).subscribe(success, failure);
    }

    public getRegExpression(expression) {
        return new RegExp(expression);
    }

    public getsplitedDate(date) {
        if (date !== null) {
            date = date.toLocaleString();
            date = date.split(',', 2);
            return date[0];
        }
    }
}
