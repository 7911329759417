import { Injectable } from '@angular/core';

import { BctEventIdUrnModel } from '../model/bct-event-id-urn.model';
import { BctEventRequestModel } from '../model/bct-event-request.model';
import { DateUtility } from '../utils/date-utility';
import { environment } from '../../../environments/environment';
import { HpHcpHttp } from './hp-hcp-http.service';
import { HpHcpUserAgentService } from './hp-hcp-user-agent.service';
import { HpHcpUserSession } from './hp-hcp-user-session.service';

const URN_DEFAULT: BctEventIdUrnModel = {
    protocol: 'urn',
    namespace: 'hws',
    appName: 'ortho-portal',
    eventSection: '',
    eventCode: ''
};

@Injectable()
export class HpHcpBctEventService {

    private _hpHcpHttp: HpHcpHttp;
    private _userAgentService: HpHcpUserAgentService;
    private _userSession: HpHcpUserSession;

    public constructor(hpHcpHttp: HpHcpHttp, userAgentService: HpHcpUserAgentService, userSession: HpHcpUserSession) {
        this._hpHcpHttp = hpHcpHttp;
        this._userAgentService = userAgentService;
        this._userSession = userSession;
    }

    public saveEvent(eventCode: string, eventSection: string, patientId: number) {

        const requestBody: BctEventRequestModel = {
            datetime: DateUtility.dateToISOString(new Date()),
            environment: environment.production ? 'production' : 'development',
            browserUserAgent: this._userAgentService.getUserBrowserName(),
            patientId: patientId,
            userId: this._userSession.getUserSession().id,
            eventId: this.generateEventIdUrn(Object.assign({}, URN_DEFAULT, {eventSection, eventCode})),
            eventType: 'EventContext'
        };
        this._hpHcpHttp.post(this._hpHcpHttp.urls.bctTracker.save, { requestBody }).subscribe();
    }

    public generateEventIdUrn(eventIdUrnObject: BctEventIdUrnModel) {
        return Object.keys(eventIdUrnObject)
            .map((property) => eventIdUrnObject[property].toLowerCase().replace(/_|\s/g, '-')).join(':');
    }

}
