import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { OneMinuteDelayModalComponent } from '../ui/one-minute-delay-modal/one-minute-delay-modal.component';
import { HpHcpHttp } from './hp-hcp-http.service';

@Injectable()
export class TkrExternalSsoService {

    constructor(
        private hpHcpHttp: HpHcpHttp,
        public dialog: MatDialog
    ) { }

    public getSignedUrl(navigationLink, patientId, eventId, success, failure) {
        const queryParams = [
            { name: 'patientId', value: patientId },
            { name: 'eventId', value: eventId }
        ];
        this.hpHcpHttp.get(this.hpHcpHttp.urls.externalSso.signedUrl + '/' + navigationLink ,{ queryParams } ).subscribe(success, failure);
    }

    public openOneMinDelayModal(isEmrSite) {
        this.dialog.open(OneMinuteDelayModalComponent, {
            panelClass: 'one-min-delay-modal',
            disableClose: true,
            data: {
                isEmrSite: isEmrSite
            }
        })
    }
}