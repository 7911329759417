import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { HpHcpRoutesAuthGuard } from './shared/service/hp-hcp-routes-guard.service';

const routes: Routes = [
    {
        path: 'dashboard',
        canLoad: [ HpHcpRoutesAuthGuard ],
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
        data: { pageTitle: 'Dashboard' },
        runGuardsAndResolvers: 'always'
    },
    {
        path: 'reports',
        canLoad: [ HpHcpRoutesAuthGuard ],
        loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),
        data: { pageTitle: 'Reports' },
        runGuardsAndResolvers: 'always'
    },
    {
        path: 'hcp',
        canLoad: [ HpHcpRoutesAuthGuard ],
        loadChildren: () => import('./hcp-user/hcp-user.module').then(m => m.HcpUserModule),
        data: { pageTitle: 'HCP User' }
    },
    {
        path: 'patient',
        canLoad: [ HpHcpRoutesAuthGuard ],
        loadChildren: () => import('./patient/patient.module').then(m => m.PatientModule),
        data: { pageTitle: 'Patient' }
    },
    {
        path: 'patient/lists',
        canLoad: [ HpHcpRoutesAuthGuard ],
        loadChildren: () => import('./patient-lists/patient-lists.module').then(m => m.PatientListsModule),
        data: { pageTitle: 'Patient Lists' }
    },
    {
        path: 'general',
        loadChildren: () => import('./footer-links/footer-links.module').then(m => m.FooterLinksModule),
        data: { pageTitle: 'Patient Lists' }
    }
];

@NgModule({
    imports: [ RouterModule.forRoot(
        routes,
        {
            anchorScrolling: 'enabled',
            scrollPositionRestoration: 'enabled',
            onSameUrlNavigation: 'reload',
            preloadingStrategy: PreloadAllModules
        }
    )],
    exports: [ RouterModule ]
})
export class AppRoutingModule {}
