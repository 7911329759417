/**
 * Copyright: Copyright © 2019
 * This file contains trade secrets of Johnson & Johnson. No part may be reproduced or transmitted in any
 * form by any means or for any purpose without the express written permission of Johnson & Johnson.
 */

import { Pipe, PipeTransform } from '@angular/core';

import { DateUtility, DATE_FORMATS, TIME_FORMATS } from '../utils/date-utility';

/*
 * @author      @version    @date           @description
 * HSenevir     1           Mar 15, 2023    AFLL-15210 Added date pipe option
 * PPareek      2           Aug 09, 2024    AFLL-17436 Added date pipe option for fixed date in defaultDateFormat
 *
 */
@Pipe({ name: 'hcpDate' })
export class HcpDatePipe implements PipeTransform {

    public transform(date: string, option: string): string {
        switch (option) {
            case 'locale':
                return this._localeDate(date);
            case 'utcDate':
                return this._utcDate(date);
            case 'age':
                return this._dateOfBirth(date);
            case 'shortTime':
                return this._formatLocalDate(date, TIME_FORMATS.shortTime);
            case 'shortTimeExtended':
                return this._formatLocalDate(date, TIME_FORMATS.shortTimeExtended);
            case 'fixedShortTime':
                return this._formatFixedDate(date, TIME_FORMATS.shortTime);
            case 'fixedShortTimeExtended':
                return this._formatFixedDate(date, TIME_FORMATS.shortTimeExtended);
            case 'shortLocalTime':
                return this._formatLocalDate(date, TIME_FORMATS.shortTime);
            case 'timeUTCStringToShortTime':
                return this._formatTimeUTCStringToShortTime(date);
            case 'mediumDate':
                return this._formatLocalDate(date, DATE_FORMATS.middleEndianDate);
            case 'defaultDate':
                return this._formatLocalDate(date, DATE_FORMATS.defaultDateFormat);
            case 'fixedDateDefaultFormat':
                return this._formatFixedDate(date, DATE_FORMATS.defaultDateFormat);
            case 'fixedDate':
                return this._fixedDate(date);
            default:
                return date;
        }
    }

    private _dateOfBirth(date: string) {
        const birth = +new Date(date);
        const now = +new Date();
        const diff = new Date(now - birth);
        const dateOfBirth = Math.abs(diff.getUTCFullYear() - 1970);

        return String(dateOfBirth);
    }

    private _formatLocalDate(date: string, format: string) {
        return DateUtility.parseLocalDate(date, format);
    }

    private _formatFixedDate(date: string, format: string) {
        return DateUtility.parseFixedDate(date, format);
    }

    private _formatTimeUTCStringToShortTime(date) {
        return DateUtility.formatTime(date);
    }

    private _localeDate(date) {
        return DateUtility.formatDate(date);
    }

    private _utcDate(date) {
        return DateUtility.formatUTCDate(date);
    }

    private _fixedDate(date) {
        return DateUtility.parseFixedDate(date);
    }

}
