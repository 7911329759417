import {Injectable} from '@angular/core';
import {AppConstants} from '../../config/constants';

// declare let ga: Function;
declare let dataLayer;

/*
 *  JIRA: AFLL-3653 - Commented out functionality and kept the code in place if we revisit the GA implementation.
 */

@Injectable()
export class HpHcpGAService {
    private gaCode: String = AppConstants.gaCode;

    public setOrganizationDimension(userSession) {
        if (userSession !== null) {
            let userOrg = userSession.organization.name;
            // if userOrg org is available we set the organization custom dimension
            if (userOrg) {
                let gtag = function() {dataLayer.push(arguments); };
                // @ts-ignore
                gtag('create', this.gaCode, {'cookieDomain': 'auto', 'cookieFlags': 'SameSite=None;Secure'});
                // @ts-ignore
                gtag('set', 'user_properties', { 'organization': userOrg });
                // @ts-ignore
                gtag('send', 'pageview', {'sessionControl': 'start'});
            }
        }
    }

    public setCarePlanDimension(dimensionValue) {
        /*ga('set', 'dimension4', dimensionValue);
        dataLayer.push({'event' : 'pageview', 'care-plan': dimensionValue});*/
    }

    public sendClickEvent(selectedElement, customAction) {
        /*dataLayer.push({
            event: 'gtm.click',
            'gtm.customAction': customAction,
            selectedElement: selectedElement,
            'gtm.elementId': selectedElement.dataset.domId,
            'gtm.element.dataset.domId': selectedElement.dataset.domId,
            'gtm.element.dataset.eventGroup' : selectedElement.dataset.eventGroup,
            'gtm.element.dataset.categoryId' : selectedElement.dataset.categoryId,
            'gtm.element.dataset.actionId' : selectedElement.dataset.actionId
        });*/
    }

    public sendCustomEvent(eventName, selectedElement) {
        /*dataLayer.push({
            event: eventName,
            selectedElement: selectedElement,
            'gtm.elementId': selectedElement.dataset.domId,
            'gtm.element.dataset.domId': selectedElement.dataset.domId,
            'gtm.element.dataset.eventGroup' : selectedElement.dataset.eventGroup,
            'gtm.element.dataset.actionId' : selectedElement.dataset.actionId
        });*/
    }

    public sendModalEvent(domId) {
        /*dataLayer.push({
            event: 'gtm.modal',
            'gtm.element.dataset.domId': domId
        });*/
    }

    public formatStringToLowercaseUnderscore(str: String): string {
        return str.replace(/[^\w -]/g, '').replace(/[\s|-]/g, '_').toLowerCase();
    }
}
