import { Injectable } from '@angular/core';

@Injectable()
export class HpHcpBrowserPrint {
    private _window: any;

    constructor() {
        this._window = window;
    }

    public print() {
        this._window.print();
    }
}
