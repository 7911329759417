import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { DateUtility, DATE_FORMATS } from '../../shared/utils/date-utility';

const DATE_PICKER_FORMAT = 'mmddyyyy';
const MINIMUM_PASSWORD_LENGTH = 8;
const REGEXPS = {
    email: /^(?!\.)(?!.*?\.\.)(?!.*\.$)[\w-.]+(.[\w-]+)@([\w-]+(.[\w-]+)?.[a-zA-Z]{2,6}|(\d{1,3}.){3}\d{1,3})(:\d{4})?$/,
    password: {
        lowercase: /(?=.*[a-z])/,
        number: /(?=.*[0-9])/,
        special: /(?=.*[_+\-.,!?@#$%^&*();\/|<>])/,
        uppercase: /(?=.*[A-Z])/
    },
    phone: /\d{10}/g,
    rawTime: /^\d{4}[aApP][mM]$/,
    numbers: /^[0-9]\d*(\.\d+)?$/,
    integers: /^[0-9]\d*$/,
    dp2numbers: /^[0-9]\d*(\.\d{0,2})?$/
};

@Injectable()
export class HpHcpValidators {

    public passwordStrength(password) {
        if (!password || password.length < MINIMUM_PASSWORD_LENGTH) {
            return false;
        }

        const { lowercase, number, special, uppercase } = REGEXPS.password;

        const containsRequired = password.match(lowercase) && password.match(uppercase);
        const containsOneOptional = password.match(special) || password.match(number);
        if (containsRequired && containsOneOptional) {
            return true;
        }

        return false;
    }

    public date({ allowEmpty }) {
        return function (control: FormControl) {
            const dateString = control.value.replace(/D|M|Y|\//g, '');
            if (allowEmpty && !dateString.length) {
                return null;
            }
            return DateUtility.validateDate(dateString, DATE_PICKER_FORMAT)
                ? null
                : (dateString == "________" ? null : { badFormat: true });
        };
    }

    public dischargeComesAfterSurgery({ controls }: FormGroup): { dischargeComesAfterSurgery: boolean } | null {
        const { dischargeDate, surgeryDate } = controls;
        const daysStayedAfterSurgery = DateUtility.daysBetween(surgeryDate.value, dischargeDate.value, DATE_FORMATS.defaultDateFormat);
        return (dischargeDate.value && (!surgeryDate.value || daysStayedAfterSurgery < 0))
            ? { dischargeComesAfterSurgery: true }
            : null;
    }

    public requirePastDate({ allowEmpty }) {
        return function (control: FormControl) {
            const dateString = control.value.replace(/D|M|Y|\//g, '');
            if (allowEmpty && !dateString.length) {
                return null;
            }
            if (DateUtility.validateDate(dateString, DATE_PICKER_FORMAT)) {
                if (DateUtility.isBeforeToday(control.value)) {
                    return null;
                } else {
                    return { badFormat: true };
                }
            } else {
                return { badFormat: true };
            }
        };
    }

    public requireCurrentOrFutureDate({ allowEmpty }) {
        return function (control: FormControl) {
            const dateString = control.value.replace(/D|M|Y|\//g, '');
            if (allowEmpty && !dateString.length) {
                return null;
            }
            if (DateUtility.validateDate(dateString, DATE_PICKER_FORMAT)) {
                if (DateUtility.isToday(control.value) || DateUtility.isAfterToday(control.value)) {
                    return null;
                } else {
                    return { badFormat: true };
                }
            } else {
                return { badFormat: true };
            }
        };
    }

    public carePlanProcedureCompatibility(control: FormControl) {
        if (control.value == true) {
            return null;
        } else {
            return { badFormat: true };
        }
    }

    public email(control: FormControl) {
        return !control.value || control.value.match(REGEXPS.email) ? null : { badFormat: true };
    }

    public range(min, max) {
        return function (control: FormControl) {
            if (!control.value || (control.value && control.value <= max && control.value >= min)) {
                return null;
            } else {
                return { badFormat: true };
            }
        };
    }

    public integerRange(min, max) {
        return function (control: FormControl) {
            if (!control.value || (control.value && control.value <= max && control.value >= min 
                && control.value.match(REGEXPS.integers))) {
                return null;
            } else {
                return { badFormat: true };
            }
        };
    }

    public dp2Range(min, max) {
        return function (control: FormControl) {
            if (!control.value || (control.value && control.value <= max && control.value >= min 
                && control.value.match(REGEXPS.dp2numbers))) {
                return null;
            } else {
                return { badFormat: true };
            }
        };
    }

    public surgeryTimesAcceptable(control: FormControl) {
        if (control.value == true) {
            return null;
        } else {
            return { badFormat: true };
        }
    }

    public number(control: FormControl) {
        return ((control.value && control.value.match(REGEXPS.numbers)) || !control.value) ? null : { badFormat: true };
    }

    public integer(control: FormControl) {
        return ((control.value && control.value.match(REGEXPS.integers)) || !control.value) ? null : { badFormat: true };
    }

    public highRiskValidate(required: boolean) {
        return ({ value: selectedRisks = [] }: FormControl) => {
            if (!required) {
                return null;
            }

            if (selectedRisks.length) {
                return null;
            }

            return { highRiskError: true };
        };
    }

    public time(control: FormControl) {
        let rawTime: string = control.value.replace(/[^\daAmMpP]/g, '');

        if (!rawTime) {
            return null;
        }

        if (REGEXPS.rawTime.test(rawTime)) {
            let hour: number = parseInt(rawTime.substring(0, 2), 10);
            let minute: number = parseInt(rawTime.substring(2, 4), 10);

            if ((hour >= 1 && hour <= 12) && (minute >= 0 && minute <= 59)) {
                return null;
            }
        }

        return { badFormat: true };

    }

    public validateSinglePhone(control: FormControl, isOptional = false) {
        let value = control.value;
        let phone: string = value.replace(/[^\d]/g, '');
        if (isOptional) {
            return value === '' || !phone || phone.match(REGEXPS.phone) ? null : { badFormat: true };
        } else {
            return !phone || phone.match(REGEXPS.phone) ? null : { badFormat: true };
        }
    }

    public validatePhone(control: FormControl) {
        let phone: string = control.value.value.replace(/[^\d]/g, '');
        return !phone || phone.match(REGEXPS.phone) ? null : { badFormat: true };
    }

    public isNotZero(control: FormControl) {
        return (control.value === 0) ? { badFormat: true } : null;
    }

    public consent(control: FormControl) {
        return control.value ? null : { badFormat: true };

    }

    public IsDateLessThanHundredYearsFromCurrentDate({ allowEmpty }) {
        return function (control: FormControl) {
            const dateString = control.value.replace(/D|M|Y|\//g, '');
            const currentDate = new Date();
            const currentYear = currentDate.getFullYear() -100;
            const date = control.value.replace(/D|M|Y|\//g, '');
            const userYear = date.slice(-4);
            if (allowEmpty && !dateString.length) {
                return null;
            }
            if (DateUtility.validateDate(dateString, DATE_PICKER_FORMAT)) {
                if ( userYear>currentYear) {
                    return null;
                } else {
                    return { badFormat: true };
                }
            } else {
                return { badFormat: true };
            }
        };
    }

}
