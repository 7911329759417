import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'hyphenWordToCaps'
})

export class HyphenWordToCaps implements PipeTransform {
    public transform( value: string ) {
        if ( value ) {
            return value.split('-').map(word => word.slice(0, 1).toUpperCase() + word.slice(1)).join('-');
        }
    }
}
