import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { I18N_VALUES } from '../../constants/date-picker-I18n-values';
import { I18n } from './date-picker.component';

@Injectable()
export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {
    constructor(private _i18n: I18n) {
        super();
    }

    public parse(value: string): NgbDateStruct {
        if (value) {
            return I18N_VALUES[this._i18n.language].split(value);
        }
        return null;
    }

    public format(date: NgbDateStruct): string {
        return I18N_VALUES[this._i18n.language].formatLocaleDate(date);
    }
}
