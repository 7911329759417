import { Injectable } from '@angular/core';
import { AppConstants } from '../../config/constants';
import { HpHcpAuth } from './hp-hcp-auth.service';
import { HpHcpUserSession } from './hp-hcp-user-session.service';

/*
 * @author      @version    @date           @description
 * HSenevir     01          Jul 17, 2023    AFLL-18875 - Removed sessionInterrupted tracking with same browser multiple tab tracking
 */

@Injectable()
export class HpHcpSessionPolling {

    constructor(
        private _hpHcpAuth: HpHcpAuth,
        private _hpHcpUserSession: HpHcpUserSession
    ) {}

    public _sendUserToLogin(inactivity?: boolean) {
        const sendUserToLogin = () => {
            window.location.href = inactivity ? `${AppConstants.vdsUrl}/#/login?${this._hpHcpAuth.getLogoutParams()}&inactivity=${inactivity}` : `${AppConstants.vdsUrl}#/login`;
        };

        this._hpHcpAuth.logout(sendUserToLogin, sendUserToLogin);
    }

}
