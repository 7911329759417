<div class="hp-hcp-patient-photo">
    <div *ngIf="photoUrl then image_content; else noimage_content"></div>

    <div class="link" *ngIf="!noEdit">
            <a class="fa fa-pencil link-icon photo-edit-link"
                data-dom-id="edit_image"
                [routerLink]="['/patient/edit', patientId, 'patient-photography']">
            </a>
    </div>
</div>

<ng-template #image_content>
    <img [ngClass]="!noEdit ? 'patient-photo' : 'patient-photo-small'"  [attr.src]="photoUrl">
</ng-template>

<ng-template #noimage_content>
    <div [ngClass]="!noEdit ? 'patient-initials' : 'patient-initials-small'">
        {{getPatientInitials()}}
    </div>
</ng-template>