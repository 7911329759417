import { Injectable } from '@angular/core';

const PAGE_TOP_SELECTOR = 'html';

@Injectable()
export class HpHcpScrollService {

    private _windowRef: any;

    constructor() {
      this._windowRef = window;
    }

    public scrollToTopOf (element: any) {
        element.scrollIntoView(true);
    }

    public scrollToPageTop() {
        this.scrollToTopOf(this._windowRef.document.querySelector(PAGE_TOP_SELECTOR));
    }
}
