import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { HpHcpHttp } from './hp-hcp-http.service';
import { HpHcpLocalStorage, STORAGE_NAMES } from './hp-hcp-localstorage.service';
import { HpHcpUserSession } from './hp-hcp-user-session.service';
import { CarePlanModel } from '../model/care-plan.model';
import { CarePlanType } from '../constants/care-plan-types';

export const PORTAL_MODALITY_IDENTIFIER_MANUAL = 'manual';

const SINGLE_SITE_MAX_VALUE = 1;

@Injectable()
export class HpHcpUserConfigService {

    private get userCarePlanConfig(): CarePlanModel {
      return this.getStoredCarePlan();
    }

    public constructor(
        private _hpHcpHttp: HpHcpHttp,
        private _localStorage: HpHcpLocalStorage,
        private _userSession: HpHcpUserSession
    ) {}

    get hasMultipleSite(): boolean {
      return (
        this._userSession.getUserSession().sites.length >
        SINGLE_SITE_MAX_VALUE
      );
    }

    public getCarePlan(onSuccess: (carePlan: CarePlanModel) => void) {
        return this.userCarePlanConfig
            ? onSuccess(this.userCarePlanConfig)
            : this.fetchCarePlan().subscribe(carePlan => {
                onSuccess(carePlan);
                this._userSession.setCarePlanSession(carePlan);
            });
    }

    public isBariatricsPlan(): Observable<boolean> {
        const storedCarePlan = this.getStoredCarePlan();
        if (storedCarePlan) {
            return of(storedCarePlan.type === CarePlanType.BARIATRICS);
        }

        return this._hpHcpHttp
            .get(this._hpHcpHttp.urls.config.carePlans)
            .pipe(
                catchError(() => of({ carePlanConfigurations: [] })),
                map(({ carePlanConfigurations }: any) => {
                    const [ firstCarePlan ] = carePlanConfigurations;
                    this._userSession.setCarePlanSession(firstCarePlan);
                    return firstCarePlan
                        ? firstCarePlan.type === CarePlanType.BARIATRICS
                        : false;
                })
            );
    }

    public getStoredCarePlan(): CarePlanModel | undefined {
        const userCarePlanConfig = this._localStorage.getItem(STORAGE_NAMES.userCarePlanConfig);

        return userCarePlanConfig && userCarePlanConfig !== 'undefined'
            ? JSON.parse(userCarePlanConfig)
            : undefined;
    }

    public fetchCarePlan(): Observable<CarePlanModel> {
        return this._hpHcpHttp
            .get(this._hpHcpHttp.urls.config.carePlans)
            .pipe(
                map(({ carePlanConfigurations }: any) => {
                    const [carePlan] = carePlanConfigurations;
                    return carePlan;
                })
            );
    }
}
