import { PatientModel } from '../model/patient.model';
import { SurgeryPhase } from '../constants/surgery-phase';
import { PatientTrackerStatusModel } from '../model/patient-tracker-status.model';

const CONNECTED_STATUS = 'Connected';
const DISCONNECTED_STATUS = 'Disconnected by Patient';
const NOT_CONNECTED_MESSAGE = 'Patient is not connected to the portal.';
const NAUSEA_MESSAGE = 'Nausea is only tracked during the post-surgery phase.';
const TRACKER_NOT_CONNECTED_MESSAGE = `Patient is not connected to a tracking app. Remind patient to connect to one to
help them track daily habits. This feature is in the Settings section of the app.`;
const TRACKER_NO_DATA_MESSAGE = 'Patient is connected to a tracking app, but there is no data to display for this chart';

export class HpHcpChartsInfoMessagesService {

    public getConectionMessage(patient: PatientModel): string {
        if (patient.connectStatus !== CONNECTED_STATUS &&
            patient.connectStatus !== DISCONNECTED_STATUS) {
            return NOT_CONNECTED_MESSAGE;
        }
        return '';
    }

    public getNauseaMessage(patient: PatientModel): string {
        let message = this.getConectionMessage(patient);

        if (!message && (patient.phase !== SurgeryPhase.POST_SURGERY)) {
            return NAUSEA_MESSAGE;
        }
        return message;
    }

    public getTrackerMessage(trackerStatus: PatientTrackerStatusModel, hasData: boolean): string {
        if (hasData) {
            return '';
        }
        if (!trackerStatus.connected) {
            return TRACKER_NOT_CONNECTED_MESSAGE;
        }

        return TRACKER_NO_DATA_MESSAGE;
    }
}
