import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

export const I18N_VALUES = {
  "US": {
    weekdays: ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"],
    months: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ],
    dateFormat: "MM/DD/YYYY",
    split: (value: string): NgbDateStruct => {
      if (value) {
        const dateParts = value.trim().split('/');
        return {
          day: parseInt(`${dateParts[1]}`, 10),
          month: parseInt(`${dateParts[0]}`, 10),
          year: parseInt(`${dateParts[2]}`, 10)
        };
      }
      return null;
    },
    formatLocaleDate: (date: NgbDateStruct): string => {
      const padNumber = (value: number) => {
        if (Number.isInteger(value)) {
          return `0${value}`.slice(-2);
        } else {
          return '';
        }
      }
      return date && (`${padNumber(date.month)}/${padNumber(date.day)}/${date.year}`) || '';
    }
  },
  de: {
    weekdays: ["Mo.", "Di.", "Mi.", "Do.", "Fr.", "Sa.", "So."],
    months: [
      "Jan",
      "Feb",
      "März",
      "Apr",
      "Mai",
      "Juni",
      "Juli",
      "Aug",
      "Sep",
      "Okt",
      "Nov",
      "Dez"
    ],
    dateFormat: "DD/MM/YYYY",
    split: (value: string): NgbDateStruct => {
      if (value) {
        const dateParts = value.trim().split('/');
        return {
          day: parseInt(`${dateParts[0]}`, 10),
          month: parseInt(`${dateParts[1]}`, 10),
          year: parseInt(`${dateParts[2]}`, 10)
        };
      }
      return null;
    },
    formatLocaleDate: (date: NgbDateStruct): string => {
      const padNumber = (value: number) => {
        if (Number.isInteger(value)) {
          return `0${value}`.slice(-2);
        } else {
          return '';
        }
      }
      return date && (`${padNumber(date.day)}/${padNumber(date.month)}/${date.year}`) || '';
    }
  },
  "UK": {
    weekdays: ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"],
    months: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ],
    dateFormat: "DD/MM/YYYY",
    split: (value: string): NgbDateStruct => {
      if (value) {
        const dateParts = value.trim().split('/');
        return {
          day: parseInt(`${dateParts[0]}`, 10),
          month: parseInt(`${dateParts[1]}`, 10),
          year: parseInt(`${dateParts[2]}`, 10)
        };
      }
      return null;
    },
    formatLocaleDate: (date: NgbDateStruct): string => {
      const padNumber = (value: number) => {
        if (Number.isInteger(value)) {
          return `0${value}`.slice(-2);
        } else {
          return '';
        }
      }
      return date && (`${padNumber(date.day)}/${padNumber(date.month)}/${date.year}`) || '';
    }
  },
  "AU": {
    weekdays: ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"],
    months: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ],
    dateFormat: "DD/MM/YYYY",
    split: (value: string): NgbDateStruct => {
      if (value) {
        const dateParts = value.trim().split('/');
        return {
          day: parseInt(`${dateParts[0]}`, 10),
          month: parseInt(`${dateParts[1]}`, 10),
          year: parseInt(`${dateParts[2]}`, 10)
        };
      }
      return null;
    },
    formatLocaleDate: (date: NgbDateStruct): string => {
      const padNumber = (value: number) => {
        if (Number.isInteger(value)) {
          return `0${value}`.slice(-2);
        } else {
          return '';
        }
      }
      return date && (`${padNumber(date.day)}/${padNumber(date.month)}/${date.year}`) || '';
    }
  },
  "NZ": {
    weekdays: ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"],
    months: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ],
    dateFormat: "DD/MM/YYYY",
    split: (value: string): NgbDateStruct => {
      if (value) {
        const dateParts = value.trim().split('/');
        return {
          day: parseInt(`${dateParts[0]}`, 10),
          month: parseInt(`${dateParts[1]}`, 10),
          year: parseInt(`${dateParts[2]}`, 10)
        };
      }
      return null;
    },
    formatLocaleDate: (date: NgbDateStruct): string => {
      const padNumber = (value: number) => {
        if (Number.isInteger(value)) {
          return `0${value}`.slice(-2);
        } else {
          return '';
        }
      }
      return date && (`${padNumber(date.day)}/${padNumber(date.month)}/${date.year}`) || '';
    }
  }
  // other languages you would support
};

