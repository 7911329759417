<a *ngIf="!item.locked; else lockedMode"
    class="list-item-action"
    data-category-id="text"
    data-action-id="click"
    [attr.data-dom-id]="item.completed ? 'mark_as_incomplete' : 'mark_as_complete'"
    (click)="toggleCompleted()">
    Mark as {{ item.completed ? 'Incomplete' : 'Complete' }}
</a>

<ng-template #lockedMode>
    <hp-hcp-information-icon [useNgContent]="true"
                            title="Completion status of this action item can only be changed through the EHR system.">
        <a class="list-item-action locked">
            Mark as {{ item.completed ? 'Incomplete' : 'Complete' }}
            <i class=" icon fa fa-lock"></i>
        </a>
    </hp-hcp-information-icon>
</ng-template>
