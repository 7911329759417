import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'domIdPrefixedIndex'})
export class HcpDomIdPrefixedIndex implements PipeTransform {

    public transform(value: string, index: number): string {
        const nonZeroBaseIndex = index + 1;
        return `${value}_${nonZeroBaseIndex}`;
    }

}
