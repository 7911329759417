import { Injectable } from '@angular/core';
import { HpHcpHttp } from '../../shared/service/hp-hcp-http.service';

@Injectable()
export class TkrCancelSurgeryService {

    constructor (
        private _hpHcpHttp: HpHcpHttp
    ) {}

    public cancelPatientSurgery(userId, success, failure) {
        return this._hpHcpHttp.put(this._hpHcpHttp.urls.patient.cancelSurgery, { urlParam: userId }).subscribe(success, failure);
    }
}
