/**
 * Copyright: Copyright © 2023
 * This file contains trade secrets of Johnson & Johnson. No part may be reproduced or transmitted in any
 * form by any means or for any purpose without the express written permission of Johnson & Johnson.
 */
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { NgbDatepickerModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

import { ButtonComponent } from './ui/button/button.component';
import { CheckboxComponent } from './ui/checkbox/checkbox.component';
import { CollapsibleContainerComponent } from './ui/collapsible-container/collapsible-container.component';
import { ConnectKeyPrintComponent } from './ui/connect-key-print/connect-key-print.component';
import { DatePickerComponent } from './ui/date-picker/date-picker.component';
import { DismissCommentComponent } from './ui/notification/dismiss-comment/dismiss-comment.component';
import { DismissNotificationComponent } from './ui/notification/dismiss-notification/dismiss-notification.component';
import { DropDownComponent } from './ui/dropdown/dropdown.component';
import { EmptyNotificationComponent } from './ui/notification/empty-notification/empty-notification.component';
import { EmptyStateColumnComponent } from './ui/empty-state-column/empty-state-column.component';
import { FooterComponent } from './ui/footer/footer.component';
import { FooterWarningDataLossModalComponent } from './ui/footer/warning-data-loss-modal/warning-data-loss-modal.component';
import { HeaderComponent } from './ui/header/header.component';
import { ExpiredSubscriptionModalComponent } from  './ui/header/subscription-expired-modal/subscription-expired-modal';
import { HealthyBehaviorsInfoIconComponent } from './ui/healthy-behaviors-info-icon/healthy-behaviors-info-icon.component';
import { InformationIconComponent } from './ui/information-icon/information-icon.component';
import { InputTextComponent } from './ui/input-text/input-text.component';
import { MultiSelectComponent } from './ui/multi-select/multi-select-component';
import { NotificationComponent } from './ui/notification/notification.component';
import { PaginationControlsComponent } from './ui/pagination-controls/pagination-controls.component';
import { PatientPhotoComponent } from './ui/patient-photo/patient-photo.component';
import { PatientSurgeryItemComponent } from './ui/patient-surgery-item/patient-surgery-item.component';
import { ProgressSpinnerComponent } from './ui/progress-spinner/progress-spinner.component';
import { ProgressbarCircularComponent } from './ui/progressbar-circular/progressbar-circular.component';
import { ProgressbarCircularTitleComponent } from './ui/progressbar-circular-title/progressbar-circular-title.component';
import { ProgressbarVerticalComponent } from './ui/progressbar-vertical/progressbar-vertical.component';
import { SelectComponent } from './ui/select/select.component';
import { SliderComponent } from './ui/slider/slider.component';
import { SurgeryDateChangedIconComponent } from './ui/surgery-date-changed-icon/surgery-date-changed-icon.component';
import { ToggleMenuComponent } from './ui/toggle-menu/toggle-menu-component';
import { UrgencyBarsComponent } from './ui/urgency-bars/urgency-bars.component';
import { WarningDataLossModalComponent } from './ui/header/warning-data-loss-modal/warning-data-loss-modal.component';
import { OneMinuteDelayModalComponent } from './ui/one-minute-delay-modal/one-minute-delay-modal.component';


import { DirectiveModule } from './directive.module';
import { PipeModule } from './pipe.module';
import { ServiceModule } from './service.module';
import { MarkableListItemComponent } from './ui/markable-list-item/markable-list-item.component';

// Angular Material
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';
import { TranslateModule } from '@ngx-translate/core';
import { PatientListService } from '../dashboard/service/patient-list.service';
import { SubNavigationComponent } from './ui/sub-navigation/sub-navigation.component';
/*
 * @author      @version    @date           @description
 * EElangav     01          Apr 11, 2023    AFLL-15217 Report Including Total VRAS Patients Count
 * ZDaudy       02          May 25, 2023    AFLL-17602 - RTM | Decouple the Global Navigation and sub-navigation for 'Reports'
 * PPareek      03          Jul 18, 2024    AFLL-21762 | Changes done to upgrade to Angular v 18
 */
@NgModule({
    exports: [
        ButtonComponent,
        CheckboxComponent,
        CollapsibleContainerComponent,
        ConnectKeyPrintComponent,
        DatePickerComponent,
        DismissNotificationComponent,
        DropDownComponent,
        EmptyNotificationComponent,
        EmptyStateColumnComponent,
        FooterWarningDataLossModalComponent,
        FooterComponent,
        HeaderComponent,
        ExpiredSubscriptionModalComponent,
        HealthyBehaviorsInfoIconComponent,
        InformationIconComponent,
        InputTextComponent,
        MarkableListItemComponent,
        MultiSelectComponent,
        NotificationComponent,
        PaginationControlsComponent,
        PatientPhotoComponent,
        PatientSurgeryItemComponent,
        ProgressSpinnerComponent,
        ProgressbarCircularComponent,
        ProgressbarCircularTitleComponent,
        ProgressbarVerticalComponent,
        SelectComponent,
        SliderComponent,
        SurgeryDateChangedIconComponent,
        ToggleMenuComponent,
        UrgencyBarsComponent,
        WarningDataLossModalComponent,
        OneMinuteDelayModalComponent,
        TranslateModule,
        SubNavigationComponent
    ],
    declarations: [
        ButtonComponent,
        CheckboxComponent,
        CollapsibleContainerComponent,
        ConnectKeyPrintComponent,
        DatePickerComponent,
        DismissCommentComponent,
        DismissNotificationComponent,
        DropDownComponent,
        EmptyNotificationComponent,
        EmptyStateColumnComponent,
        FooterWarningDataLossModalComponent,
        FooterComponent,
        HeaderComponent,
        ExpiredSubscriptionModalComponent,
        HealthyBehaviorsInfoIconComponent,
        InputTextComponent,
        InformationIconComponent,
        MarkableListItemComponent,
        MultiSelectComponent,
        NotificationComponent,
        PaginationControlsComponent,
        PatientPhotoComponent,
        PatientSurgeryItemComponent,
        ProgressSpinnerComponent,
        ProgressbarCircularComponent,
        ProgressbarCircularTitleComponent,
        ProgressbarVerticalComponent,
        SelectComponent,
        SliderComponent,
        SurgeryDateChangedIconComponent,
        ToggleMenuComponent,
        UrgencyBarsComponent,
        WarningDataLossModalComponent,
        OneMinuteDelayModalComponent,
        SubNavigationComponent
    ],
    imports: [
        MatToolbarModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatDividerModule,
        MatDialogModule,
        MatCardModule,
        CommonModule,
        DirectiveModule,
        FormsModule,
        PipeModule,
        ReactiveFormsModule,
        RouterModule,
        ServiceModule,
        NgbDatepickerModule,
        NgbDropdownModule,
        TooltipModule.forRoot(),
        TypeaheadModule.forRoot(),
        TranslateModule
    ],
    providers: [
        PatientListService,
        provideHttpClient(withInterceptorsFromDi())
    ]
})
export class UiModule {}