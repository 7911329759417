<div class="hp-hcp-activate-account">

    <div class="container user-form" *ngIf="!(submitted || isSubmitting)">
        <form [formGroup]="form">
            <h3 class="title">
                Welcome to the Health Partner Care Portal
            </h3>
            <p class="password-information">
                Please create a password below, to activate your Health Partner Care Portal Account.
                <br/> <br/>
                Your password must be at least 8 characters long, with one uppercase and one numeric character one numeric or special character. <br>
                You can’t reuse your past 5 passwords or any password created in the past 365 days.<br>
                Your password can only be changed once per day.
            </p>

            <span *ngIf="shouldShowError && errors.newPasswordError" class="error-message">
                {{ errors.newPasswordError }}
            </span>
            <div class="form-input-group">
                <input class="form-input"
                       data-dom-id="account_activation_input_password"
                       type="password"
                       placeholder="Create password*"
                       formControlName="password"
                       [class.error-input]="shouldShowError && errors.newPasswordError" />

                <i class="fa icon fa-check-circle check-pass" *ngIf="shouldShowError && !errors.newPasswordError"></i>
                <i class="fa icon fa-exclamation-circle check-fail" *ngIf="shouldShowError && errors.newPasswordError"></i>
            </div>

            <span class="error-message"
                  *ngIf="shouldShowError && errors.confirmPasswordError">
                Password does not match what you entered above. Please try again.
            </span>
            <div class="form-input-group">
                <input class="form-input"
                       data-dom-id="account_activation_input_confirm_password"
                       type="password"
                       placeholder="Confirm password*"
                       formControlName="confirmedPassword"
                       [class.error-input]="shouldShowError && errors.confirmPasswordError" />

                <i class="fa icon fa-check-circle check-pass"
                   *ngIf="shouldShowError && !(errors.confirmPasswordError || errors.newPasswordError)"></i>
                <i class="fa icon fa-exclamation-circle check-fail"
                   *ngIf="shouldShowError && errors.confirmPasswordError"></i>
            </div>

            <div class="form-buttons">
                <button type="button"
                        data-dom-id="account_activation_button_cancel"
                        class="button cancel-button"
                        [class.disabled]="isSubmitting"
                        (click)="goToLogin($event)">
                    CANCEL
                </button>
                <button type="button"
                        data-dom-id="account_activation_button_submit"
                        class="button submit-button"
                        [class.disabled]="isSubmitting"
                        (click)="activateUserAccount($event)">
                    SUBMIT
                </button>
            </div>
        </form>
    </div>

    <div class="container password-changed" *ngIf="submitted && !isSubmitting">
        <h3 class="title">
            Account Activated Successfully!
        </h3>
        <p class="password-information">
            Your account is now active!
            <br />
            Please return to the Health Partner Care Portal to log in.
        </p>

        <button type="button" class="button submit-button return-button"
                (click)="goToLogin($event)">
            LOG IN
        </button>
    </div>

    <div class="container password-changed" *ngIf="responseError">
        <h3 class="title">
            Activation Error!
        </h3>
        <p class="error">{{responseError}}</p>
    </div>
</div>
