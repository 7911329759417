import { Component, Input } from '@angular/core';

@Component({
    selector: 'hp-hcp-collapsible-container',
    templateUrl: './collapsible-container.html',
    styleUrls: ['./collapsible-container.scss']
})
export class CollapsibleContainerComponent {

    @Input() public editLink = '';
    @Input() public extraRightSpace = false;
    @Input() public title: string;
    @Input() public collapsed = false;

    public formattedTitle: string;

    public ngOnInit() {
        this.formattedTitle = this.formatTitleAsLowercaseUnderscore();
    }

    public toggleCollapsed(): void {
        this.collapsed = !this.collapsed;
    }

    private formatTitleAsLowercaseUnderscore(): string {
        return this.title.replace(/[\s|-]/g, '_').toLowerCase();
    }
}
