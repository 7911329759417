<div class="hp-hcp-select-menu-wrapper">
    <div
        ngbDropdown
        #multiSelect="ngbDropdown"
        autoClose="outside"
        class="dropdown d-inline-block"
        (openChange)="isOpen = $event; isOpen && updateChecked()"
    >
        <button
            [attr.data-dom-id]="isOpen ? 'up_arrow' : 'down_arrow'"
            class="btn btn-link title"
            data-category-id="icon"
            ngbDropdownToggle
        >
            {{ menuTitle }}
            <span
                [attr.data-dom-id]="isOpen ? 'up_arrow' : 'down_arrow'"
                class="toggle-icon fa"
                data-category-id="icon"
                [ngClass]="{'fa-chevron-up': isOpen, 'fa-chevron-down': !isOpen}">
            </span>
        </button>
        <div ngbDropdownMenu class="menu">
            <ng-container *ngIf="sortedOptions?.length; else loading">
                <ul toggleMenu class="list options">
                    <li
                        class="item"
                        *ngFor="
                            let option of sortedOptions;
                            let selectIndex = index
                        "
                    >
                    <input id="chk-{{selectIndex}}"
                           attr.data-dom-id="{{domId}}_{{selectIndex + 1}}"
                           class="custom-input custom-input-gray"
                           data-category-id="check_box"
                           type="checkbox"
                           [checked]="isChecked[selectIndex]"
                           (change)="isChecked[selectIndex] = !isChecked[selectIndex]"
                    />
                        <label class="checkbox-wrapper" for="chk-{{selectIndex}}">
                            {{ option.name }}
                        </label>
                    </li>
                </ul>
                <ul class="footer list actions">
                    <li class="action item flex-1">
                        <button
                            class="btn-link"
                            (click)="uncheckAll()"
                            data-category-id="button"
                            data-dom-id="clear_all">
                            Clear All
                        </button>
                    </li>
                    <li class="action item flex-1">
                        <button
                            class="btn-link"
                            (click)="submitAllSelection(); multiSelect.close()"
                            data-category-id="button"
                            data-dom-id="apply"
                        >
                            Apply
                        </button>
                    </li>
                </ul>
            </ng-container>
            <ng-template #loading>
                <div class="loading-wrapper">Loading...</div>
            </ng-template>
        </div>
    </div>
</div>
