import { Injectable } from '@angular/core';
import { HpHcpHttp } from './hp-hcp-http.service';

@Injectable()
export class TkrSiteLocation {

    constructor (
        private _hpHcpHttp: HpHcpHttp
    ) {}

    public getSiteLocations(siteId, success, failure) {
        this._hpHcpHttp.get(this._hpHcpHttp.urls.site.location,{ urlParam: siteId }).subscribe(success, failure);
    }
}