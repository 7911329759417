import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';

import isEmpty from 'lodash/isEmpty';

const BACKSPACE = 8;

@Directive({
    selector: '[inputTextFormat]'
})
export class InputTextFormatDirective implements OnInit {
    @Input() public excludePlaceHolderIfEmpty = false;
    @Input() public inputTextFormatValidatorPattern;
    @Input() public inputTextFormatAllowedChar;
    @Input() public inputTextFormat: string;
    @Input() public inputTextFormatPlaceholder: string;

    constructor(
        private _control: NgControl,
        private _elementRef: ElementRef,
        private _model: NgControl
    ) { }

    public ngOnInit() {
        this._updateControl();
    }

    @HostListener('keypress', ['$event']) public keyEvent(event: KeyboardEvent) {
        const keyCode = event.keyCode;
        const key = event.key || String.fromCharCode(keyCode);

        if (keyCode !== BACKSPACE) {
            let rawNumber: string = this._getTextWithoutFormating(this._elementRef.nativeElement.value).concat(key);
            if (this._isMaxChar(rawNumber) || !this._isValidInput(rawNumber)) {
                return false;
            }
        }
    }

    @HostListener('input', ['$event'])
    public onEvent(event) {
        this._updateControl();
    }

    private _isMaxChar(rawNumber): boolean {
        let textMaxLength: number = this.inputTextFormat.replace(RegExp(`[^${this.inputTextFormatPlaceholder}]`, 'g'), '').length;
        return rawNumber.length > textMaxLength;
    }

    private _isValidInput(rawNumber): boolean {
        return this.inputTextFormatValidatorPattern.test(rawNumber);
    }

    private _updateControl() {
        let rawNumber = this._getTextWithoutFormating(
            this._control.control
                ? this._control.control.value
                : this._elementRef.nativeElement.value
        );

        let formattedNumber = this.excludePlaceHolderIfEmpty && isEmpty(rawNumber)
            ? ''
            : this._getFormatedText(this.inputTextFormat, this.inputTextFormatPlaceholder, rawNumber);

        let caretPos = this._getLastDigitPosition(formattedNumber, rawNumber) + 1;

        this._model.viewToModelUpdate(formattedNumber);
        this._model.valueAccessor.writeValue(formattedNumber);

        this._elementRef.nativeElement.selectionStart = caretPos;
        this._elementRef.nativeElement.selectionEnd = caretPos;
    }

    private _getFormatedText(formatString: string, digitPlaceholder: string, rawNumber: string): string {
        const digits = rawNumber.split('');
        let digitCounter = -1;

        const replacer = (match, p1) => {
            digitCounter += p1 ? 1 : 0;
            return p1 && digits[digitCounter] ? digits[digitCounter] : match;
        };

        const regx = new RegExp(`(${digitPlaceholder})`, 'g');
        return formatString.replace(regx, replacer);
    }

    private _getTextWithoutFormating(formatedNumber: string): string {
        return formatedNumber.replace(this.inputTextFormatAllowedChar, '');
    }

    private _getLastDigitPosition(formatedNumber: string, rawNumber: string): number {
        return rawNumber.length > 0 ? formatedNumber.lastIndexOf(rawNumber[rawNumber.length - 1]) : -1;
    }

}
