import { Injectable } from '@angular/core';
import { HpHcpHttp } from './hp-hcp-http.service';
import { StaffUserModel, ManageUsersModel } from '../model/manage-users.model';
import { Observable } from 'rxjs';

@Injectable()
export class HpHcpUsersService {

    constructor (
        private _hpHcpHttp: HpHcpHttp
    ) { }

    public getUsers(): Observable<ManageUsersModel> {
        return this._hpHcpHttp.get(this._hpHcpHttp.urls.users.list);
    }

    public resendPassword(userId) {
        return this._hpHcpHttp.get(this._hpHcpHttp.urls.users.resendPassword, { urlParam: userId });
    }

    public getRoles () {
        return this._hpHcpHttp.get(this._hpHcpHttp.urls.users.roles);
    }

    public getSites () {
        return this._hpHcpHttp.get(this._hpHcpHttp.urls.users.sites);
    }

    public saveMultiOrgSelection(requestBody) {
        return this._hpHcpHttp.post(
            this._hpHcpHttp.urls.organization.mutiOrgSelection,
            { requestBody }
        );
    }

    public getUser (userId): Observable<StaffUserModel> {
        return this._hpHcpHttp.get(this._hpHcpHttp.urls.users.user, { urlParam: userId });
    }

    public saveUser (requestBody): Observable<any> {
        return this._hpHcpHttp.post(this._hpHcpHttp.urls.users.save, { requestBody });
    }

    public editUser (userId, requestBody): Observable<any> {
        return this._hpHcpHttp.put(this._hpHcpHttp.urls.users.edit, { urlParam: userId, requestBody });
    }

    public reinviteUser (userId): Observable<any> {
        return this._hpHcpHttp.post(this._hpHcpHttp.urls.users.reinvite, { urlParam: userId});
    }

    public setActivation (urlParam, requestBody): Observable<any> {
        return this._hpHcpHttp.post(this._hpHcpHttp.urls.users.setActivation, { urlParam, requestBody });
    }
}



