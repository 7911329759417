<div class="message-dialog">
    <div class="velys-logo-row text-center">
        <img class="velys-logo" src="../../../assets/img/velys-logo-signin.png" alt="velys Logo signin" />
    </div>
    <div class="update-confirmation">
        <h1 class="title">Are you still there?</h1>
        <div class="success-message">
            If not, we'll close this session in <span class="time-left">{{timeleft}}</span> minutes.
        </div>
    </div>
    <div class="form-buttons text-center">
        <button type="button" class="button" (click)="stayOnPage()">
            I'm here
        </button>
        <button type="button" class="detail-button" (click)="logoutUser()">
            Logout
        </button>
    </div>

</div>
