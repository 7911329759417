import reduce from 'lodash/reduce';
import moment from 'moment';
import { environment } from '../../../environments/environment';
import { DateTimeFormatUtility } from './date-time-format-utility';

const ISO_DATE_REGEX = /\d{4}-[01]\d-[0-3]\d([T ][0-2]\d:[0-5]\d:[0-5]\d((\.\d+)?[+-][0-2]\d:[0-5]\d|Z)?)?/;
const MOMENT_LOCALE = 'en';

/*
 *  @author     @version    @date           @description
 *  HSenevir    01          Apr 01, 2023    AFLL-16880 - Supporting Angular CLI build and refactoring
 */
export const DATE_FORMATS = {
    bigEndianDate: 'YYYY-MM-DD',
    middleEndianDate: 'MMM DD, YYYY',
    timestamp: 'timestamp',
    romVasStepHoverDateFormat: 'yyyy-MM-dd',
    datePickerFormat: 'mmddyyyy',
    defaultDateFormat: 'MM/DD/YYYY',
    regionDateFormat: 'MM/DD/YYYY'
};

export const TIME_FORMATS = {
    shortTime: 'h:mm a',
    shortTimeExtended: 'hh:mm a',
    longTimeExtended: 'hh:mm:ss'
};

export type AnyValidDateArg = Date | string | number | number[];

export class DateUtility {

    public static concatDateTime(date: string, time: string): string {
        return `${this.formatDate(date)}, ${this.formatTime(time)}`;
    }

    public static createLocaleDate(date?: string) {
        moment.locale(MOMENT_LOCALE);
        return date ? moment(date) : moment();
    }

    public static dateToISOString(date: Date): string {
        return date.toISOString();
    }

    public static daysBetween(startDate: string, endDate: string, datesFormat: string = DATE_FORMATS.bigEndianDate): number {
        return (startDate && endDate)
            ? moment(endDate, datesFormat).diff(moment(startDate, datesFormat), 'days')
            : NaN;
    }

    /**
     * Check if the date input falls AFTER today.
     */
    public static isAfterToday(date: number) {
        let today = moment();
        return moment(date).isAfter(today, 'day');
    }

    public static isAfter(referenceDate: string, subjectDate: string) {
        if (!(referenceDate && subjectDate)) {
            return undefined;
        }
        moment.locale(MOMENT_LOCALE);
        return moment(subjectDate).isAfter(referenceDate);
    }

    public static isSameOrAfter(referenceDate: string, subjectDate: string) {
        if (!(referenceDate && subjectDate)) {
            return undefined;
        }
        moment.locale(MOMENT_LOCALE);
        return moment(subjectDate).isSameOrAfter(referenceDate);
    }

    /**
     * Check if the date input falls BEFORE today.
     */
    public static isBeforeToday(date: number) {
        let today = moment();
        return moment(date).isBefore(today, 'day');
    }

    /**
     * Check if the date input falls ON or BEFORE today.
     */
    public static isDateSameAndBeforeToday(date: number, isUTC = false) {
        let today = isUTC ? moment.utc(moment().format('YYYY-MM-DD')) : moment();
        if (isUTC) {
            return moment.utc(date).isSameOrBefore(today, 'day');
        } else {
            return moment(date).isSameOrBefore(today, 'day');
        }
    }

    public static calculateFutureDate(days: number) {
        let today = moment().add(days, 'days');
        return moment(today).format('MM/DD/YYYY');
    }

    /**
     * Check if the date input falls on the same day as today
     */
    public static isToday(timestamp: number) {
        const today = new Date();
        const date = new Date(timestamp);
        return DateUtility.sameDate(today, date);
    }

    public static sameDate(date: Date, other: Date): boolean {
        return date.getFullYear() === other.getFullYear() &&
            date.getMonth() === other.getMonth() &&
            date.getDate() === other.getDate();
    }

    public static fromNow(date: string): string {
        if (!date) {
            return undefined;
        }

        let daysFromNow = '';
        const noDaysOfDifference = 0;
        moment.locale(MOMENT_LOCALE);
        if (moment().diff(moment(date)) < noDaysOfDifference) {
            daysFromNow = moment().fromNow();
        } else {
            daysFromNow = moment(date).fromNow();
        }

        return this._camelize(daysFromNow);
    }


    /**
     * Method to add N number of (days, months, weeks, etc) to a milliseconds timestamp
     * @param date: milliseconds JS timestamp
     * @param toAdd: number (accepts decimals)
     * @param type: any
     * 'ms' or 'milliseconds'
     * 's' or 'seconds'
     * 'm' or 'minutes'
     * 'h' or 'hours'
     * 'd' or 'days',
     * 'M' or 'months'
     * 'w' or 'weeks'
     * 'Q' or 'quarters'
     * 'y' or 'years'
     * @returns milliseconds timestamp of new date stamp
     */
    public static getFutureDate(date: number, toAdd: number, type: any): any {
        if (!date || !toAdd) {
            return undefined;
        }

        let futureDate = moment(date).add(toAdd, type);

        return futureDate.valueOf();
    }

    public static currentDate(format: string = DATE_FORMATS.defaultDateFormat) {
        moment.locale(MOMENT_LOCALE);
        return moment().format(format);
    }

    public static dateFromDayInterval(
        days: string | number,
        initialDate: any = undefined,
        returnDateFormat: string = DATE_FORMATS.defaultDateFormat
    ) {
        const intervalDays = +days;
        if (!Number.isInteger(intervalDays) || Number.isNaN(intervalDays) || Array.isArray(days) || days === null) {
            return '';
        }
        moment.locale(MOMENT_LOCALE);
        const format = 'string' === typeof initialDate ? DATE_FORMATS.defaultDateFormat : null;
        return moment(initialDate, format).add(intervalDays, 'days').format(returnDateFormat);
    }

    public static today() {
        return DateUtility.removeHoursFromDay(new Date());
    }

    public static formatDate(
        date: any,
        returnDateFormat: string = DATE_FORMATS.middleEndianDate,
        format: string = DATE_FORMATS.bigEndianDate
    ): string {
        if (!date) {
            return undefined;
        }

        moment.locale(MOMENT_LOCALE);
        if (format === DATE_FORMATS.timestamp) {
            format = null;
        }
        return moment(date, format).utc().format(returnDateFormat);
    }

    public static formatLocalDate(
        date: any,
        returnDateFormat: string = DATE_FORMATS.middleEndianDate,
        format: string = DATE_FORMATS.bigEndianDate
    ): string {
        if (!date) {
            return undefined;
        }

        moment.locale(MOMENT_LOCALE);
        if (format === DATE_FORMATS.timestamp) {
            format = null;
        }
        return moment(date, format).format(returnDateFormat);
    }

    public static formatUTCDate(date: any, format: string = DATE_FORMATS.bigEndianDate,
        returnDateFormat: string = DATE_FORMATS.middleEndianDate): string {
        if (!date) {
            return undefined;
        }
        moment.locale(MOMENT_LOCALE);
        if (format === DATE_FORMATS.timestamp) {
            format = null;
        }
        return moment.utc(date, format).format(returnDateFormat);
    }

    public static parseLocalDate(date: moment.MomentInput, format: string = DATE_FORMATS.middleEndianDate): string {
        if (!date) {
            return undefined;
        }
        moment.locale(MOMENT_LOCALE);
        return moment.utc(date).local().format(format);
    }

    public static parseFixedDate(date: any, format: string = DATE_FORMATS.middleEndianDate): string {
        if (!date) {
            return '';
        }
        moment.locale(MOMENT_LOCALE);
        return moment.parseZone(date).format(format);
    }

    public static formatTime(time: string, format: string = TIME_FORMATS.shortTimeExtended): string {
        return time
            ? `${moment(time, 'HH:mm').format(format)}`
            : '';
    }

    public static formatTimeWithSeconds(time: string, format: string = TIME_FORMATS.longTimeExtended): string {
        return time
            ? `${moment(time, 'HH:mm:ss').format(format)}`
            : '';
    }

    /**
     * Convert a timestamp (unix format) to JS local time (in milliseconds)
     */
    public static convertUnixToLocal(date: number): number {
        if (!date) {
            return undefined;
        }

        return (date + new Date().getTimezoneOffset() * 60) * 1000;
    }

    public static stringToDateParts(dateString: string, dateFormat: string = DATE_FORMATS.bigEndianDate) {
        let dateParts = {
            year: 'YYYY',
            month: 'MM',
            day: 'DD'
        };

        return reduce(dateParts, (result, datePart, key) => {
            let pos = dateFormat.indexOf(datePart);
            if (pos !== -1) {
                let value = dateString.substring(pos, pos + datePart.length);
                result[key] = Number(value);
                return result;
            }
        }, {});
    }

    public static stringToUTC(string: string): number {
        let utc = +moment.utc(string);
        return utc;
    }

    /**
     * method to subtract N number of months to a milliseconds timestamp
     * @param date
     * @param toSubtract
     * @returns {number}
     */
    public static subtractMonths(date: number, toSubtract: number): string {
        if (!date || !toSubtract) {
            return undefined;
        }

        let returnValue: any = +moment(date).subtract(toSubtract, 'month').utc();
        return returnValue;
    }

    public static validateDate(date: string, format: string): boolean {
        return moment(date, format.toUpperCase(), true).isValid();
    }

    public static createDate(date: Readonly<AnyValidDateArg>): Date {
        if (date instanceof Array) {
            return new (Date as any)(...date);
        } else if (date instanceof Date) {
            return new Date(date.getTime());
        } else if (typeof (date) === 'string' && date.match(ISO_DATE_REGEX)) {

            const [year, month, day] = date.match(/(\d{4})-(\d{2})-(\d{2})/).slice(1);
            const timeMatch = date.match(/(\d{2}):(\d{2}):(\d{2})/);
            const [hours, minutes, seconds] = timeMatch ? timeMatch.slice(1) : [0, 0, 0];

            return new Date(+year, +month - 1, +day, +hours, +minutes, +seconds);
        }

        return new Date(date as any);
    }

    public static dateInRange(date: Readonly<Date>, { start, end }: { start: Readonly<Date>, end: Readonly<Date> }): boolean {
        const startTime = start.getTime();
        const endTime = end.getTime();

        const time = date.getTime();

        return time >= startTime && time <= endTime;
    }

    public static firstDayOfMonth(date: Readonly<AnyValidDateArg>, monthsToAdd = 0): Date {
        let outcome = DateUtility.incrementMonth(date, monthsToAdd);
        outcome.setDate(1);
        return outcome;
    }

    public static firstSecondOfMonth(date: Readonly<AnyValidDateArg>, monthsToAdd = 0): Date {
        return DateUtility.removeHoursFromDay(DateUtility.firstDayOfMonth(date, monthsToAdd));
    }

    public static incrementDay(date: Readonly<AnyValidDateArg>, daysToAdd = 1): Date {
        let outcome = DateUtility.createDate(date);
        outcome.setDate(outcome.getDate() + daysToAdd);
        return outcome;
    }

    public static incrementMonth(date: Readonly<AnyValidDateArg>, monthsToAdd = 1): Date {
        const outcome = DateUtility.createDate(date);
        let day = outcome.getDate();
        outcome.setDate(1);
        outcome.setMonth(outcome.getMonth() + monthsToAdd);
        day = Math.min(this._daysInMonth(outcome.getMonth(), outcome.getFullYear()), day);
        outcome.setDate(day);
        return outcome;
    }

    public static lastDayOfMonth(date: Readonly<AnyValidDateArg>, monthsToAdd = 0): Date {
        let outcome = DateUtility.firstDayOfMonth(date, monthsToAdd + 1);
        outcome.setDate(0);

        return outcome;
    }

    public static lastSecondOfMonth(date: Readonly<AnyValidDateArg>, monthsToAdd = 0): Date {
        let outcome = DateUtility.firstSecondOfMonth(date, monthsToAdd + 1);
        outcome.setMilliseconds(-1);

        return outcome;
    }

    public static resetDay(date: Readonly<AnyValidDateArg>): Date {
        return DateUtility.removeHoursFromDay(DateUtility.createDate(date));
    }

    private static removeHoursFromDay(day: Date): Date {
        day.setHours(0, 0, 0, 0);
        return day;
    }

    private static _camelize(dateString: string): string {
        return dateString
            .replace(/\s(.)/g, (firstLetter: string) => firstLetter.toUpperCase());
    }

    private static _daysInMonth(month: number, year: number): number {
        return 32 - new Date(year, month, 32).getDate();
    }

    public static setDateFormats() {
        let country = environment['country'] ? environment['country'].toLowerCase() : 'default';
        DATE_FORMATS['regionDateFormat'] = DateTimeFormatUtility[country]['dateFormat']['regionDateFormat'];
        DATE_FORMATS['middleEndianDate'] = DateTimeFormatUtility[country]['dateFormat']['middleEndianDate'];
        DATE_FORMATS['romVasStepHoverDateFormat'] = DateTimeFormatUtility[country]['dateFormat']['romVasStepHoverDateFormat'];
        DATE_FORMATS['datePickerFormat'] = DateTimeFormatUtility[country]['dateFormat']['datePickerFormat'];
        TIME_FORMATS['shortTime'] = DateTimeFormatUtility[country]['timeFormat']['shortTime'];
        TIME_FORMATS['shortTimeExtended'] = DateTimeFormatUtility[country]['timeFormat']['shortTimeExtended'];
    }

    public static getDefaultFormatDate(date: any, format: string = DATE_FORMATS.regionDateFormat, returnDateformat: string = DATE_FORMATS.defaultDateFormat) {
        moment.locale(MOMENT_LOCALE);
        if (this.isIncompleteDate(date)) {
            return date;
        }
        return moment(date, format).format(returnDateformat);
    }

    public static isIncompleteDate(date: any) {
        const dateString = date.replace(/D|M|Y|\//g, '');
        if (dateString.length < 8) {
            return true;
        }
    }

    public static getRegionDateFormat(date: any, format: string = DATE_FORMATS.defaultDateFormat, returnDateformat: string = DATE_FORMATS.regionDateFormat) {
        moment.locale(MOMENT_LOCALE);
        if (!moment(date, format, true).isValid()) {
            return date;
        }
        return moment(date, format).format(returnDateformat);
    }
}
