import { Injectable } from '@angular/core';

@Injectable()
export class HpHcpFileReader {

    public getFileReaderInstance() {
        return new FileReader();
    }

}
