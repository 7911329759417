<div>
    <div class="expired-subscription-modal-body">
        <span (click)="closeModal()" class="close-modal-button"> &#x2716; </span>
        <div class="modal-content-header">
            Confirm Navigation
        </div> 
        <div class="update-confirmation">
            
            <div class="message">
                Are you sure you want to navigate away from this page?
                <br/>
                You will lose any unsaved changes.
            </div>
        </div>
        <div class="form-buttons">
            <button type="button" class="secondary-modal-button" (click)="closeModal()">
                Stay on this page
            </button>
            <button type="button" class="main-modal-button" (click)="closeAndNavigateAway()">
                Leave this page
            </button>
        </div>
    </div>
</div>
