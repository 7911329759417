import { Injectable } from '@angular/core';
import { HpHcpHttp } from './hp-hcp-http.service';

/*
 *  @author     @version    @date           @description
 *  JVeerasa    01          Nov 17, 2023    AFLL-20214 | VDP | Docspera Non EHR | Use velys patient id for intake form API call 
 */

@Injectable()
export class TkrDsIntakeFormService {

    constructor (
        private _hpHcpHttp: HpHcpHttp
    ) {}

    public postIntakeForm(patientId, success, failure) {
        return this._hpHcpHttp.post(this._hpHcpHttp.urls.intakeForm.sendIntakeForm, { urlParam: patientId }).subscribe(success, failure);
    }

    public getIntakeFormStatus(docsperaPatientId, success, failure){
        return this._hpHcpHttp.get(this._hpHcpHttp.urls.intakeForm.intakeFormStatus, { urlParam: docsperaPatientId }).subscribe(success, failure);
    }
}
