import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { HpHcpHttp } from './hp-hcp-http.service';
import { SurgeonModel } from '../model/surgeon.model';

@Injectable()
export class HpHcpSurgeonService {
  constructor(private _hpHcpHttp: HpHcpHttp) {}

  public fetchSurgeonsBySite(
    selectedSites = []
  ): Observable<Array<SurgeonModel>> {
    const surgeons$: any = this._hpHcpHttp.get(
      this._hpHcpHttp.urls.surgeon.viewAll
    );

    if (!selectedSites.length) {
      return surgeons$;
    }

    return surgeons$.pipe(
      map((surgeons: Array<object>) => {
        const reducedList = surgeons.reduce((acc: any, surgeon: any) => {
          const sites = surgeon.sites.filter(
            ({ id }) => selectedSites.includes(String(id))
          );

          if (!sites.length) {
            return acc;
          }

          return [...acc, { ...surgeon }];
        }, []);
        return reducedList;
      })
    );
  }

  public createSurgeon(requestBody: SurgeonModel): Observable<any> {
    return this._hpHcpHttp.post(this._hpHcpHttp.urls.surgeon.save, { requestBody });
  }

  public updateSurgeon(urlParam: number, requestBody: SurgeonModel): Observable<any> {
    return this._hpHcpHttp.put(this._hpHcpHttp.urls.surgeon.edit, { urlParam, requestBody });
  }

  public deleteSurgeon(urlParam: number): Observable<any> {
    return this._hpHcpHttp.delete(this._hpHcpHttp.urls.surgeon.delete, { urlParam });
  }

  public fetchSurgeon(urlParam: number): Observable<any> {
    return this._hpHcpHttp.get(this._hpHcpHttp.urls.surgeon.view, { urlParam });
  }
}
