/**
 * Copyright: Copyright © 2024
 * This file contains trade secrets of Johnson & Johnson. No part may be reproduced or transmitted in any
 * form by any means or for any purpose without the express written permission of Johnson & Johnson.
 */
import {Injectable, Inject} from '@angular/core';
import {ReplaySubject} from 'rxjs';

import {HpHcpGAService} from './hp-hcp-ga.service';
import {HpHcpSessionSync} from './hp-hcp-session-sync.service';
import {HpHcpLocalStorage} from './hp-hcp-localstorage.service';
import {LegalAcceptModel} from '../model/legal-accept.model';
import {SessionTimeoutModel} from '../model/session-timeout.model';
import {UserModel} from '../model/user.model';

/*
 * @author      @version    @date           @description
 * HSenevir     01          Jul 17, 2023    AFLL-18875 - Removed sessionInterrupted tracking with same browser multiple tab tracking
 * EElangav     02          Feb 26, 2024    AFLL-20712 Added Localization of Customer Support contact details for ANZ
*/
@Injectable()
export class HpHcpUserSession {

    private readonly roles = {
        organizationAdmin: {
            machineName: 'ROLE_ORG_ADMIN'
        }
    };

    private _localStorage: HpHcpLocalStorage;
    private _storageNames: any;
    private _sessionPollingStopper: ReplaySubject<void>;
    constructor(
        private _hcpHcpGaService: HpHcpGAService,
        private _sessionSynchronizer: HpHcpSessionSync,
        hpHcpLocalStorage: HpHcpLocalStorage,
        @Inject('STORAGE_NAMES') storageNames: any
    ) {
        this._localStorage = hpHcpLocalStorage;
        this._storageNames = storageNames;
    }

    public clearSession() {
        this._localStorage.removeItem(this._storageNames.connectKey);
        this._localStorage.removeItem(this._storageNames.isRegistrationInProgress);
        this._localStorage.removeItem(this._storageNames.multiOrgSelected);
        this._localStorage.removeItem(this._storageNames.passwordExpirationDays);
        this._localStorage.removeItem(this._storageNames.patient);
        this._localStorage.removeItem(this._storageNames.sessionExpiration);
        this._localStorage.removeItem(this._storageNames.userCarePlanConfig);
        this._localStorage.removeItem(this._storageNames.userSession);
        this._localStorage.removeItem(this._storageNames.vrasMaxAttemptsRetry);

        this._sessionSynchronizer.userLogout();

        if (this._sessionPollingStopper) {
            this._sessionPollingStopper.next(undefined);
            this._sessionPollingStopper.complete();
        }
    }

    public getSessionExpiration(): SessionTimeoutModel {
        const sessionExpiration = this._localStorage.getItem(this._storageNames.sessionExpiration);
        return JSON.parse(sessionExpiration);
    }

    public getSessionPollingStopper(): ReplaySubject<void> {
        this._sessionPollingStopper = new ReplaySubject<void>(1);
        return this._sessionPollingStopper;
    }

    public getUserEmail(): string {
        const email = this._localStorage.getItem(this._storageNames.userEmail);
        this._localStorage.removeItem(this._storageNames.userEmail);

        return email;
    }

    public getUserSession(): UserModel {
        const userSession = this._localStorage.getItem(this._storageNames.userSession);
        return JSON.parse(userSession);
    }

    public setSessionExpiration(sessionLength) {
        const sessionExpiration: SessionTimeoutModel = {
            lastActivity: new Date(),
            sessionLengthMilliseconds: sessionLength * 1000,
            sessionLength: sessionLength,
            twoMinuteIdleEncountered: false,
            inactivityWindow: false
        };

        this._localStorage.setItem(this._storageNames.sessionExpiration, JSON.stringify(sessionExpiration));
    }

    public startGaSession(userData: UserModel) {
        this._hcpHcpGaService.setOrganizationDimension(userData);
    }

    public setUserSession(userData: UserModel) {
        this.setSessionExpiration(userData.sessionLength);
        this.setMultiOrgSelected(false);
        this._setUserEmail(userData.email);
        this._localStorage.setItem(this._storageNames.passwordExpirationDays, String(userData.passwordExpirationDays));
        this._localStorage.setItem(this._storageNames.userSession, JSON.stringify(userData));
    }

    public setUserLegalAcceptSession(legalAcceptData: LegalAcceptModel) {
        this._localStorage.setItem(this._storageNames.userLegalAcceptSession, JSON.stringify(legalAcceptData));
    }

    public getUserLegalAcceptSession(): LegalAcceptModel {
        const userLegalAcceptSession = this._localStorage.getItem(this._storageNames.userLegalAcceptSession);
        return JSON.parse(userLegalAcceptSession);
    }

    public removeUserLegalAcceptSession() {
        this._localStorage.removeItem(this._storageNames.userLegalAcceptSession);
    }

    public updateSessionExpirationActivity() {
        let sessionExpiration = this.getSessionExpiration();

        if (!sessionExpiration) {
            return;
        }

        const lastActivity = new Date();
        Object.assign(sessionExpiration, {lastActivity});

        this._localStorage.setItem(this._storageNames.sessionExpiration, JSON.stringify(sessionExpiration));
    }

    public isOrganizationalAdmin() {
        const {roles: currentUserRoles} = JSON.parse(this._localStorage.getItem(this._storageNames.userSession));
        return currentUserRoles &&
            currentUserRoles.some(userRole => userRole.machineName === this.roles.organizationAdmin.machineName);
    }

    public getOrganizations() {
        const {multiOrg = []} = JSON.parse(this._localStorage.getItem(this._storageNames.userSession));
        return multiOrg;
    }

    public getMultiOrgSelected(): boolean {
        return JSON.parse(this._localStorage.getItem(this._storageNames.multiOrgSelected));
    }

    public setMultiOrgSelected(multiOrgSelected: boolean) {
        this._localStorage.setItem(this._storageNames.multiOrgSelected, JSON.stringify(multiOrgSelected));
    }

    public isMultiOrgUser(): boolean {
        const organizations = this.getOrganizations();
        return Array.isArray(organizations) && organizations.length > 1;
    }

    public setCarePlanSession(carePlan) {
        this._localStorage.setItem(this._storageNames.userCarePlanConfig, JSON.stringify(carePlan));
    }

    private _setUserEmail(email: string) {
        this._localStorage.setItem(this._storageNames.userEmail, email);
    }
}
