import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'spacesToUnderscores'})
export class SpacesToUnderscoresPipe implements PipeTransform {

    public transform(value: string): any {
        if (!value) { return value; }

        return value.replace(/ /g , '_');

    }

}
