<hp-hcp-information-icon
    class="healthy-behaviors-info"
    data-dom-id="healthy_behaviors_info"
    [title]="healthyBehaviorsTooltip"
    type="wide"
    [tooltipPlacement]="tooltipPlacement">
</hp-hcp-information-icon>
<ng-template #healthyBehaviorsTooltip>
    This percentage reflects patient compliance towards their healthy behavior goals (calories, protein, water, and steps) over the past four days.
</ng-template>
