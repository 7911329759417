/**
 * Copyright: Copyright © 2023
 * This file contains trade secrets of Johnson & Johnson. No part may be reproduced or transmitted in any
 * form by any means or for any purpose without the express written permission of Johnson & Johnson.
 */
import { Injectable } from '@angular/core';
import { HpHcpHttp } from './hp-hcp-http.service';
/*
 * @author      @version    @date           @description
 * EElangav     01          Apr 27, 2023    AFLL-17635 - VPP Portal | Media File Detail HIPAA Audit Logging
 */
@Injectable()
export class PatientGenericMediaService {

    constructor(
        private _hpHcpHttp: HpHcpHttp
    ) { }

    public getFilteredGenericMediaList(patientId, fileType, fileSubType, success, failure) {
        this._hpHcpHttp.get(this._hpHcpHttp.urls.genericMedia.media + '?filter=' + fileType + ',' + fileSubType, { urlParam: patientId }).subscribe(success, failure);
    }

    public getGenericMediaList(patientId, success, failure) {
        this._hpHcpHttp.get(this._hpHcpHttp.urls.genericMedia.media, { urlParam: patientId }).subscribe(success, failure);
    }

    public getGenericMediaData(patientId, mediaId, fullImage: boolean, success, failure) {
        const requestBody = {
            userId: patientId
        };
        const queryParams = [
            { name: 'fullImage', value: fullImage }
        ]
        this._hpHcpHttp.post(this._hpHcpHttp.urls.genericMedia.mediaContent + '/' + mediaId + '/content', { requestBody, queryParams }).subscribe(success, failure);
    }

    public setGenericMediaHcpResponse(patientId, mediaId, hcpResponse, hcpNotes, success, failure) {
        // AFLL-12756 - replacing / with ++ to avoid WAF detecting as SQL injection
        let customEncodedHcpResponse = hcpResponse.replace('/', '++');
        const requestBody = {
            hcpResponse: customEncodedHcpResponse,
            notes: hcpNotes
        };
        this._hpHcpHttp.post(this._hpHcpHttp.urls.genericMedia.setHcpResponse + '/' + mediaId + '/hcpResponse', { urlParam: patientId, requestBody }).subscribe(success, failure);
    }
}
