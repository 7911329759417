import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { EmailPreference } from '../model/email-preferences.model';
import { HpHcpHttp } from './hp-hcp-http.service';

@Injectable()
export class HcpEmailPreferencesService {

    constructor (private _hpHcpHttp: HpHcpHttp) {
    }

    public getEmailPreferences(): Observable<EmailPreference[]> {
        return this._hpHcpHttp.get(this._hpHcpHttp.urls.user.emailPreferences);
    }

    public updateEmailPreferences(preferences: EmailPreference[]): Observable<any> {
        let filteredPrefs = [];
        preferences.forEach((pref) => {
            if (pref.selected) {
                filteredPrefs.push({id: pref.id});
            }
        });

        const pack = { requestBody: { emailPreferences: filteredPrefs } };

        return this._hpHcpHttp.put(this._hpHcpHttp.urls.user.emailPreferences, pack);
    }
}
