<div class="hp-hcp-notification">
    <div class="dismiss-box" *ngIf="isDismissBoxVisible" [class.right-side]="isDismissCommentRightSide">
        <hp-hcp-dismiss-comment
         [notificationId]="notificationId"
         [displayOnRightSide]="isDismissCommentRightSide"
         (onCloseBox)="toggleDismissCommentBox()"
         (onDismissWithComment)="dismissNotification($event)"></hp-hcp-dismiss-comment>
    </div>
    <div class="notification-item {{prioritySelector}}">
        <div class="head-section">
            <div class="notification-date-time">{{datetime | hcpDate: 'shortTime'}} {{datetime | hcpDate: 'mediumDate'}}</div>
            <div class="actions">
                <hp-hcp-dismiss-notification
                    [notificationId]="notificationId"
                    [notificationType]="prioritySelector"
                    [patientId]="patientId"
                    [patientSurgeryPhase]="patientSurgeryPhase"
                    (onDismissNotification)="dismissNotification($event)"
                    (onShowDismissComment)="toggleDismissCommentBox()">
                </hp-hcp-dismiss-notification>
            </div>
        </div>
        <div class="content-section">
            <div class="type-icon">
                <img src="../../../../assets/img/notification-icons/{{iconName}}.png">
            </div>
            <div class="content">{{message}}</div>
        </div>
        <div class="view-profile-section">
            <span data-dom-id="view_patient_details" class="patient-profile-link" *ngIf="hasPatientDetails" (click)="goToPatientProfile()">
                VIEW PATIENT DETAILS <i class="fa fa-play play-icon"></i>
            </span>
        </div>
    </div>
</div>
