<div class="hp-hcp-connect-key-print">
  <div><div class="print-icon" (click)="printContent()"></div></div>
  <div class="print-content">
    <p class="patient-name" translate>
      connectKeyPrint.patient_name <span>{{patientFirstName | translate}},</span>
    </p>
    <p translate>connectKeyPrint.connect_key_title</p>
    <p><strong translate> connectKeyPrint.connect_key<span class="print-connect-code">{{connectCode | translate}}</span> </strong></p>
    <p translate>connectKeyPrint.connect_msg_01</p>
    <p translate>connectKeyPrint.connect_msg_02</p>
    <p translate>connectKeyPrint.connect_msg_03</p>

    <ol>
      <li class="item-step" translate>connectKeyPrint.download_msg_01</li>
      <li class="item-step" translate>connectKeyPrint.download_msg_02</li>
      <li class="item-step" translate>connectKeyPrint.download_msg_03</li>
    </ol>

    <div class="note" translate>
      connectKeyPrint.expaire_title <span>{{ codeExpirationDate | hcpDate: "mediumDate" }}.</span> connectKeyPrint.contact_msg
    </div>

    <div class="content-footer" translate>connectKeyPrint.good_health</div>
    <div class="content-footer" translate>connectKeyPrint.velys_team</div>

  </div>
</div>
