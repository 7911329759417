import { Injectable } from '@angular/core';
import { HpHcpHttp } from '../../shared/service/hp-hcp-http.service';
import { Observable } from 'rxjs';
import { CohortDataModel } from '../../shared/model/cohort-data.model'


@Injectable()
export class TkrCohortData {

    constructor (
        private _hpHcpHttp: HpHcpHttp
    ) {}

    public getCohortData(userId, success, failure) {
        this._hpHcpHttp.get(this._hpHcpHttp.urls.actionList.actionListData, { urlParam: userId }).subscribe(success, failure);
    }

    public getCohortDataFromModel(userId): Observable<CohortDataModel>{
        return this._hpHcpHttp.get(this._hpHcpHttp.urls.actionList.actionListData, { urlParam: userId })
    }
}
