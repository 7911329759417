<div class="hp-hcp-auth-app">
    <div class="signin-wizard"  *ngIf="isShowContent">
        <div class="login-logo-row text-center">
            <img class="login-logo" src="../../../assets/img/velys-logo-signin.png" alt="velys Logo signin" />
        </div>
        <div *ngIf="!isSessionValid" class="login-session-error signin-error-center-model">
            <p>
                Your login session was interrupted, This may be because you logged in from another browser tab or window.
            </p>
        </div>
        <div *ngIf="isInactiveLogout" class="signin-inactive-error signin-error-center-model">
            <p>
                For your security, the VELYS&trade; Insights portal requires you to enter your password after 15 minutes of inactivity.
            </p>
        </div>
        <form [formGroup]="loginForm" class="login-form">
            <div class="input-field-row">
                <label for="email">Email</label>
                <input class="login-form-input"
                    type="email" formControlName="email" placeholder="" id="email" autocomplete="off"/>
                <span class="error-message" *ngIf="isInValidEmail">Please enter a valid email address</span>
            </div>
            <div class="input-field-row">
                <label for="signinPassword">Password</label>
                <input class="login-form-input" type="password" formControlName="password" placeholder="" for="signinPassword" autocomplete="off">
            </div>

            <div class="input-signin-but text-center">
                <button mat-raised-button color="primary" class="login-button" (click)="login()" [disabled]="isSubmitted">
            Log In
        </button>
            </div>
            <a class="forgot-link" routerLink="/forgotpassword" routerLinkActive="active">
        Forgot Password?</a>
        </form>
    </div>
</div>
