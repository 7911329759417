<div class="hp-hcp-date-picker" click-outside (clickOutside)="isOpen = false">
  <input
    [attr.data-dom-id]=" textFieldDomId"
    class="date-picker-input"
    [(dateFormat)]="dateFormat"
    data-event-group="date_picker_field"
    [disabled]="isDisabled"
    (click)="isOpen = false"
    [(ngModel)]="inputModel"
    (onChange)="handleInputModelChange($event)"
    >
      <div [ngClass]="{'bottom-position':position=='bottom', 'right-bottom-position' :position=='right-bottom', 'left-position' :position=='left'}" class="datepicker-wrapper" [class.show]="isOpen">
        <ngb-datepicker
          #datepicker="ngbDatepicker"
          navigation="select"
          (dateSelect)="isOpen = false; updateInputDate($event)"
          [(ngModel)]="datepickerModel"
          firstDayOfWeek="7"
          [dayTemplate]="customDay">
        </ngb-datepicker>
        <div class="footer">
          <button
            class="today"
            (click)="isOpen = false; updateInputDate(today)" translate>
            datePicker.today
          </button>
          <button
            class="pkr-close"
            (click)="isOpen = false;" translate>
            datePicker.close
          </button>
        </div>
      </div>
  <div class="calendar-icon-wrapper">
    <button
     class="calendar btn"
     type="button"
      *ngIf="!isDisabled"
      (click)="isOpen = !this.isOpen; updateCalendar();">
      <i class="calendar-icon"></i>
    </button>
  </div>
  <ng-template
    #customDay
    let-date="date"
    let-currentMonth="currentMonth"
    let-selected="selected"
    let-disabled="disabled"
    let-focused="focused">
  <div
      class="custom-day"
      [class.bg-primary-custom]="selected"
      [class.text-muted]="isDateMuted(currentMonth, date, disabled, selected)"
      [class.outside]="isDateMuted(currentMonth, date, disabled, selected)"
      [class.focused]="focused"
      [class.active]="!selected && isDateToday(date)">
      {{ date.day }}
  </div>
  </ng-template>
</div>
