import { Component, Inject, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { HpHcpAuth } from '../../shared/service/hp-hcp-auth.service';
import { HpHcpUserSession } from '../../shared/service/hp-hcp-user-session.service';
import { HpHcpLocalStorage } from '../../shared/service/hp-hcp-localstorage.service';
import { HpHcpSessionPolling } from '../../shared/service/hp-hcp-session-polling.service';
import { AppConstants } from '../../config/constants';

@Component({
    selector: 'hp-hcp-idle-message-modal',
    templateUrl: './idle-message-modal.html',
    styleUrls: ['./idle-message-modal.scss']
})

export class IdleMessageModalComponent implements OnInit, OnDestroy {
    private _storageNames: any;
    private timeLeftBeforeTimeout: Subscription;
    public timeleft = '00:00';
    private counter = 780;

    constructor(
        private dialogRef: MatDialogRef<IdleMessageModalComponent>,
        private _hpHcpAuth: HpHcpAuth,
        private _userSessionService: HpHcpUserSession,
        private _localStorage: HpHcpLocalStorage,
        private _hpHcpSessionPolling: HpHcpSessionPolling,
        @Inject('STORAGE_NAMES') storageNames: any
    ) {
        this._storageNames = storageNames;
        dialogRef.disableClose = true;
    }

    public ngOnInit() {
        this.timeLeftBeforeTimeout = timer(0, 1000).subscribe(() => this.transform(--this.counter));
    }

    @HostListener('window:click')
    @HostListener('window:mousemove')
    public onEnter() {
        this.timeLeftBeforeTimeout.unsubscribe();
        this.counter = 900;
        this.timeLeftBeforeTimeout = timer(0, 1000).subscribe(() => this.transform(--this.counter));
    }

    public ngOnDestroy() {
        this.timeLeftBeforeTimeout.unsubscribe();
    }

    public stayOnPage() {
        const inactivityWindow = false;
        let sessionExpiration = this._userSessionService.getSessionExpiration();
        Object.assign(sessionExpiration, {inactivityWindow});
        this._localStorage.setItem(this._storageNames.sessionExpiration, JSON.stringify(sessionExpiration));
        this.dialogRef.close();
    }

    public logoutUser() {
        this.dialogRef.close();
        const inactivityWindow = false;
        let sessionExpiration = this._userSessionService.getSessionExpiration();
        Object.assign(sessionExpiration, {inactivityWindow});
        this._localStorage.setItem(this._storageNames.sessionExpiration, JSON.stringify(sessionExpiration));
        const redirectToLogin = () => {
            window.location.href = `${AppConstants.vdsUrl}/#/login?${this._hpHcpAuth.getLogoutParams()}`;
        };
        const onLogoutFailure = null;
        this._hpHcpAuth.logout(redirectToLogin, onLogoutFailure);
    }

    public transform(value: number) {
        if (value === 0) {
            this.dialogRef.close();
            this._hpHcpAuth.logout(this._hpHcpSessionPolling
                ._sendUserToLogin.bind(this._hpHcpSessionPolling, true),
                this._hpHcpSessionPolling._sendUserToLogin.bind(this._hpHcpSessionPolling, true));
        }
        const minutes: number = Math.floor(value / 60);
        this.timeleft = ('00' + minutes).slice(-2) + ':' + ('00' + Math.floor(value - minutes * 60)).slice(-2);
    }

}