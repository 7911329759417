import { Component, Input, OnInit } from '@angular/core';

import { HpHcpBrowserPrint } from '../../service/hp-hcp-browser-print.service';
import { HpHcpUserConfigService } from '../../service/hp-hcp-user-config.service';
import { CarePlanType } from '../../constants/care-plan-types';

@Component({
    selector: 'hp-hcp-connect-key-print',
    templateUrl: './connect-key-print.html',
    styleUrls: ['./connect-key-print.scss']
})
export class ConnectKeyPrintComponent implements OnInit {

    @Input() public codeExpirationDate: string;
    @Input() public connectCode: string;
    @Input() public patientFirstName: string;
    @Input() public patientLastName: string;

    public isBariatricsPlan = false;

    constructor (
        private _hpHcpBrowserPrint: HpHcpBrowserPrint,
        private _userConfigService: HpHcpUserConfigService
    ) {}

    public ngOnInit() {
        this._userConfigService.getCarePlan(carePlan => {
            this.isBariatricsPlan = carePlan.type === CarePlanType.BARIATRICS;
        });
    }

    public printContent() {
        this._hpHcpBrowserPrint.print();
    }
}
