import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HpHcpAuth } from './hp-hcp-auth.service';
import { PORTAL_MODALITY_IDENTIFIER_MANUAL, HpHcpUserConfigService } from './hp-hcp-user-config.service';
import { HpHcpUserSession } from './hp-hcp-user-session.service';
import { HpHcpUrlRedirect } from './hp-hcp-url-redirect.service';
import { AppConstants } from '../../config/constants';

@Injectable()
export class HpHcpRoutesAuthGuard  {

    constructor(
        private _userSessionService: HpHcpUserSession
    ) { }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this._isUserAuthenticated();
    }

    public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this._isUserAuthenticated();
    }

    public canLoad(route: Route): boolean {
        return this._isUserAuthenticated();
    }

    private _isUserAuthenticated() {
        if (!this._userSessionService.getUserSession()) {
            window.location.href = `${AppConstants.vdsUrl}/#/login`;
            return false;
        }
        return true;
    }

}

@Injectable()
export class HpHcpRoutesBariatricsGuard  {

    constructor(
        private _userConfigService: HpHcpUserConfigService,
        private _router: Router
    ) { }

    public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this._userConfigService
            .isBariatricsPlan()
            .pipe(
                map((isBariatricsPlan) => {
                    if (!isBariatricsPlan) {
                        this._router.navigateByUrl('/dashboard');
                    }

                    return isBariatricsPlan;
                })
            );
    }

}

@Injectable()
export class HpHcpRoutesOrganizationalAdminGuard  {

    constructor(
        private _userSessionService: HpHcpUserSession,
        private _router: Router,
        private _urlRedirectService: HpHcpUrlRedirect
    ) { }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.canLoad(null);
    }

    public canLoad(route: Route): boolean {
        if (!this._userSessionService.isOrganizationalAdmin()) {
            this._navigateToMainPage();
            return;
        }
        return true;
    }

    private _navigateToMainPage() {
        this._router.navigate([this._urlRedirectService.urls.dashboard]);
    }
}

@Injectable()
export class HpHcpManageSurgeonGuard  {
    public constructor(
        private _configService: HpHcpUserConfigService,
        private _userSessionService: HpHcpUserSession
    ) { }

    public canActivate() {
        const userCarePlan = this._configService.getStoredCarePlan();

        if (!(userCarePlan && userCarePlan.addPatient)) {
            return false;
        }

        const isManualModality = userCarePlan.addPatient === PORTAL_MODALITY_IDENTIFIER_MANUAL;
        const isOrgAdmin = this._userSessionService.isOrganizationalAdmin();

        return (isOrgAdmin && isManualModality);
    }

    public canLoad() {
        return this.canActivate();
    }
}

@Injectable()
export class HpHcpRoutesEmptyOrganizationGuard  {
    private _isOrganizationUrl: boolean;
    private _orgSelectionPermitted: boolean;
    public constructor(
        private _authService: HpHcpAuth,
        private _router: Router,
        private _urlRedirectService: HpHcpUrlRedirect,
        private _userSessionService: HpHcpUserSession
    ) {
        this._orgSelectionPermitted = this._authService.orgSelectionPermitted();
    }

    public canActivate(route: ActivatedRouteSnapshot, { url }: RouterStateSnapshot): boolean {
        this._isOrganizationUrl = this._urlRedirectService.isOrganizationAdminUrl(url);
        if (this.canLoadOrganizationPage() || this.canLoadNonOrganizationPage()) {
            return true;
        }
        return false;
    }

    public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.canActivate(route, state);
    }

    public canLoadNonOrganizationPage() {
        return !this._checkAccess();
    }

    public canLoadOrganizationPage() {
        if (this._shouldNavigateToMain()) {
            this._router.navigate([this._urlRedirectService.urls.dashboard]);
            return;
        }
        return this._checkAccess();
    }

    private _checkAccess() {
        return this._isOrganizationUrl && this._orgSelectionPermitted;
    }

    private _shouldNavigateToMain() {
        const multiOrgSelected: boolean = this._userSessionService.getMultiOrgSelected();
        return this._isOrganizationUrl && multiOrgSelected;
    }
}

