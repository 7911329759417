/**
 * Copyright: Copyright © 2023
 * This file contains trade secrets of Johnson & Johnson. No part may be reproduced or transmitted in any
 * form by any means or for any purpose without the express written permission of Johnson & Johnson.
 */
import { Inject, Injectable } from '@angular/core';
import { HpHcpLocalStorage } from 'src/app/shared/service/hp-hcp-localstorage.service';
import { HpHcpHttp } from '../../shared/service/hp-hcp-http.service';
/*
 * @author      @version    @date           @description
 * EElangav     01          Apr 10, 2023    AFLL-15217 Report Including Total VRAS Patients Count
 * EElangav     02          Apr 11, 2023    AFLL-15217 - Added Total VRAS Patients Count 
 */
@Injectable()
export class PatientListService {

    private _hpHcpHttp: HpHcpHttp;
    private _localStorage: HpHcpLocalStorage;
    private _storageNames: any;

    constructor(hpHcpHttp: HpHcpHttp,
        hpHcpLocalStorage: HpHcpLocalStorage,
        @Inject('STORAGE_NAMES') storageNames: any
    ) {
        this._hpHcpHttp = hpHcpHttp;
        this._localStorage = hpHcpLocalStorage;
        this._storageNames = storageNames;
    }

    public getPatientList(queryParams?: Array<Object>) {
        return this._hpHcpHttp.post(this._hpHcpHttp.urls.patient.list, { queryParams });
    }

    public getPatientListBySearch(page: number, query: string) {
        const queryParams = [
            { name: 'autocomplete', value: 0 },
            { name: 'page', value: page }
        ];

        const requestBody = {
            query: query
        };

        return this._hpHcpHttp.post(this._hpHcpHttp.urls.patient.list, { queryParams, requestBody });
    }

    public setVrasPatientCount(vrasPatientCount) {
        this._localStorage.setItem(this._storageNames.countOfVrasPatients, JSON.stringify(vrasPatientCount));
    }

    public getVrasPatientCount() {
        return JSON.parse(this._localStorage.getItem(this._storageNames.countOfVrasPatients));
    }
}
