<div class="tkr-sub-navigation">
    <mat-toolbar-row>
        <button mat-button [ngClass]="{ 'reports-text-selected': isCreateProms }" *ngIf="isShowPROMs"
            class="navigation-text" (click)="goToCreateReport()" translate>{{'subNavigation.proms' | translate
            }}</button>

        <button mat-button [ngClass]="{ 'reports-text-selected': isCreateRtm }" *ngIf="isShowRTM" class="navigation-text" (click)="goToRtmReport()" translate>{{'subNavigation.rtm' | translate
            }}</button>

        <span class="fill-space"></span>

        <button mat-button [ngClass]="{ 'reports-text-selected': isViewReport }" class="generatedReports"
            (click)="goToGeneratedReport()" translate>{{'subNavigation.generatedReports' | translate
            }}</button>
    </mat-toolbar-row>
</div>