import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'domId'})
export class HcpDomIdPipe implements PipeTransform {

    public transform(value: any): string {
        return value
            && typeof value === 'string'
            && value.trim().toLowerCase().replace(/[- ]/g, '_')
            || typeof value === 'number' && value.toString()
            || '';
    }

}
