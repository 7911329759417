import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import isEmpty from 'lodash/isEmpty';

@Injectable()
export class HpHcpDocumentTitleService {

    constructor (
        private titleService: Title
    ) { }

    public setDocumentTitle(title: string) {
        let newTitle = this.titleService.getTitle().split('|')[0].replace(/^\s*|\s*$/g, '');
        newTitle += (!isEmpty(title) ? ' | ' + title : '');
        this.titleService.setTitle(newTitle);
    }
}
