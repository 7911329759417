import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AccountActivationAcceptComponent } from './account-activation/account-activation-accept/account-activation-accept.component';
import { AccountActivationDeclineComponent } from './account-activation/account-activation-decline/account-activation-decline.component';
import { AuthComponent } from './auth.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LoginComponent } from './login/login.component';
import { PrivacyAndTermsComponent } from './privacy-and-terms/privacy-and-terms.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SsoComponent } from './sso/sso.component';

const routes: Routes = [
    {
        path: '',
        component: AuthComponent,
        data: { pageTitle: 'Login'},
        children: [
            {
              path: 'activate-account',
              component: AccountActivationAcceptComponent,
              data: {
                pageTitle: 'Activate Your Health Partner Account'
              }
            },
            {
              path: 'decline-invitation',
              component: AccountActivationDeclineComponent,
              data: {
                pageTitle: 'Health Partner - Decline Invitation'
              }
            },
            { path: '', redirectTo: '/login', pathMatch: 'full' },
            { path: 'login', component: LoginComponent },
            { path: 'sso', component: SsoComponent },
            { path: 'forgotpassword', component: ForgotPasswordComponent, data: { pageTitle: 'Forgot Password' } },
            { path: 'newpassword', component: ResetPasswordComponent, data: { pageTitle: 'Reset Password' } },
            {
                path: 'privacy-and-terms',
                component: PrivacyAndTermsComponent,
                data: {
                    pageTitle: 'Privacy Policy And Terms Of Use'
                }
            }
        ]
    }
];

@NgModule({
    imports: [ RouterModule.forChild(routes) ],
    exports: [ RouterModule ]
})
export class AuthRoutingModule {}
