<div class="hp-hcp-collapsible-container">
    <div class="header">
        <span class="title">
            {{title}}
            <span class="fa fa-pencil title-action link" [routerLink]="editLink" *ngIf="editLink" data-dom-id="action_list_edit"></span>
        </span>
        <div class="collapse-arrow link"
             [ngClass]="{'extra-right-space': extraRightSpace }"
             data-category-id="menu"
             data-event-group="accordion"
            [attr.data-action-id]="collapsed ? 'expand' : 'collapse'"
            attr.data-dom-id="{{ title | domId }}"
             (click)="toggleCollapsed()">
            <img class="collapse-icon" [src]="'../../../../assets/img/blue_arrow_' + [collapsed ?  'down.png' : 'up.png']" />
        </div>
    </div>

    <div class="content"
         [class.collapsed]="collapsed">
        <ng-content> </ng-content>
    </div>
</div>
