import { Component, ElementRef, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { from } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { HpHcpDocumentTitleService } from './shared/service/hp-hcp-document-title.service';
import { BlockScrollStrategy, Overlay } from '@angular/cdk/overlay';
import { MAT_SELECT_SCROLL_STRATEGY } from '@angular/material/select';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AppConstants } from './config/constants';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { HpHcpAuth } from './shared/service/hp-hcp-auth.service';
import { IdleMessageModalComponent } from './hcp-user/idle-message-modal/idle-message-modal.component';
import { HpHcpUserSession } from './shared/service/hp-hcp-user-session.service';
import { HpHcpSessionPolling } from './shared/service/hp-hcp-session-polling.service';
import { HpHcpLocalStorage } from './shared/service/hp-hcp-localstorage.service';
import { TranslateService } from '@ngx-translate/core';

export function scrollFactory(overlay: Overlay): () => BlockScrollStrategy {
    return () => overlay.scrollStrategies.block();
}

/*
 *  @author     @version    @date           @description
 *  HSenevir    01          Feb 10, 2024    AFLL-20737 - Setting VDS Portal url dynamically based on hostname with ANZ
 *  SKota7      02          Mar 27, 2024    AFLL-20827 - Removed is-vdp related changes
 *  PPareek     03          Jun 06, 24      AFLL-21131 Fixed Sonar Issue (used variable instead of value)
 */

@Component({
    selector: 'tkr-app',
    templateUrl: './app.component.html',
    providers: [
        { provide: MAT_SELECT_SCROLL_STRATEGY, useFactory: scrollFactory, deps: [Overlay] }
    ],
    styleUrls: ['./app.scss']
})
export class AppComponent implements OnInit {
    private _dialog: MatDialog;
    private _hpHcpAuthService: HpHcpAuth;
    private _hpHcpSessionPollingService: HpHcpSessionPolling;
    private _hpHcpUserSessionService: HpHcpUserSession;
    public inactivityScreenVisible = false;
    public dialogRef: MatDialogRef<IdleMessageModalComponent>;

    constructor(
        private _documentTitleService: HpHcpDocumentTitleService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private element: ElementRef,
        private idle: Idle,
        private keepalive: Keepalive,
        authService: HpHcpAuth,
        dialog: MatDialog,
        sessionPollingService: HpHcpSessionPolling,
        userSessionService: HpHcpUserSession,
        private _localStorage: HpHcpLocalStorage,
        private _translate: TranslateService
    ) {
        let lng = this._translate.getBrowserLang();
        lng = lng === 'en' ? 'en-US' : lng;
        this._translate.setDefaultLang(lng);
        this._translate.use(lng);
        this._dialog = dialog;
        this._hpHcpAuthService = authService;
        this._hpHcpSessionPollingService = sessionPollingService;
        this._hpHcpUserSessionService = userSessionService;

        let inactivityWindow = false;
        let sessionExpiration = this._hpHcpUserSessionService.getSessionExpiration();
        if (sessionExpiration) {
            Object.assign(sessionExpiration, { inactivityWindow });
            this._localStorage.setItem('sessionExpiration', JSON.stringify(sessionExpiration));
        }
        const idleTime = 120;
        const idleTimeInactivity = 780;
        // sets an idle timeout of 120 seconds(2 minutes).
        idle.setIdle(idleTime);
        // sets a timeout period of 780 seconds. after 900 seconds (15 minutes) of inactivity, the user will be considered timed out.
        idle.setTimeout(idleTimeInactivity);
        // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
        idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

        idle.onIdleEnd.subscribe(() => {
            this.reset();
        });

        idle.onTimeout.subscribe(() => {
            this._dialog.closeAll();
            this._hpHcpAuthService.logout(this._hpHcpSessionPollingService
                ._sendUserToLogin.bind(this._hpHcpSessionPollingService, true),
                this._hpHcpSessionPollingService._sendUserToLogin.bind(this._hpHcpSessionPollingService, true));
        });

        idle.onIdleStart.subscribe(() => {
            sessionExpiration = this._hpHcpUserSessionService.getSessionExpiration();
            if (!sessionExpiration.inactivityWindow) {
                this.inactivityScreenVisible = true;
                this.dialogRef = this._dialog.open(IdleMessageModalComponent, {
                    backdropClass: 'backdrop-background',
                    panelClass: 'overlap-panel'
                });

                this.dialogRef.afterClosed().subscribe(result => {
                    this.inactivityScreenVisible = false;
                });
                inactivityWindow = true;
                Object.assign(sessionExpiration, { inactivityWindow });
                this._localStorage.setItem('sessionExpiration', JSON.stringify(sessionExpiration));
            }

        });
        const keepAliveTime = 15;
        this.keepalive.interval(keepAliveTime);

        if (this._hpHcpUserSessionService.getUserSession()) {
            idle.watch();
        }

        this._hpHcpAuthService.getUserLoggedIn().subscribe(userLoggedIn => {
            if (userLoggedIn) {
                idle.watch();
            } else {
                idle.stop();
            }
        });
    }

    private reset() {
        this.idle.watch();
    }

    public ngOnInit() {
        let gaCode = this.element.nativeElement.getAttribute('ga-code');
        let vdsUrls = this.element.nativeElement.getAttribute('vds-urls');
        let cookieDomainScriptId = this.element.nativeElement.getAttribute('cookie-domain-script-id');

        /*
        * anzRegex is for ANZ hostname in lower environment hostnames.
        * auRegex and nzRegex are for Prod hostnames
        * */
        const anzRegex = new RegExp('-anz-', 'g');
        const auRegex = new RegExp('[/.]au\\.','g');
        const nzRegex = new RegExp('[/.]nz\\.','g');
        const hostname = location.host;

        const isAnzHostname = hostname.match(anzRegex) != null;
        const isAuHostname = hostname.match(auRegex) != null;
        const isNzHostname = hostname.match(nzRegex) != null;

        // This logic can be used for non-logged in scenarios
        AppConstants.isAnz = isAnzHostname || isAuHostname || isNzHostname;

        if (cookieDomainScriptId != null) {
            AppConstants.cookieDomainScriptId = cookieDomainScriptId;
        }

        if (vdsUrls != null) {
            const vdsPortalUrlList = vdsUrls.split(',');
            AppConstants.vdsUrl = vdsPortalUrlList[0];
            vdsPortalUrlList.forEach(item => {
                if (isAnzHostname && item.match(anzRegex) != null) {
                    AppConstants.vdsUrl = item;
                }
                if (isAuHostname && item.match(auRegex) != null) {
                    AppConstants.vdsUrl = item;
                }
                if (isNzHostname && item.match(nzRegex) != null) {
                    AppConstants.vdsUrl = item;
                }
            });
        }

        if (gaCode != null) {
            AppConstants.gaCode = gaCode;
        }

        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            map(() => this.activatedRoute),
            switchMap(route => {
                let childrenRoutes = [];
                childrenRoutes.push(route);
                while (route.firstChild) {
                    route = route.firstChild;
                    childrenRoutes.push(route);
                }
                return from(childrenRoutes);
            }),
            filter(route => route.outlet === 'primary'),
            switchMap(route => route.data),
            filter(routeData => true === !!routeData['pageTitle'])
        ).subscribe((routeData) => {
            const pageTitle = (routeData && routeData['pageTitle'] ? routeData['pageTitle'] : '');
            this._documentTitleService.setDocumentTitle(pageTitle);
        });
    }

    public footerLinkClick(event: boolean) {
        let sessionExpiration = this._hpHcpUserSessionService.getSessionExpiration();
        this.dialogRef.close();
        this.dialogRef.afterClosed().subscribe(result => {
            this.inactivityScreenVisible = false;
        });
        const inactivityWindow = true;
        Object.assign(sessionExpiration, { inactivityWindow });
        this._localStorage.setItem('sessionExpiration', JSON.stringify(sessionExpiration));
    }

}
