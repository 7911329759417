/**
 * Copyright: Copyright © 2023
 * This file contains trade secrets of Johnson & Johnson. No part may be reproduced or transmitted in any
 * form by any means or for any purpose without the express written permission of Johnson & Johnson.
 */

/*
 * @author      @version    @date           @description
 * HSenevir     1           Mar 09, 2023    AFLL-15210 Added Encryption info
 * EElangav     2           Mar 16, 2023    AFLL-16808 Patient Information Widget
 * AVijay3      3           Mar 20, 2023    AFLL-15210 Added the maximum file size limit
 * APawar22     4           Jun 02, 2023    AFLL-17605 RTM | View all reports on the 'Generated Reports' page
 * JVelagal     5           Jun 05, 2023    AFLL-17603 RTM | 'Create RTM Reports' page
 * ZDaudy       6           Jun 21, 2023    AFLL-18198 Correcting reportType to 'RTM'
 * HSenevir     7           Feb 10, 2024    AFLL-20737 Added ANZ variables
 * SKota7       8           Mar 27, 2024    AFLL-20827 Removed is-vdp related changes
*  LMudalig     9           June 03, 2024   AFLL-19930 - Upgrade to AmCharts v5 | HCP Portal | Upgrade components  
*/

export class AppConstants {
    // Google Analytics code is set from AppComponent, when code is set in the root tag
    private static _gaCode;
    private static _vdsUrl;
    private static _cookieDomainScriptId;
    public static cookieDataDomainUrl = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
    public static _isAnz;
    public static encryptionKey = '7dd1cfb30cfe04f5dcd576e757ea78c8';
    public static encryptionInitialVector = 'cfe05756a1281af20f7cd6e686ab286a';
    public static promsReportType = 'proms_ajrr';
    public static rtmReportType = 'rtm';
    public static allReportType = 'all';
    public static maxFileSize = 500000000;

    static get cookieDomainScriptId() {
        return this._cookieDomainScriptId;
    }

    static set cookieDomainScriptId(value) {
        this._cookieDomainScriptId = value;
    }

    static get vdsUrl() {
        return this._vdsUrl;
    }

    static set vdsUrl(value) {
        this._vdsUrl = value;
    }

    static get isAnz() {
        return this._isAnz;
    }

    static set isAnz(value) {
        this._isAnz = value;
    }

    static get gaCode() {
        return this._gaCode;
    }

    static set gaCode(value) {
        this._gaCode = value;
    }

    public static get defaultSurgeryDate(): string {
        return '1999-01-01';
    }

    public static get defaultSurgeryTime(): string {
        return '00:00:00';
    }

    public static get defaultSurgeryDateTime(): string {
        return '1999-01-01T00:00:00+00:00';
    }

    public static get amChartsLicense(): string {
        return 'AM5C-205232863';
    }

    public static get productCode(): string {
        return '451570500';
    }

    public static get defaultSurgeryStartTime(): string {
        return '06:01';
    }

    public static get defaultSurgeryEndTime(): string {
        return '07:31';
    }
}
